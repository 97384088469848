import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import { SocialsignupService } from './socialsignup.service';
import Swal from 'sweetalert2';
import { Cookie } from 'ng2-cookies/ng2-cookies';
import { VerifyotpService } from './../verifyotp/verifyotp.service';
declare let gtag: Function;


@Component({
  selector: 'app-socialsignup',
  templateUrl: './socialsignup.component.html',
  styleUrls: ['./socialsignup.component.css']
})
export class SocialsignupComponent implements OnInit {
  loginForm: FormGroup;
  otpNumber: any;
  socialsignupData: any;

  constructor(
    private formBuilder: FormBuilder,
    private socialsignupService: SocialsignupService,
    private verifyotpService: VerifyotpService,
    private router: Router,
    private route: ActivatedRoute,


  ) {
    this.socialsignupData = this.router.getCurrentNavigation().extras.state;

  }

  ngOnInit() {
    this.loginForm = this.formBuilder.group({
      firstName: [this.socialsignupData.name, Validators.required],
      lastName: ['', Validators.required],
      mobileNumber: ['', Validators.required],
      countryCode: ['', Validators.required],
      email: [this.socialsignupData.email, Validators.required],


    });
  }
  sendotp() {
    const signupData = this.loginForm.value;
    const signupdata = { firstName: signupData.firstName, lastName: signupData.lastName, mobileNumber: signupData.mobileNumber, countryCode: signupData.countryCode, email: signupData.email, type: 'socialLogin', token: this.socialsignupData.token, socialType: this.socialsignupData.socialType }
    var otpdata = { mobileNumber: signupData.mobileNumber, countryCode: signupData.countryCode }
    this.socialsignupService.sendOtp(otpdata).subscribe(
      res => {
        const data = res.body['data']
        if (res.body['error'] === 'true') {
          Swal({
            title: 'Error',
            text: res.body['message'],
            type: 'error',
            confirmButtonText: 'Ok',
          });
        } else {
          var req = { email: signupData.email, socialToken: this.socialsignupData.token, socialType: this.socialsignupData.socialType }
          // console.log("**************** socialsiginup component", req)
          this.verifyotpService.socialLogin(req).subscribe(
            res => {
              const data = res.body['data']
              if (res.body['error'] === 'true') {
                Swal({
                  title: 'Error',
                  text: res.body['message'],
                  type: 'error',
                  confirmButtonText: 'Ok',
                });
              } else {
                gtag('event', 'conversion', { 'send_to': 'AW-623317282/5hbyCJi35O8BEKKinKkC' });
                if (res.body['data'].token != null) {
                  Cookie.set('token', res.body['data'].token);
                  Cookie.set('image', res.body['data'].image);
                  Cookie.set('userName', res.body['data'].userName);
                  Cookie.set('id', res.body['data'].id);
                  this.router.navigate(['/']);
                } else {
                  this.otpNumber = ''
                  this.router.navigate(['/verifyotp'], { state: signupdata });
                }
              }
            });

        }
      });

  }

}

import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import { EditInsuranceService } from './edit-insurance.service';
import Swal from 'sweetalert2';
import { NgxSpinnerService } from "ngx-spinner";


@Component({
  selector: 'app-edit-insurance',
  templateUrl: './edit-insurance.component.html',
  styleUrls: ['./edit-insurance.component.css']
})
export class EditInsuranceComponent implements OnInit {

  insuranceCarier: any;
  insuranceType: any;
  memberId: any;
  firstName: any;
  lastName: any;
  validity: any;
  id: any;


  formName: string;


  insuranceForm: any;
  selectedImage: File;
  url: string | ArrayBuffer;
  profileImage: any;
  url1: string | ArrayBuffer;
  url2: string | ArrayBuffer;
  insuranceImage1: any;
  insuranceImage2: any;
  date: Date;
  dd: any;
  mm: any;
  yyyy: any;

  constructor(
    private formBuilder: FormBuilder,
    private editinsuranceService: EditInsuranceService,
    private router: Router,
    private route: ActivatedRoute,
    private spinner: NgxSpinnerService
  ) {
    this.insuranceForm = this.formBuilder.group({
      insuranceCarier: ['', Validators.required,],
      insuranceType: ['', Validators.required],
      memberId: ['', Validators.required],
      image: ['', Validators.required],
      validity: ['', Validators.required],
      firstName: ['', Validators.required],
      lastName: ['', Validators.required]
    });

  }

  ngOnInit() {
    this.spinner.show();
    this.editinsuranceService.insuranceData().subscribe(
      res => {
        this.insuranceCarier = res.body['data'].insuranceDetails[0].insuranceCarier;
        this.insuranceType = res.body['data'].insuranceDetails[0].insuranceType;
        this.memberId = res.body['data'].insuranceDetails[0].memberId;
        this.firstName = res.body['data'].insuranceDetails[0].firstName;
        this.lastName = res.body['data'].insuranceDetails[0].lastName;
        this.validity = res.body['data'].insuranceDetails[0].validity;
        var date = new Date(this.validity)
        this.dd = date.getDate();
        this.mm = date.getMonth() + 1;
        this.yyyy = date.getFullYear();
        if (this.dd < 10) { this.dd = '0' + this.dd }
        if (this.mm < 10) { this.mm = '0' + this.mm };
        this.validity = this.yyyy + '-' + this.mm + '-' + this.dd
        // console.log(this.validity)

        this.url1 = res.body['data'].insuranceDetails[0].insuranceImage1;
        this.url2 = res.body['data'].insuranceDetails[0].insuranceImage2;
        this.insuranceImage1 = res.body['data'].insuranceDetails[0].insuranceImage1;
        this.insuranceImage2 = res.body['data'].insuranceDetails[0].insuranceImage2;
        this.id = res.body['data'].insuranceDetails[0].id;


        this.insuranceForm = new FormGroup({
          insuranceCarier: new FormControl(this.insuranceCarier),
          insuranceType: new FormControl(this.insuranceType),
          memberId: new FormControl(this.memberId),
          firstName: new FormControl(this.firstName),
          lastName: new FormControl(this.lastName),
          validity: new FormControl(this.validity),
          insuranceImage1: new FormControl(this.insuranceImage1),
          insuranceImage2: new FormControl(this.insuranceImage2),


        })
        this.spinner.hide();
      },
      err => {
        this.spinner.hide();

        this.router.navigateByUrl('/serverError');
      }
    );
    this.formName = 'Edit';
  }
  handleFileInput1(file: FileList) {

    this.selectedImage = file.item(0);
    // console.log(this.selectedImage)
    const reader = new FileReader();

    reader.readAsDataURL(this.selectedImage); // read file as data url

    reader.onload = (event) => { // called once readAsDataURL is completed
      this.url1 = reader.result;
      // console.log(this.url1)

      this.editinsuranceService.fileUpload(this.selectedImage).subscribe(
        res => {
          console.log(res)
          this.insuranceImage1 = res.body['data'].imageURL;
        }
      )
    };

  }

  handleFileInput2(file: FileList) {

    this.selectedImage = file.item(0);
    // console.log(this.selectedImage)
    const reader = new FileReader();

    reader.readAsDataURL(this.selectedImage); // read file as data url

    reader.onload = (event) => { // called once readAsDataURL is completed
      this.url2 = reader.result;
      // console.log(this.url2)

      this.editinsuranceService.fileUpload(this.selectedImage).subscribe(
        res => {
          // console.log(res)
          this.insuranceImage2 = res.body['data'].imageURL;
        }
      )
    };

  }


  onSubmit() {
    this.spinner.show();

    const insuranceData = this.insuranceForm.value;
    // console.log(this.profileImage)
    // if(this.profileImage==undefined) {
    //   this.profileImage='test.png'

    // }
    // else{

    // }
    const profiledata = { id: this.id, insuranceCarier: insuranceData.insuranceCarier, insuranceType: insuranceData.insuranceType, memberId: insuranceData.memberId, firstName: insuranceData.firstName, lastName: insuranceData.lastName, validity: insuranceData.validity, insuranceImage1: this.insuranceImage1, insuranceImage2: this.insuranceImage2 }
    // console.log(profiledata)
    this.editinsuranceService.editInsurance(profiledata).subscribe(
      res => {
        this.spinner.hide();
        const data = res.body['data']
        if (res.body['error'] === 'true') {
          this.spinner.hide();

          Swal({

            title: 'Error',
            text: res.body['message'],
            type: 'error',
            confirmButtonText: 'Ok',
          });
        } else {
          this.spinner.hide();
          this.router.navigate(['/user/profileupdate'], { state: profiledata });

        }
      });

  }
}


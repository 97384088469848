import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import { EditFamilyService } from './editfamily.service';
import Swal from 'sweetalert2';
import { NgxSpinnerService } from "ngx-spinner";

@Component({
  selector: 'app-editfamily',
  templateUrl: './editfamily.component.html',
  styleUrls: ['./editfamily.component.css']
})
export class EditfamilyComponent implements OnInit {
  familyForm: FormGroup;
  submitted = false;
  selectedOption1: any;
  selectedOption: any;
  type: any;
  name: any;
  gender: any;
  dob: any;
  id: any;
  memberData: any;
  date: Date;
  dd: any;
  mm: any;
  yyyy: any;
  sex = [
    { Gender: "Male", value: 1 },
    { Gender: "Female", value: 2 },

  ]

  spouse = [
    { type: "children ", value: 1 },
    { type: "mother ", value: 2 },
    { type: "father", value: 3 },


  ]
  value: number;




  constructor(private formBuilder: FormBuilder,
    private editfamilyService: EditFamilyService,
    private router: Router,
    private route: ActivatedRoute,
    private spinner: NgxSpinnerService) {
    this.familyForm = this.formBuilder.group({
      type: ['', Validators.required],
      name: ['', Validators.required],
      gender: ['', Validators.required],
      dob: ['', Validators.required],
    });
    this.memberData = this.router.getCurrentNavigation().extras.state;


  }
  get f() { return this.familyForm.controls; }


  ngOnInit() {
    //  this.spinner.show();
    this.id = this.memberData.data.id;
    this.name = this.memberData.data.name;
    this.gender = this.memberData.data.gender;
    this.type = this.memberData.data.type;
    this.dob = this.memberData.data.dob;
    var date = new Date(this.dob)
    this.dd = date.getDate();
    this.mm = date.getMonth() + 1;
    this.yyyy = date.getFullYear();
    if (this.dd < 10) { this.dd = '0' + this.dd }
    if (this.mm < 10) { this.mm = '0' + this.mm };
    this.dob = this.yyyy + '-' + this.mm + '-' + this.dd

    if (this.type === "children") {
      this.selectedOption = 1;
    }
    if (this.type === "mother") {
      this.selectedOption = 2;

    }
    if (this.type === "father") {
      this.selectedOption = 3;

    }
    this.selectedOption = this.type;
    this.selectedOption1 = this.gender;

    this.familyForm = new FormGroup({
      name: new FormControl(this.name),
      gender: new FormControl(this.gender),
      type: new FormControl('1'),
      dob: new FormControl(this.dob),
    })

  }
  onSubmit() {
    // this.spinner.show();
    this.submitted = true;

    const memberData = this.familyForm.value;
    const profiledata = {
      id: this.id, name: memberData.name, gender: memberData.gender, type: memberData.type, dob: memberData.dob,
    }
    this.editfamilyService.editFamily(profiledata).subscribe(
      res => {
        this.spinner.hide();
        const data = res.body['data']
        if (res.body['error'] === 'true') {
          this.spinner.hide();

          Swal({

            title: 'Error',
            text: res.body['message'],
            type: 'error',
            confirmButtonText: 'Ok',
          });
        } else {
          this.spinner.hide();
          this.router.navigate(['/user/profileupdate'], { state: profiledata });

        }
      });

  }

}
import { Injectable } from '@angular/core';
import { environment } from '../../environments/environment';
import { HttpClient, HttpHeaders } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})

export class VerifyotpService {

  baseUrl = environment.baseUrl;

  constructor(private http: HttpClient) { }

  login(apiData) {
    const httpHeaders = new HttpHeaders({
      'Content-Type': 'application/json'
    });

    return this.http.post(this.baseUrl + '/user/verifyLoginAndRegister', apiData, {

      headers: httpHeaders,
      observe: 'response'
    });
  }
  verifySocialotp(apiData) {
    const httpHeaders = new HttpHeaders({
      'Content-Type': 'application/json'
    });

    return this.http.post(this.baseUrl + '/user/verifySocialOTP', apiData, {

      headers: httpHeaders,
      observe: 'response'
    });
  }
  socialLogin(apiData) {
    const httpHeaders = new HttpHeaders({
      'Content-Type': 'application/json'
    });
    if (apiData.socialType == 'google') {
      return this.http.post(this.baseUrl + '/user/checkGoogleLogin', apiData, {
        headers: httpHeaders,
        observe: 'response'
      });
    }
    if (apiData.socialType == 'facebook') {
      return this.http.post(this.baseUrl + '/user/checkFacebookLogin', apiData, {
        headers: httpHeaders,
        observe: 'response'
      });
    }
  }

  socialSignupLogin(apiData) {
    console.log("**********inside verify otp service", apiData)
    const httpHeaders = new HttpHeaders({
      'Content-Type': 'application/json'
    });
    if (apiData.socialType == 'google') {
      return this.http.post(this.baseUrl + '/user/googleSignup', apiData, {
        headers: httpHeaders,
        observe: 'response'
      });
    }
    if (apiData.socialType == 'facebook') {
      return this.http.post(this.baseUrl + '/user/facebookSignup', apiData, {
        headers: httpHeaders,
        observe: 'response'
      });
    }
  }

  verifyotp(apiData) {

    const httpHeaders = new HttpHeaders({
      'Content-Type': 'application/json'
    });

    return this.http.post(this.baseUrl + '/user/loginAndRegister', apiData, {

      headers: httpHeaders,
      observe: 'response'
    });
  }

  logout() {
    sessionStorage.clear();
  }

}

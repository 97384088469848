import { Injectable } from '@angular/core';
import { environment } from '../../environments/environment';
import { HttpClient, HttpHeaders } from '@angular/common/http';


@Injectable({
  providedIn: 'root'
})

export class UrgentcareService {

  baseUrl = environment.baseUrl;

  constructor(private http: HttpClient) { }

  doctorList(filter) {
    const httpHeaders = new HttpHeaders({
      'Content-Type': 'application/json'
    });

    return this.http.post(this.baseUrl + '/user/listUrgentCare', filter, {

      headers: httpHeaders,
      observe: 'response'
    });
  }

  urgentCareDoctorList(filter) {
    const httpHeaders = new HttpHeaders({
      'Content-Type': 'application/json'
    });

    return this.http.post(this.baseUrl + '/user/listUrgentCare', filter, {

      headers: httpHeaders,
      observe: 'response'
    });
  }

  categoryList() {
    const httpHeaders = new HttpHeaders({
      'Content-Type': 'application/json'
    });

    return this.http.get(this.baseUrl + '/user/getAllCategory', {

      headers: httpHeaders,
      observe: 'response'
    });
  }
  listHospital(data) {
    // var data = {
    //   latitude:'13.08268020', longitude:'80.27071840'

    // }
    const httpHeaders = new HttpHeaders({
      'Content-Type': 'application/json'
    });

    return this.http.post(this.baseUrl + '/user/listHospital', data, {

      headers: httpHeaders,
      observe: 'response'
    });
  }
  getChildrenHospital(data) {
    const httpHeaders = new HttpHeaders({
      'Content-Type': 'application/json'
    });

    return this.http.post(this.baseUrl + '/user/listChildrenHospital', data, {

      headers: httpHeaders,
      observe: 'response'
    });
  }
}




import { Component, OnInit, ViewChild, ElementRef, NgZone } from '@angular/core';
import { UrgentcareService } from './urgentcare.service';
import { Router, ActivatedRoute } from '@angular/router';
import { NgxSpinnerService } from "ngx-spinner";
import { Cookie } from 'ng2-cookies/ng2-cookies';
import { MouseEvent, MapsAPILoader } from '@agm/core';
import { FormBuilder, FormGroup, Validators, FormControl } from '@angular/forms';
import { DoctorProfileService } from '././../doctorprofile/doctorprofile.service'
import Swal from 'sweetalert2';
@Component({
  selector: 'app-urgentcare',
  templateUrl: './urgentcare.component.html',
  styleUrls: ['./urgentcare.component.css']
})
export class UrgentcareComponent implements OnInit {
  page = 1;
  pages: any;
  // categoryId: Number = 1;

  @ViewChild('search')
  public searchElementRef: ElementRef;
  location: string;
  doctorId: Number = 1;
  doctorList: any;
  categoriesList: any;
  adminData: any;
  doctorVerifiedStatus: any;
  filterhide: any;
  urgentCareCount: any
  // latitude: any;
  // longitude: any;
  searchForm: any;
  zoom: number = 8;
  lat: number;
  lng: number;
  hospitalLat: any;
  hospitalLong: any;
  education: any;
  hide: boolean;
  markers: [];
  mobileNumber: any;
  hospitalImage: any;
  hospitalName: any;
  isSingle: any = 1;
  item: any;
  geoCoder: any;
  errormessage: String
  address: string;
  id: any;
  hospitalAddress: any;
  latitude: any;
  longitude: any;
  // latitude = Cookie.get('latitude');
  // longitude = Cookie.get('longitude');
  categoryId = Cookie.get('categoryId');
  categories1: any = [
    {
      "categoryId": 1,
      "categoryName": "Urgent Care",
      "categoryImage": "https://app.docnowmd.com/uploads/admin/1598335835713.png",
      "count": 0
    },
    {
      "categoryId": 2,
      "categoryName": "children Urgent Care",
      "categoryImage": "https://app.docnowmd.com/uploads/admin/1598335889427.png",
      "count": 0
    },
  ]

  constructor(private urgentcareService: UrgentcareService,
    private router: Router,
    private spinner: NgxSpinnerService,
    private doctorProfileService: DoctorProfileService,
    private formBuilder: FormBuilder,
    private mapsAPILoader: MapsAPILoader,
    private ngZone: NgZone

  ) {
    this.adminData = this.router.getCurrentNavigation().extras.state;
    this.latitude = Cookie.get('latitude');
    this.longitude = Cookie.get('longitude');
    this.lat = parseFloat(this.latitude)
    this.lng = parseFloat(this.longitude)
    this.searchForm = this.formBuilder.group({
      location: ['', Validators.required]
    })

  }

  ngOnInit() {
    this.setCurrentLocation();
    this.spinner.show();

    if (this.adminData == undefined) {
      this.spinner.hide();
      this.filterUrgentDoctor();
      this.latitude = this.latitude || "33.7489954"
      this.longitude = this.longitude || "-84.3879824"

    } else {
      if (this.adminData.isUrgentCare == 1) {
        this.latitude = this.adminData.latitude;
        this.longitude = this.adminData.longitude;
        this.filterUrgentDoctor();
      } else {
        this.latitude = this.adminData.latitude;
        this.longitude = this.adminData.longitude;
        this.filterUrgentDoctor();
      }
    }
    this.urgentcareService.categoryList().subscribe(
      res => {
        this.spinner.hide();
        this.categoriesList = res.body['data'].categories;
      },
      err => {
        this.spinner.hide();
        this.router.navigateByUrl('/serverError');
      }
    );
    // Map Loader
    this.mapsAPILoader.load().then(() => {
      this.setCurrentLocation();
      this.geoCoder = new google.maps.Geocoder;

      let autocomplete = new google.maps.places.Autocomplete(this.searchElementRef.nativeElement);
      autocomplete.addListener("place_changed", () => {
        this.ngZone.run(() => {
          //get the place result
          let place: google.maps.places.PlaceResult = autocomplete.getPlace();
          //set latitude, longitude and zoom
          this.latitude = place.geometry.location.lat();
          this.longitude = place.geometry.location.lng();
          Cookie.set('latitude', this.latitude)
          Cookie.set('longitude', this.longitude);
          this.errormessage = ""
          this.zoom = 4;
        });
      });
    });
    // End Map Loader
  }
  private setCurrentLocation() {
    if ('geolocation' in navigator) {
      navigator.geolocation.getCurrentPosition((position) => {
        // console.log(position)
        this.latitude = position.coords.latitude;
        this.longitude = position.coords.longitude;
        Cookie.set('latitude', this.latitude)
        Cookie.set('longitude', this.longitude);
        this.zoom = 8;
        this.getAddress(this.latitude, this.longitude);
      });
    }
  }
  markerDragEnd($event: MouseEvent) {
    this.latitude = $event.coords.lat;
    this.longitude = $event.coords.lng;
    this.getAddress(this.latitude, this.longitude);
  }
  searchdoctor() {
    console.log("console", this.latitude, this.longitude)
    Cookie.set('latitude', this.latitude)
    Cookie.set('longitude', this.longitude);
    this.filterUrgentDoctor();
  }

  getAddress(latitude, longitude) {
    // console.log(latitude, longitude)
    this.geoCoder.geocode({ 'location': { lat: latitude, lng: longitude } }, (results, status) => {
      if (status === 'OK') {
        // console.log(results)
        if (results[0]) {
          this.zoom = 12;
          this.address = results[0].formatted_address;
          // console.log(this.address)
          this.location = this.address
          this.searchForm = new FormGroup({
            location: new FormControl(this.address)
          })
        } else {
          window.alert('No results found');
        }
      } else {
        window.alert('Geocoder failed due to: ' + status);
      }

    });
  }

  filterDoctor() {
    var filter = { categoryId: JSON.stringify(this.categoryId), latitude: this.latitude, longitude: this.longitude, page: this.page, filter: JSON.stringify({ "normalDoctors": 0, "teleHealth": 0 }) }
    this.urgentcareService.doctorList(filter).subscribe(
      response => {
        this.spinner.hide();
        this.doctorList = response.body['data'].doctors;
        this.urgentCareCount = response.body['data'].doctors.length
        // var categoryArr = this.categoriesList
        // categoryArr.forEach(function(data, index) {
        //   if (this.categoryId === data.categoryId) {
        //     data.status = true
        //   } else {
        //     data.status = false
        //   }
        // })
      },
      err => {
        this.spinner.hide();
        this.router.navigateByUrl('/serverError');
      }
    );
  }

  filterUrgentDoctor() {
    var filter = { categoryId: JSON.stringify(this.categoryId), latitude: this.latitude, longitude: this.longitude, page: this.page, filter: JSON.stringify({ "normalDoctors": 0, "teleHealth": 0 }) }
    //  console.log(filter)
    this.setCurrentLocation();

    this.urgentcareService.urgentCareDoctorList(filter).subscribe(
      response => {
        this.spinner.hide();
        if (response.body['error'] == 'false') {
          this.doctorList = response.body['data'].doctors;
          this.markers = response.body['data'].doctors;
          this.urgentCareCount = response.body['data'].doctors.length
          this.pages = response.body['data'].pageCount * 10;
        } else {
          this.doctorList = [];
          this.markers = [];
          this.urgentCareCount = 0
          this.pages = 0;
        }
      },
      err => {
        this.spinner.hide();
        this.router.navigateByUrl('/serverError');
      }
    );
  }


  getOrder(page) {
    this.urgentcareService.doctorList(page).subscribe(
      response => {
        this.spinner.hide();
        this.doctorList = response.body['doctors'];
        this.urgentCareCount = response.body['data'].doctors.length

      },
      err => {
        this.spinner.hide();
        this.router.navigateByUrl('/serverError');
      }
    );
  }

  searchdoctorList() {
    const categoryData = { categoryId: this.categoryId }

    this.router.navigate(['/doctorprofile'], { state: categoryData });

  }
  doctorBooking(doctorId) {
    const doctorData = { doctorId: doctorId }

    this.router.navigate(['/booking'], { state: doctorData });
  }

  doctorProfile(doctorId) {
    const doctorData = { doctorId: doctorId }
    Cookie.set('doctorId', doctorId);
    this.router.navigate(['/doctorprofile'], { state: doctorData });
  }

  //filter
  doctorFilter(event) {
    this.spinner.show();
    //this.filterDoctor();
    this.categoryId = event.categoryId
    Cookie.set('categoryId', this.categoryId)
    var filter = { categoryId: JSON.stringify(this.categoryId), latitude: this.latitude, longitude: this.longitude, page: this.page, filter: JSON.stringify({ "normalDoctors": 0, "teleHealth": 0 }) }
    this.urgentcareService.doctorList(filter).subscribe(
      response => {
        this.spinner.hide();
        this.doctorList = response.body['data'].doctors;
        this.urgentCareCount = response.body['data'].doctors.length
        this.pages = response.body['data'].pageCount * 10;
        // var categoryArr = this.categoriesList
        // categoryArr.forEach(function(data, index) {
        //   if (this.categoryId === data.categoryId) {
        //     data.status = true
        //   } else {
        //     data.status = false
        //   }
        // })
      },
      err => {
        this.spinner.hide();
        this.router.navigateByUrl('/serverError');
      }
    );
  }

  getDoctor(page) {
    this.page = page

    var filter = { categoryId: JSON.stringify(this.categoryId), latitude: this.latitude, longitude: this.longitude, page: JSON.stringify(this.page), filter: JSON.stringify({ "normalDoctors": 0, "teleHealth": 0 }) }
    //  console.log(filter)
    this.urgentcareService.urgentCareDoctorList(filter).subscribe(
      response => {
        this.spinner.hide();
        this.doctorList = response.body['data'].doctors;
        this.urgentCareCount = response.body['data'].doctors.length
        this.pages = response.body['data'].pageCount * 10;
      },
      err => {
        this.spinner.hide();
        this.router.navigateByUrl('/serverError');
      }
    );

  }

  clickedMarker(label, index: number) {
    // console.log(`clicked the marker: ${label || index}`)
    // // console.log(label);
    // this.hospitalImage = label.hospitalImage
    // this.hospitalName = label.hospitalName
    // this.education = label.education
    // this.mobileNumber = label.mobileNumber
    // this.hospitalAddress = label.hospitalAddress
  }

  mapClicked($event: MouseEvent) {
    // this.markers.push({
    //   lat: $event.coords.lat,
    //   lng: $event.coords.lng,
    //   draggable: true
    // });
  }


  // markerDragEnd(m: marker, $event: MouseEvent) {
  //   // console.log('dragEnd', m, $event);
  // }





}

interface marker {
  lat: number;
  lng: number;
  label?: string;
  draggable: boolean;
}

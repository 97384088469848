import { Component, OnInit } from '@angular/core';
import SwiperCore , {
  Navigation,
  Pagination,
  Scrollbar,
  A11y,
} from 'swiper/core';
SwiperCore.use([Navigation, Pagination, Scrollbar, A11y]);
declare var $;
@Component({
  selector: 'app-seo',
  templateUrl: './seo.component.html',
  styleUrls: ['./seo.component.css']
})
export class SeoComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }
  onSwiper(swiper) {
    console.log(swiper);
  }
  onSlideChange() {
    console.log('slide change');
  }
 
  data: any = [{
    "parentName": "Why do I need Wait List™ for my practice?",
    "childProperties":
      [
        { "propertyName": "Practices lose $150K revenue on an annual basis to no-shows and cancelled appointments. A survey conducted by the DocNow team in 2019 revealed that 16% patients left due to a cancellation fee charged by the practice. To avoid these issues, Wait List™ feature is a must have for all small and large practices." }
      ]
  }, {
    "parentName": "How does the Wait List™ feature work?",
    "childProperties":
      [
        { "propertyName": "Our proprietary AI engine monitors the open slot on the practitioner’s calendar. As soon as a slot is cancelled, notifications are sent to patients scheduled either for the same day or a few days out to come in early if they have selected the Wait List™ feature in the App. The patients are given a timeframe to accept the new appointment or the notification is sent to the next patient in line until the slot is filled. The cycle continues until all appointments are booked in the system." }
      ]
  }, {
    "parentName": "What if the patient has not selected the Wait List™ feature in the App?",
    "childProperties":
      [
        { "propertyName": "The Wait List™ is a simple turn on/off feature in the DocNow App. If the patient has not turned on their Wait List™ feature, they will not be notified." }
      ]
  }, {
    "parentName": "How will the practitioner know that the patient has his or her Wait List™ feature on?",
    "childProperties":
      [
        { "propertyName": "The practitioner dashboard will provide that information." }
      ]
  }, {
    "parentName": "Can I just purchase the Wait List™ feature without buying the whole solution?",
    "childProperties":
      [
        { "propertyName": "In order for the Wait List™ feature to work, you need membership of DocNow platform" }
      ]
  }, {
    "parentName": "Is there an extra cost for the Wait List™ feature?",
    "childProperties":
      [
        { "propertyName": "No. The Wait List™ feature  is part of the DocNow solution that comes with the membership along with other features and functionalities." }
      ]
  }

];
    ngAfterViewInit(){
      if (window.location.href.indexOf("seo-and-marketing") > -1) {
        $('nav').addClass('addShadow');
      }
      $('.stat-number').each(function () {
        var size = $(this).text().split(".")[1] ? $(this).text().split(".")[1].length : 0;
        $(this).prop('Counter', 0).animate({
           Counter: $(this).text()
        }, {
           duration: 5000,
           step: function (func) {
              $(this).text(parseFloat(func).toFixed(size));
           }
        });
     });
    }
    toggleAccordian(event, index) {
      const element = event.target;
      element.classList.toggle("active");
      if (this.data[index].isActive) {
        this.data[index].isActive = false;
      } else {
        this.data[index].isActive = true;
      }
      const panel = element.nextElementSibling;
      if (panel.style.maxHeight) {
        panel.style.maxHeight = null;
      } else {
        panel.style.maxHeight = panel.scrollHeight + "px";
      }
    }
}

import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import { ProfilesettingsService } from './profilesettings.service';
import Swal from 'sweetalert2';
import { NgxSpinnerService } from "ngx-spinner";



@Component({
  selector: 'app-profilesettings',
  templateUrl: './profilesettings.component.html',
  styleUrls: ['./profilesettings.component.css']
})
export class ProfilesettingsComponent implements OnInit {
  registerForm: FormGroup;
  submitted = false;
  profileData: any;
  userName: any;
  mobileNumber: any;
  email: any;
  gender: any;
  userAddress: any;
  userCity: any;
  userState: any;
  zipcode: any;
  bloodGroup: any;
  profileForm: any;
  DOB: any;
  selectedOption: any;
  selectedOption1: any;

  formName: string;
  bloodGroupType = [
    { BloodGroupName: "A-", value: 1 },
    { BloodGroupName: "A+", value: 2 },
    { BloodGroupName: "B-", value: 3 },
    { BloodGroupName: "B+", value: 4 },
    { BloodGroupName: "AB-", value: 5 },
    { BloodGroupName: "AB+", value: 6 },
    { BloodGroupName: "O-", value: 7 },
    { BloodGroupName: "O+", value: 8 }

  ]
  sex = [
    { Gender: "Male", value: 1 },
    { Gender: "Female", value: 2 },

  ]

  selectedImage: File;
  url: string | ArrayBuffer;
  profileImage: any;

  constructor(
    private formBuilder: FormBuilder,
    private profilesettingsService: ProfilesettingsService,
    private router: Router,
    private route: ActivatedRoute,
    private spinner: NgxSpinnerService



  ) {


    this.profileForm = this.formBuilder.group({
      userName: ['', Validators.required,],
      mobileNumber: ['', Validators.required],
      countryCode: ['', Validators.required],
      email: ['', Validators.required],
      image: ['', Validators.required],
      gender: ['Male', Validators.required],
      userAddress: ['', Validators.required],
      userCity: ['', Validators.required],
      userState: ['', Validators.required],
      zipcode: ['', Validators.required],
      bloodGroup: ['', Validators.required],
      DOB: ['', Validators.required]
    });

  }

  ngOnInit() {
    this.spinner.show();
    this.profilesettingsService.registerData().subscribe(
      res => {
        this.userName = res.body['data'].profileDetails[0].userName;
        this.mobileNumber = res.body['data'].profileDetails[0].mobileNumber;
        this.email = res.body['data'].profileDetails[0].email;
        this.gender = res.body['data'].profileDetails[0].gender;
        this.userAddress = res.body['data'].profileDetails[0].userAddress;
        this.userCity = res.body['data'].profileDetails[0].userCity;
        this.userState = res.body['data'].profileDetails[0].userState;
        this.zipcode = res.body['data'].profileDetails[0].zipcode;
        this.bloodGroup = res.body['data'].profileDetails[0].bloodGroup;
        this.DOB = res.body['data'].profileDetails[0].DOB;
        this.profileImage = res.body['data'].profileDetails[0].image;
        this.url = res.body['data'].profileDetails[0].image;




        this.profileForm = new FormGroup({
          userName: new FormControl(this.userName),
          mobileNumber: new FormControl(this.mobileNumber),
          email: new FormControl(this.email),
          gender: new FormControl(this.gender),
          userAddress: new FormControl(this.userAddress),
          userCity: new FormControl(this.userCity),
          userState: new FormControl(this.userState),
          zipcode: new FormControl(this.zipcode),
          DOB: new FormControl(this.DOB),
          bloodGroup: new FormControl(this.bloodGroup)

        })
        this.spinner.hide();
      },
      err => {
        this.spinner.hide();

        this.router.navigateByUrl('/serverError');
      }
    );
    this.formName = 'Edit Profile';
  }
  handleFileInput(file: FileList) {

    this.selectedImage = file.item(0);
    // console.log(this.selectedImage)
    const reader = new FileReader();

    reader.readAsDataURL(this.selectedImage); // read file as data url

    reader.onload = (event) => { // called once readAsDataURL is completed
      this.url = reader.result;
      // console.log(this.url)

      this.profilesettingsService.fileUpload(this.selectedImage).subscribe(
        res => {
          // console.log(res)
          this.profileImage = res.body['data'].imageURL;
        }
      )
    };

  }




  onSubmit() {
    this.spinner.show();

    const profileData = this.profileForm.value;
    // console.log(this.profileImage)
    if (this.profileImage == undefined) {
      this.profileImage = 'test.png'

    }
    else {

    }
    const profiledata = { userName: profileData.userName, mobileNumber: profileData.mobileNumber, email: profileData.email, gender: profileData.gender, userAddress: profileData.userAddress, userCity: profileData.userCity, userState: profileData.userState, zipcode: profileData.zipcode, DOB: profileData.DOB, image: this.profileImage, bloodGroup: profileData.bloodGroup }

    this.profilesettingsService.updateProfile(profiledata).subscribe(
      res => {
        this.spinner.hide();
        const data = res.body['data']
        if (res.body['error'] === 'true') {
          this.spinner.hide();

          Swal({

            title: 'Error',
            text: res.body['message'],
            type: 'error',
            confirmButtonText: 'Ok',
          });
        } else {
          this.spinner.hide();

          this.router.navigate(['/user/profileupdate'], { state: profiledata });

        }
      });

  }
}



import { Component, OnInit } from '@angular/core';
import { WalletService } from '../wallet/wallet.service';
import { Router } from '@angular/router';
import { NgxSpinnerService } from "ngx-spinner";
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { AccountdetailsService } from '../accountdetails/accountdetails.service';
import Swal from 'sweetalert2';
import { Cookie } from 'ng2-cookies/ng2-cookies';

@Component({
  selector: 'app-directpay',
  templateUrl: './directpay.component.html',
  styleUrls: ['./directpay.component.css']
})
export class DirectpayComponent implements OnInit {

  cardForm: FormGroup;
  walletBalance: any;
  walletTransaction: any;
  pages: number;
  page = 1;
  submitted: boolean;
  cardList: any;
  plan: string;
  days: string;
  accessToken: any;
  amount: string;
  constructor(
    private WalletService: WalletService,
    private formBuilder: FormBuilder,
    private router: Router,
    private spinner: NgxSpinnerService,
    private AccountdetailsService: AccountdetailsService,
  ) {
    this.cardForm = this.formBuilder.group({
      amount: ['', [Validators.required,]],
      cardNumber: ['']
      // expiry: ['', [Validators.required, Validators.maxLength(16)]],
      // cvc: ['', [Validators.required, Validators.maxLength(16)]],
    }, {
      });
  }
  // convenience getter for easy access to form fields
  get f() {
    return this.cardForm.controls;
  }
  ngOnInit() {
    this.spinner.show();
    this.AccountdetailsService.listCard().subscribe(
      response => {
        this.spinner.hide();
        this.cardList = response.body['data'];
      },
      err => {
        this.spinner.hide();
        this.router.navigateByUrl('/serverError');
      }
    );
    var data = { page: 1 }
    this.WalletService.myWallet().subscribe(
      response => {
        this.spinner.hide();
        this.walletBalance = response.body['data'].walletBalance;
        this.walletTransaction = response.body['data'].walletTransaction;
        this.pages = response.body['data'].pageCount * 10;
      },
      err => {
        this.spinner.hide();
        this.router.navigateByUrl('/serverError');
      }
    );
  }
  onSubmit() {
    this.submitted = true;
    this.spinner.show();

    // stop here if form is invalid
    if (this.cardForm.invalid) {
      return;
      this.spinner.hide();

    }
    var cardData = this.cardForm.value;
    var data = { amount: cardData.amount, source: cardData.cardNumber, days: this.days, plan: this.plan }
    this.WalletService.addDirect(data).subscribe(
      response => {
        this.spinner.hide();
        this.spinner.hide();
        if (response.body['error'] == true) {
          Swal({
            title: 'Error',
            text: response.body['message'],
            type: 'error',
            confirmButtonText: 'Ok',
          });
        } else {
          this.router.navigate(['/home']);
        }
      },
      err => {
        this.spinner.hide();
        this.router.navigateByUrl('/serverError');
      }
    );
    // display form values on success
  }
  addCard() {
    const cardData = { addcardview: true }
    this.router.navigate(['/user/payment'], { state: cardData });
  }

  oneMonthPlane1() {
    this.spinner.show();

    if (this.cardList == '') {
      this.spinner.hide();
      this.router.navigateByUrl('/user/payment');

    }

    this.accessToken = Cookie.get('token');
    if (this.accessToken == undefined) {
      this.spinner.hide();
      Swal({
        title: '',
        text: 'To subscription your plus now, log in to your existing account or sign up.',
        type: 'error',
        confirmButtonText: 'Ok',
      });
      this.router.navigateByUrl('/login');
    }
    this.spinner.hide();

    this.amount = '70';
    this.days = '28'
    this.plan = 'individual'
    this.cardForm = new FormGroup({
      amount: new FormControl(this.amount),
      cardNumber: new FormControl(),

    })

  }

  oneMonthPlane2() {
    this.spinner.show();

    if (this.cardList == '') {
      this.spinner.hide();
      this.router.navigateByUrl('/user/payment');
    }

    this.accessToken = Cookie.get('token');
    if (this.accessToken == undefined) {
      this.spinner.hide();
      Swal({
        title: '',
        text: 'To subscription your plus now, log in to your existing account or sign up.',
        type: 'error',
        confirmButtonText: 'Ok',
      });
      this.router.navigateByUrl('/login');
    }
    this.spinner.hide();

    this.amount = '200';
    this.days = '28'
    this.plan = 'family'
    this.cardForm = new FormGroup({
      amount: new FormControl(this.amount),
      cardNumber: new FormControl(),

    })

  }

  oneMonthPlane3() {
    this.spinner.show();

    if (this.cardList == '') {
      this.spinner.hide();
      this.router.navigateByUrl('/user/payment');

    }

    this.accessToken = Cookie.get('token');
    if (this.accessToken == undefined) {
      this.spinner.hide();
      Swal({
        title: '',
        text: 'To subscription your plus now, log in to your existing account or sign up.',
        type: 'error',
        confirmButtonText: 'Ok',
      });
      this.router.navigateByUrl('/login');
    }
    this.spinner.hide();

    this.amount = '300';
    this.days = '28'
    this.plan = 'family'
    this.cardForm = new FormGroup({
      amount: new FormControl(this.amount),
      cardNumber: new FormControl(),

    })

  }

  oneMonthPlane4() {
    this.spinner.show();

    if (this.cardList == '') {
      this.spinner.hide();
      this.router.navigateByUrl('/user/payment');

    }

    this.accessToken = Cookie.get('token');
    if (this.accessToken == undefined) {
      this.spinner.hide();
      Swal({
        title: '',
        text: 'To subscription your plus now, log in to your existing account or sign up.',
        type: 'error',
        confirmButtonText: 'Ok',
      });
      this.router.navigateByUrl('/login');
    }
    this.spinner.hide();

    this.amount = '400';
    this.days = '28'
    this.plan = 'family'
    this.cardForm = new FormGroup({
      amount: new FormControl(this.amount),
      cardNumber: new FormControl(),

    })

  }
}

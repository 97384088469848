import { Component } from '@angular/core';
import { NgProgress } from 'ngx-progressbar';
import { NgxSpinnerService } from "ngx-spinner";

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent {
  title = 'docnow';
  constructor(
    public ngProgress: NgProgress,  private spinner: NgxSpinnerService,
  ) {
    // this.spinnerAlert('show');
    // setTimeout(() => { this.spinnerAlert('stop'); }, 3000);
  }

  spinnerAlert(show) {
    if (show === 'show') {
      this.ngProgress.start();
    } else {
      this.ngProgress.done();
    }
  }
  showSpinner() {
    this.spinner.show();
    setTimeout(() => {
        /** spinner ends after 5 seconds */
        this.spinner.hide();
    }, 5000);
  }
}

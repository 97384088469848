import { Injectable } from '@angular/core';
import { environment } from '../../environments/environment';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Cookie } from 'ng2-cookies/ng2-cookies';
import { from } from 'rxjs';

@Injectable({
	providedIn: 'root'
})
export class NewBillingService {

	baseUrl = environment.baseUrl;
	accessToken = Cookie.get('token');

	constructor(private http: HttpClient) {
	}

	requestData(apiData) {
		const httpHeaders = new HttpHeaders({
			'role': 'user',
			'Content-Type': 'application/json',
		});
		return this.http.post(this.baseUrl + '/public/v2/requestPractioner', apiData, {
			headers: httpHeaders,
			observe: 'response'
		});
	}

	searchDefaultData() {
		const httpHeaders = new HttpHeaders({
			'role': 'user',
			'Content-Type': 'application/json',
		});
		return this.http.get(this.baseUrl + '/user/getAllCategory', {
			headers: httpHeaders,
			observe: 'response'
		});
	}
}

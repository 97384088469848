import { Component, OnInit, ViewChild, ElementRef, NgZone, AfterViewInit } from '@angular/core';
import { MapsAPILoader, MouseEvent } from '@agm/core';
import { GoogleMapsAPIWrapper } from '@agm/core/services';
import { FormBuilder, FormGroup, Validators, FormControl } from '@angular/forms';
import { google } from "google-maps";
import { HomeService } from './home.service';
import { Cookie } from 'ng2-cookies/ng2-cookies';
declare const google: any;
import { Router, ActivatedRoute } from '@angular/router';
import * as $ from '../../assets/js/jquery.min.js';
import { NgxSpinnerService } from "ngx-spinner";
import Swal from 'sweetalert2';
import { LoadJsService } from '../layouts/load-js.service';
import { SeoService } from '../layouts/seo.service';
import { Observable, OperatorFunction } from 'rxjs';
import { debounceTime, distinctUntilChanged, map } from 'rxjs/operators';
import { Ng2DeviceService } from 'ng2-device-detector';
import SwiperCore, {
  Navigation,
  Pagination,
  Scrollbar,
  Swiper,
  A11y,
  Virtual,
  Zoom,
  Autoplay,
  Thumbs,
  Controller,
} from 'swiper/core';
SwiperCore.use([
  Navigation,
  Pagination,
  Scrollbar,
  A11y,
  Virtual,
  Zoom,
  Autoplay,
  Thumbs,
  Controller
]);
declare var $;

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.css']
})
export class HomeComponent implements OnInit, AfterViewInit {
  accessToken = Cookie.get('token');
  userName = Cookie.get('userName');
  image = Cookie.get('image');
  hide: boolean;
  latitude: any;
  longitude: any;
  zoom: number;
  address: string;
  wikiItems: any[] = [];
  geoCoder: any;
  searchForm: any;
  speciality: any;
  blogsList: any;
  subscriptionData: any;
  directPayId: any;
  plusMemberId: any;
  categoryId: any;
  errormessage: String
  categoryArr: any[] = [];
  // categoryArr: [] =  [{"categoryId":1,"categoryName":"Cardiology","categoryImage":"https://app.docnowmd.com/uploads/admin/1598335835713.png","count":3},{"categoryId":2,"categoryName":"Pediatrician","categoryImage":"https://app.docnowmd.com/uploads/admin/1598335889427.png","count":2},{"categoryId":3,"categoryName":"Homeopathy","categoryImage":"https://app.docnowmd.com/uploads/admin/1598335944321.png","count":1},{"categoryId":4,"categoryName":"General Physician","categoryImage":"https://app.docnowmd.com/uploads/admin/1598336011800.png","count":1},{"categoryId":5,"categoryName":"Kidney Issues","categoryImage":"https://app.docnowmd.com/uploads/admin/1598336057099.png","count":1},{"categoryId":6,"categoryName":"Mental Wellness","categoryImage":"https://app.docnowmd.com/uploads/admin/1598336200563.png","count":2},{"categoryId":7,"categoryName":"Digestive","categoryImage":"https://app.docnowmd.com/uploads/admin/1598336261896.png","count":1},{"categoryId":8,"categoryName":"Cancer","categoryImage":"https://app.docnowmd.com/uploads/admin/1598336308094.png","count":1},{"categoryId":9,"categoryName":"Surgery","categoryImage":"https://app.docnowmd.com/uploads/admin/1598336337095.png","count":1},{"categoryId":10,"categoryName":"Dental Care","categoryImage":"https://app.docnowmd.com/uploads/admin/1598336378592.png","count":1}]
  topSpeciality: any;
  recentlyVisitedDoctors: any;
  recentlyVisitedDoctorsShow: boolean = false;
  plus: any;
  memberId: any;
  date: any;
  lat: any;
  lng: any;
  catId: any = 1;
  footerYear: any = new Date().getFullYear();
  google: any;
  topDoctorsStatus: boolean = true
  showDentalCare: boolean = false
  showGeneralCare: boolean = false


  @ViewChild('search')
  public searchElementRef: ElementRef;
  location: string;
  docnowLocations: any;
  topDentalDoctorsService: any;
  topGeneralDoctors: any;
  filteredcategory: any[];
  private deviceInfo;
  private isMobile: boolean = false;


  constructor(
    private mapsAPILoader: MapsAPILoader,
    private homeService: HomeService,
    private ngZone: NgZone,
    private formBuilder: FormBuilder,
    private router: Router,
    private spinner: NgxSpinnerService,
    private jsObj: LoadJsService,
    private seoObj: SeoService,
    private deviceService: Ng2DeviceService
  ) {
    this.searchForm = this.formBuilder.group({
      location: ['', Validators.required],
      speciality: ['', Validators.required]
    })

  };
  ngAfterViewInit() {

  }

  doctorProfile(doctorId) {
    const doctorData = { doctorId: doctorId }
    Cookie.set('doctorId', doctorId);
    this.router.navigate(['/doctorprofile'], { state: doctorData });
  }


  showSpinner() {
    this.spinner.show();
    setTimeout(() => {
      /** spinner ends after 5 seconds */
      this.spinner.hide();
    }, 5000);
  }
  onSwiper(swiper) {
    console.log(swiper);
  }
  onSlideChange() {
    console.log('slide change');
  }
  breakpoints = {
    640: { slidesPerView: 1 },
    768: { slidesPerView: 1 },
    1024: { slidesPerView: 3 },
  };
  //   swiperConfig: any = {

  //     breakpoints: {
  //         640: {
  //           slidesPerView: 1
  //         }
  //     }
  // }
  // breakPointsToggle: boolean;
  // breakpointChange(eve) {

  //   this.breakPointsToggle = !this.breakPointsToggle;
  //   this.breakpoints = {
  //     640: { slidesPerView: 2 },
  //     768: { slidesPerView: 4 },
  //     1024: { slidesPerView: this.breakPointsToggle ? 3 : 5},
  //   };
  // }
  private loadSeoTags() {
    this.seoObj.setTitle('DocNow | Find a Doctor Near You | Book Doctors Online')
    this.seoObj.setDescription('Search for your doctor, right now with DocNow. Book an appointment with a physician nearby. Sign up to DocNow Plus and get incredible discounts on regular urgent care visits.')
    this.seoObj.setAuthor('DocNow LLC')
    this.seoObj.setKeyword('DocNow | Find a Doctor Near You | Book Doctors Online')
    this.seoObj.setOGTitle('DocNow | Find a Doctor Near You | Book Doctors Online')
    this.seoObj.setOGDescription('Search for your doctor, right now with DocNow. Book an appointment with a physician nearby. Sign up to DocNow Plus and get incredible discounts on regular urgent care visits.')
  }


  searchSpeciality(event) {
    // console.log('i am called', this.searchForm.value)
    const data = { name: this.searchForm.value.speciality }
    this.homeService.search(data).subscribe(async res => {
      this.speciality = res.body['data'].categories;
      // console.log('countries', this.speciality)
    })
  }

  dropdownList = [];
  selectedItems = [];
  dropdownSettings = {};

  public model: any;

  detectMobil() {
    if (/(android|bb\d+|meego).+mobile|avantgo|bada\/|blackberry|blazer|compal|elaine|fennec|hiptop|iemobile|ip(hone|od)|ipad|iris|kindle|Android|Silk|lge |maemo|midp|mmp|netfront|opera m(ob|in)i|palm( os)?|phone|p(ixi|re)\/|plucker|pocket|psp|series(4|6)0|symbian|treo|up\.(browser|link)|vodafone|wap|windows (ce|phone)|xda|xiino/i.test(navigator.userAgent)
      || /1207|6310|6590|3gso|4thp|50[1-6]i|770s|802s|a wa|abac|ac(er|oo|s\-)|ai(ko|rn)|al(av|ca|co)|amoi|an(ex|ny|yw)|aptu|ar(ch|go)|as(te|us)|attw|au(di|\-m|r |s )|avan|be(ck|ll|nq)|bi(lb|rd)|bl(ac|az)|br(e|v)w|bumb|bw\-(n|u)|c55\/|capi|ccwa|cdm\-|cell|chtm|cldc|cmd\-|co(mp|nd)|craw|da(it|ll|ng)|dbte|dc\-s|devi|dica|dmob|do(c|p)o|ds(12|\-d)|el(49|ai)|em(l2|ul)|er(ic|k0)|esl8|ez([4-7]0|os|wa|ze)|fetc|fly(\-|_)|g1 u|g560|gene|gf\-5|g\-mo|go(\.w|od)|gr(ad|un)|haie|hcit|hd\-(m|p|t)|hei\-|hi(pt|ta)|hp( i|ip)|hs\-c|ht(c(\-| |_|a|g|p|s|t)|tp)|hu(aw|tc)|i\-(20|go|ma)|i230|iac( |\-|\/)|ibro|idea|ig01|ikom|im1k|inno|ipaq|iris|ja(t|v)a|jbro|jemu|jigs|kddi|keji|kgt( |\/)|klon|kpt |kwc\-|kyo(c|k)|le(no|xi)|lg( g|\/(k|l|u)|50|54|\-[a-w])|libw|lynx|m1\-w|m3ga|m50\/|ma(te|ui|xo)|mc(01|21|ca)|m\-cr|me(rc|ri)|mi(o8|oa|ts)|mmef|mo(01|02|bi|de|do|t(\-| |o|v)|zz)|mt(50|p1|v )|mwbp|mywa|n10[0-2]|n20[2-3]|n30(0|2)|n50(0|2|5)|n7(0(0|1)|10)|ne((c|m)\-|on|tf|wf|wg|wt)|nok(6|i)|nzph|o2im|op(ti|wv)|oran|owg1|p800|pan(a|d|t)|pdxg|pg(13|\-([1-8]|c))|phil|pire|pl(ay|uc)|pn\-2|po(ck|rt|se)|prox|psio|pt\-g|qa\-a|qc(07|12|21|32|60|\-[2-7]|i\-)|qtek|r380|r600|raks|rim9|ro(ve|zo)|s55\/|sa(ge|ma|mm|ms|ny|va)|sc(01|h\-|oo|p\-)|sdk\/|se(c(\-|0|1)|47|mc|nd|ri)|sgh\-|shar|sie(\-|m)|sk\-0|sl(45|id)|sm(al|ar|b3|it|t5)|so(ft|ny)|sp(01|h\-|v\-|v )|sy(01|mb)|t2(18|50)|t6(00|10|18)|ta(gt|lk)|tcl\-|tdg\-|tel(i|m)|tim\-|t\-mo|to(pl|sh)|ts(70|m\-|m3|m5)|tx\-9|up(\.b|g1|si)|utst|v400|v750|veri|vi(rg|te)|vk(40|5[0-3]|\-v)|vm40|voda|vulc|vx(52|53|60|61|70|80|81|83|85|98)|w3c(\-| )|webc|whit|wi(g |nc|nw)|wmlb|wonu|x700|yas\-|your|zeto|zte\-/i.test(navigator.userAgent.substr(0, 4))) this.isMobile = true;
  }

  ngOnInit() {
    // this.deviceInfo = this.deviceService.getDeviceInfo();
    // this.detectMobil();
    // console.log(this.isMobile, this.deviceInfo.device);
    // if (this.isMobile) {
    //   if (this.deviceInfo.device === 'android') {
    //     window.location.href = "https://play.google.com/store/apps/details?id=com.docnow.android&hl=en&gl=US"
    //   }
    //   if (this.deviceInfo.device === 'iphone') {
    //     window.location.href = "https://apps.apple.com/us/app/docnow-find-doctors-now/id1479953248"
    //   }
    // }
    this.loadSeoTags()
    this.spinner.show();
    if (this.userName != null) {
      this.hide = true
    } else {
      this.hide = false
    }
    this.spinner.hide();



    if (this.accessToken == undefined) {
      this.directPayId = 'invalid'
      this.plusMemberId = 'invalid'
    }

    this.homeService.searchDefaultData().subscribe(async res => {
      this.dropdownList = res.body['data'].categories
      // console.log(this.dropdownList)
      var cat = res.body['data'].categories
      this.categoryArr = cat
      this.filteredcategory = this.categoryArr.map(data => {
        return data.categoryName
      })

    })

    this.homeService.findLocation().subscribe(async res => {
      this.docnowLocations = res.body['data'].locations
      // console.log(this.docnowLocations)
    })

    this.setCurrentLocation();
    this.spinner.show();
    this.homeService.blogList().subscribe(async res => {
      this.blogsList = res.body['data'].blogs;
      // console.log(this.blogsList)
      this.spinner.hide();
    },
      err => {
        this.spinner.hide();
        // this.router.navigateByUrl('/serverError');
      }
    );


    var location = { latitude: this.latitude, longitude: this.longitude }
    this.homeService.doctorData(location).subscribe(async resp => {
      this.topDentalDoctorsService = resp.body['data'].topDentalDoctorsService
      if (this.topDentalDoctorsService.length == 0) {
        this.showDentalCare = true
      }
      this.topGeneralDoctors = resp.body['data'].topGeneralDoctors
      if (this.topGeneralDoctors.length == 0) {
        this.showGeneralCare = true
      }
    })

    this.homeService.subscriptionData().subscribe(async res => {
      this.subscriptionData = res.body['data'].userSubscriptionDetails;
      // console.log(this.subscriptionData)
      localStorage.setItem("subscriptionData", this.subscriptionData[0])
      if (this.subscriptionData.length != 0) {
        this.directPayId = res.body['data'].userSubscriptionDetails[0].directPayId;
        this.plusMemberId = res.body['data'].userSubscriptionDetails[0].plusMemberId;
        localStorage.setItem("plusMemberId", this.plusMemberId)
        //  console.log(this.directPayId ,this.plusMemberId )
      }
      this.spinner.hide();
    },
      err => {
        this.spinner.hide();
        // this.router.navigateByUrl('/serverError');
      }
    );
    this.homeService.topSpecialityService().subscribe(async res => {
      this.topSpeciality = res.body['data'].topSpeciality;
      this.recentlyVisitedDoctors = res.body['data'].recentlyVisitedDoctors;
      // console.log(this.recentlyVisitedDoctors)
      if (this.recentlyVisitedDoctors.length > 0) {
        this.recentlyVisitedDoctorsShow = true
      }
      this.spinner.hide();
    },
      err => {
        this.spinner.hide();
        // this.router.navigateByUrl('/serverError');
      }
    );


    //load Places Autocomplete
    this.mapsAPILoader.load().then(() => {
      this.setCurrentLocation();
      this.geoCoder = new google.maps.Geocoder;

      let autocomplete = new google.maps.places.Autocomplete(this.searchElementRef.nativeElement);
      autocomplete.addListener("place_changed", () => {
        this.ngZone.run(() => {
          //get the place result
          let place: google.maps.places.PlaceResult = autocomplete.getPlace();
          //set latitude, longitude and zoom
          this.latitude = place.geometry.location.lat();
          this.longitude = place.geometry.location.lng();
          Cookie.set('latitude', this.latitude)
          Cookie.set('longitude', this.longitude);
          // console.log('location details', this.latitude, this.longitude)
          this.errormessage = ""
          this.zoom = 4;
        });
      });
    });

    var arr = []

    this.dropdownSettings = {
      singleSelection: true,
      text: "Search Speciality",
      enableSearchFilter: true,
      classes: "form-control search-info form-group text",
      searchAutofocus: false,
      noDataLabel: "No Search Results Found",
      lazyLoading: true,
      enableFilterSelectAll: false,
      labelKey: 'categoryName',
      primaryKey: 'categoryId',
      searchBy: ["categoryName"],
    };


    this.getMobileOperatingSystem()
    Cookie.delete('categoryId');
    Cookie.delete('specialityName');
    // this.spinner.hide();
  }



  getMobileOperatingSystem() {
    var userAgent = navigator.userAgent || navigator.vendor;

    // Windows Phone must come first because its UA also contains "Android"
    if (/windows phone/i.test(userAgent)) {
      return "https://app.docnowonline.com/docnow-enterprise";
    }

    if (/android|Android/i.test(userAgent)) {
      return "https://play.google.com/store/apps/details?id=com.docnow.android&hl=en&gl=US";
    }

    // iOS detection from: http://stackoverflow.com/a/9039885/177710
    if (/iPad|iPhone|iPod/.test(userAgent)) {
      return "https://apps.apple.com/us/app/docnow-find-doctors-now/id1479953248";
    }

    return "https://app.docnowonline.com/docnow-enterprise";
  }



  searchdoctorlist(data) {
    // console.log("data",data)
    Cookie.set('specialityId', data.toString());
    Cookie.set('categoryId', data.toString());
    const categoryData = { categoryId: data.toString(), latitude: this.latitude, longitude: this.longitude }
    // console.log("categoryData",categoryData)
    if (categoryData.latitude == null || categoryData.longitude == null) {
      Swal('Oops...', 'Please Select Your Location!', 'error')
    } else {
      this.router.navigate(['/search'], { state: categoryData });
    }

  }
  DirectView(subscriptionData) {
    this.memberId = subscriptionData[0].directPayId;
    this.date = subscriptionData[0].directPayDate;

  }

  plusView(subscriptionData) {
    this.memberId = subscriptionData[0].plusMemberId;
    this.date = subscriptionData[0].plusMemberDate;
  }


  onItemSelect(item: any) {
    // console.log("item", item)
    this.categoryId = item.categoryId;
    Cookie.set('specialityId', item.categoryId);
    Cookie.set('categoryId', item.categoryId);
    Cookie.set('specialityName', item.categoryName);
  }
  OnItemDeSelect(item: any) {
  }
  onSelectAll(items: any) {
  }
  onDeSelectAll(items: any) {
  }

  // Get Current Location Coordinates
  private setCurrentLocation() {
    if ('geolocation' in navigator) {
      navigator.geolocation.getCurrentPosition((position) => {
        this.latitude = position.coords.latitude;
        this.longitude = position.coords.longitude;
        Cookie.set('latitude', this.latitude)
        Cookie.set('longitude', this.longitude);
        this.zoom = 8;
        this.getAddress(this.latitude, this.longitude);
      });
    } else {
      this.topDoctorsStatus = false
    }
  }


  markerDragEnd($event: MouseEvent) {
    this.latitude = $event.coords.lat;
    this.longitude = $event.coords.lng;
    this.getAddress(this.latitude, this.longitude);
  }

  getAddress(latitude, longitude) {
    console.log(latitude, longitude)
    let geoCoder = this.google.maps.Geocoder();
    this.geoCoder.geocode({ 'location': { lat: latitude, lng: longitude } }, (results, status) => {
      // if (status === 'OK') {
      if (status === this.google.maps.GeocoderStatus.OK) {
        console.log(results[0], 'res');
        if (results[0]) {
          this.zoom = 12;
          this.address = results[0].formatted_address;
          // console.log(this.address)
          this.location = this.address
          this.searchForm = new FormGroup({
            location: new FormControl(this.address),
            speciality: new FormControl()
          })
        } else {
          window.alert('No results found');
        }
      } else {
        window.alert('Geocoder failed due to: ' + status);
      }

    });
  }


  searchdoctorList(id) {
    const categoryData = { categoryId: id, latitude: this.latitude, longitude: this.longitude }
    this.router.navigate(['/search'], { state: categoryData });
  }

  searchdoctor() {

    this.categoryId = Cookie.get('categoryId');
    if (this.latitude && this.longitude) {
      if (this.categoryId) {
        const categoryData = { categoryId: this.categoryId.toString(), latitude: this.latitude, longitude: this.longitude }
        this.router.navigate(['/search'], { state: categoryData });
      } else {
        this.errormessage = "Please pick one Specialty from the below list"
      }
    } else {
      this.errormessage = "Please pick one Location from the below list"
    }

  }

  topSpecialityClick(categoryId) {
    Cookie.set('categoryId', categoryId);
    if (this.latitude && this.longitude) {
      this.searchdoctor()
    } else {
      Swal('Oops...', 'Please Select Your Location!', 'error')
    }
  }


  onFocused(e) {
    // do something
  }

  //searchUrgentCareDoctor List
  searchUrgentCareDoctor() {
    const urgentCareData = { isUrgentCare: 1, latitude: this.latitude, longitude: this.longitude }
    this.router.navigate(['/urgentcare'], { state: urgentCareData });
  }

  //Doctor List
  findDoctor() {
    const normalDoctorData = { categoryId: '1', latitude: this.latitude, longitude: this.longitude }
    this.router.navigate(['/search'], { state: normalDoctorData });
  }

  createAccount() {
    this.router.navigate(['/register']);
  }

  directDoctor() {
    // const directDoctorData = { categoryId:'1',latitude: this.latitude, longitude : this.longitude }
    // this.router.navigate(['/directDoctor'], { state: directDoctorData });
    const hospitalData = { categoryId: '1', latitude: this.latitude, longitude: this.longitude }
    this.router.navigate(['/ChildrenHospitalLocation'], { state: hospitalData });
  }
  hospitallocation() {
    const hospitalData = { categoryId: '1', latitude: this.latitude, longitude: this.longitude }
    this.router.navigate(['/hospitallocation'], { state: hospitalData });
  }
  booking(id) {
    const doctorData = { doctorId: id }

    this.router.navigate(['/booking'], { state: doctorData });

  }
  doctorprofile(id) {
    const doctorData = { doctorId: id }

    this.router.navigate(['/doctorprofile'], { state: doctorData });
  }


  blogDetails(blogs) {
    // this.router.navigate(['/blogDetails'], { state: blogs });
    var routeLink = '/blogDetails/' + blogs.id + '/' + blogs.title
    this.router.navigate([routeLink], { state: blogs });

  }


  unsubscribePlus() {
    this.spinner.show();
    Swal({
      title: 'Please Enter Your Feedback',
      input: 'text',
      inputValidator: (value) => {
        if (value) {
          var data = { reason: value }
          this.homeService.UnsubscribePlus(data).subscribe(async res => {
            // console.log(res)
            Swal({
              title: 'success',
              text: 'Unsubscribe Successfully',
              type: 'success',
              showConfirmButton: false,
              timer: 1000
            });
            window.location.reload();

          });
        }
        return !value && 'You need to write something!'
      },
      type: "warning",
      showCancelButton: true,
      confirmButtonColor: '#DD6B55',
      confirmButtonText: 'Yes, I am sure!',
      cancelButtonText: "No, cancel it!",
      // closeOnConfirm: false,
      // closeOnCancel: false

    }),

      this.spinner.hide();
  }
  unsubscribePay() {
    this.spinner.show();

    Swal({
      title: 'Please Enter Your Feedback',
      input: 'text',
      inputValidator: (value) => {
        if (value) {
          var data = { reason: value }
          this.homeService.UnsubscribePay(data).subscribe(async res => {
            window.location.reload();

            // console.log(res)
            Swal({
              title: 'success',
              text: 'Unsubscribe Successfully',
              type: 'success',
              showConfirmButton: false,
              timer: 1000
            });

          });
        }
        return !value && 'You need to write something!'
      },
      type: "warning",
      showCancelButton: true,
      confirmButtonColor: '#DD6B55',
      confirmButtonText: 'Yes, I am sure!',
      cancelButtonText: "No, cancel it!",
      // closeOnConfirm: false,
      // closeOnCancel: false

    }),

      this.spinner.hide();




  }
  login() {
    this.router.navigate(['/login']);

  }
  blog() {
    this.router.navigate(['/blogList']);

  }
  logout() {
    this.spinner.show();
    // this.homeService.logout().subscribe(
    //   async response => {
    // this.spinner.hide();
    //     const data = response.body['data']
    //     if (response.body['error'] === 'true') {
    //     }
    //     else {
    Cookie.deleteAll('/');
    Cookie.delete('token');
    Cookie.delete('image');
    Cookie.delete('userName');
    Cookie.delete('latitude');
    Cookie.delete('latitude');
    Cookie.delete('doctorId');
    localStorage.clear();
    sessionStorage.clear();
    this.spinner.hide();
    this.router.navigateByUrl('/login');
    // }
    // }
    // );

  }


  private loadScripts() {
    this.jsObj.load('jquery').then(async data => {
      this.jsObj.load('script').then(async data => {
        this.jsObj.load('bootstrap').then(async data => {
          this.jsObj.load('resizesensor').then(async data => {
            this.jsObj.load('popper').then(async data => {
              // this.jsObj.load('slick').then(async data => {
              //         this.jsObj.load('moment').then(async data => {
              //           this.jsObj.load('daterangepicker').then(async data => {
              //               this.jsObj.load('sidebar').then(async data => {
              //                 this.jsObj.load('fancybox').then(async data => {
              //                   this.jsObj.load('datetimepicker').then(async data => {
              //                     this.jsObj.load('select2').then(async data => {
              //                     }).catch(error => console.log(error));
              //                   }).catch(error => console.log(error));
              //                 }).catch(error => console.log(error));
              //               }).catch(error => console.log(error));
              //             }).catch(error => console.log(error));
              //           }).catch(error => console.log(error));
              // }).catch(error => console.log(error));
            }).catch(error => console.log(error));
          }).catch(error => console.log(error));
        }).catch(error => console.log(error));
      }).catch(error => console.log(error));
    }).catch(error => console.log(error));
  }




}






import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import { InsuranceService } from './add-insurance.service';
import Swal from 'sweetalert2';
import { NgxSpinnerService } from "ngx-spinner";



@Component({
  selector: 'app-add-insurance',
  templateUrl: './add-insurance.component.html',
  styleUrls: ['./add-insurance.component.css']
})
export class AddInsuranceComponent implements OnInit {
  // registerForm: FormGroup;
  submitted = false;
  profileData: any;
  userName: any;
  mobileNumber: any;
  email: any;
  gender: any;
  userAddress: any;
  userCity: any;
  userState: any;
  zipcode: any;
  bloodGroup: any;
  insuranceForm: any;
  DOB: any;
  selectedOption: any;
  selectedOption1: any;

  formName: string;

  selectedImage: File;
  url: string | ArrayBuffer;
  profileImage: any;
  url1: string | ArrayBuffer;
  url2: string | ArrayBuffer;
  insuranceImage1: any;
  insuranceImage2: any;

  constructor(
    private formBuilder: FormBuilder,
    private insuranceService: InsuranceService,
    private router: Router,
    private route: ActivatedRoute,
    private spinner: NgxSpinnerService
  ) {
    this.insuranceForm = this.formBuilder.group({
      insuranceCarier: ['', Validators.required,],
      insuranceType: ['', Validators.required],
      memberId: ['', Validators.required],
      image: ['', Validators.required],
      validity: ['', Validators.required],
      firstName: ['', Validators.required],
      lastName: ['', Validators.required]
    });

  }

  ngOnInit() {
    // this.spinner.show();
    //     this.profilesettingsService.registerData().subscribe(
    //       res => {
    //         this.userName = res.body['data'].profileDetails[0].userName;
    //         this.mobileNumber = res.body['data'].profileDetails[0].mobileNumber;
    //         this.email = res.body['data'].profileDetails[0].email;
    //         this.gender = res.body['data'].profileDetails[0].gender;
    //         this.userAddress = res.body['data'].profileDetails[0].userAddress;
    //         this.userCity = res.body['data'].profileDetails[0].userCity;
    //         this.userState = res.body['data'].profileDetails[0].userState;
    //         this.zipcode = res.body['data'].profileDetails[0].zipcode;
    //         this.bloodGroup = res.body['data'].profileDetails[0].bloodGroup;
    //         this.DOB = res.body['data'].profileDetails[0].DOB;
    //         this.profileImage=res.body['data'].profileDetails[0].image;
    //         this.url=res.body['data'].profileDetails[0].image;

    //  this.insuranceForm = new FormGroup({
    //       userName: new FormControl( this.userName),
    //       mobileNumber: new FormControl( this.mobileNumber),
    //       email: new FormControl( this.email),
    //       gender: new FormControl( this.gender),
    //       userAddress: new FormControl( this.userAddress),
    //       userCity: new FormControl( this.userCity),
    //       userState: new FormControl( this.userState),
    //       zipcode: new FormControl( this.zipcode),
    //       DOB: new FormControl( this.DOB),
    //       bloodGroup: new FormControl(this.bloodGroup)

    // })
    // this.spinner.hide();
    //       },
    //       err => {
    //         this.spinner.hide();

    //         this.router.navigateByUrl('/serverError');
    //       }
    //     );
    //     this.formName = 'Edit Profile';
  }
  handleFileInput1(file: FileList) {

    this.selectedImage = file.item(0);
    const reader = new FileReader();

    reader.readAsDataURL(this.selectedImage); // read file as data url

    reader.onload = (event) => { // called once readAsDataURL is completed
      this.url1 = reader.result;

      this.insuranceService.fileUpload(this.selectedImage).subscribe(
        res => {
          this.insuranceImage1 = res.body['data'].imageURL;
        }
      )
    };

  }

  handleFileInput2(file: FileList) {

    this.selectedImage = file.item(0);
    const reader = new FileReader();

    reader.readAsDataURL(this.selectedImage); // read file as data url

    reader.onload = (event) => { // called once readAsDataURL is completed
      this.url2 = reader.result;

      this.insuranceService.fileUpload(this.selectedImage).subscribe(
        res => {
          this.insuranceImage2 = res.body['data'].imageURL;
        }
      )
    };

  }


  onSubmit() {
    this.spinner.show();

    const insuranceData = this.insuranceForm.value;
    const profiledata = { insuranceCarier: insuranceData.insuranceCarier, insuranceType: insuranceData.insuranceType, memberId: insuranceData.memberId, firstName: insuranceData.firstName, lastName: insuranceData.lastName, validity: insuranceData.validity, insuranceImage1: this.insuranceImage1, insuranceImage2: this.insuranceImage2 }

    this.insuranceService.addInsurance(profiledata).subscribe(
      res => {
        this.spinner.hide();
        const data = res.body['data']
        if (res.body['error'] === 'true') {
          this.spinner.hide();

          Swal({

            title: 'Error',
            text: res.body['message'],
            type: 'error',
            confirmButtonText: 'Ok',
          });
        } else {
          this.spinner.hide();
          this.router.navigate(['/user/profileupdate'], { state: profiledata });

        }
      });

  }
}
import { Component, OnInit } from '@angular/core';
import { BookingService } from './booking.service';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import { VerifyotpService } from '../../app/verifyotp/verifyotp.service';
import { NgxSpinnerService } from "ngx-spinner";
import { Cookie } from 'ng2-cookies/ng2-cookies';
import { environment } from '../../environments/environment';
import {
  AuthService,
  SocialUser,
  FacebookLoginProvider,
  GoogleLoginProvider
} from 'angular5-social-login';
import Swal from 'sweetalert2';
declare var jQuery: any;
declare let gtag: Function;
declare var $;
@Component({
  selector: 'app-booking',
  templateUrl: './booking.component.html',
  styleUrls: ['./booking.component.css']
})
export class BookingComponent implements OnInit {

  doctorData: any;
  doctorId: any;
  doctorBooking: any;
  morningTimeSlots: any;
  eveningTimeSlots: any;
  bookingTime: any;
  submitted: any;
  bookingDate: any;
  doctorprofile: any;
  doctorImage: any;
  doctorsName: any;
  doctorAddress: any;
  dates: any = [];
  accessToken: string;
  teleHealth: any;
  categoryId = Cookie.get("categoryId")
  loginForm: FormGroup;
  errorMsg = false;
  private user: SocialUser;
  public authorized: boolean = false;
  name: any;
  userData: any;
  appURL = environment.appURL;
  adminData: any;
  otpNumber: any;
  otpForm: FormGroup;
  otpError = false;
  hideOTP: boolean = false;
  registerForm: FormGroup;
  registerError = false;
  constructor(
    private bookingService: BookingService,
    private formBuilder: FormBuilder,
    private verifyotpService: VerifyotpService,
    private router: Router,
    private spinner: NgxSpinnerService,
    private socialAuthService: AuthService,

  ) {
    this.doctorData = this.router.getCurrentNavigation().extras.state;
    this.adminData = this.router.getCurrentNavigation().extras.state;
  }

  ngOnInit() {

    if (this.adminData == undefined) {
      this.router.navigate(['/login']);
    }
    this.otpForm = this.formBuilder.group({
      OTP: [''],

    });
    this.loginForm = this.formBuilder.group({
      mobileNumber: ['', Validators.required],
      countryCode: ['1', Validators.required]
    });
    this.registerForm = this.formBuilder.group({
      countryCode: ['', [Validators.required,]],
      mobileNumber: ['', [Validators.required,]],
      email: ['', [Validators.required,]],
      firstname: ['', [Validators.required,]],
      lastname: ['', [Validators.required,]]
    });
    var date = new Date();
    this.getDates(date)
    let dte = new Date();
    dte.setDate(dte.getDate() - 2)
    this.spinner.show();

    if (this.doctorData == undefined) {
      this.spinner.hide();
      this.router.navigateByUrl('/');
    } else {
      var current = new Date();
      var day = current.getDate();
      var month = current.getMonth() + 1;
      var year = current.getFullYear();
      var dateString = year + '-' + month + '-' + day
      this.doctorId = this.doctorData.doctorId;
      const doctordata = { doctorsId: this.doctorId, date: dateString, status: 'true' }
      this.bookingService.booking(doctordata).subscribe(
        res => {
          this.spinner.hide();
          this.morningTimeSlots = res.body['data'].doctorAvailableMorningTimeslots;
          this.eveningTimeSlots = res.body['data'].doctorAvailableEveningTimeslots;
          this.doctorprofile = res.body['data'].doctorProfileDetails;

          this.doctorImage = this.doctorprofile[0].doctorsImage;
          this.doctorsName = this.doctorprofile[0].doctorsName;
          this.doctorAddress = this.doctorprofile[0].doctorAddress;
          // this.averageRating = this.doctorprofile[0].averageRating ? this.doctorprofile[0].averageRating : "Not Ranked Yet"
        },
        err => {
          this.spinner.hide();
          this.router.navigateByUrl('/serverError');
        }
      );
    }
  }

  get f() { return this.loginForm.controls; }
  get otp() { return this.otpForm.controls; }
  get signup() { return this.registerForm.controls; }

  arrowBack() {
    this.hideOTP = !this.hideOTP;
  }
  /* Social Login */
  public socialSignIn(socialPlatform: string) {
    let socialPlatformProvider;
    if (socialPlatform == "facebook") {
      socialPlatformProvider = FacebookLoginProvider.PROVIDER_ID;
    } else if (socialPlatform == "google") {
      socialPlatformProvider = GoogleLoginProvider.PROVIDER_ID;
    }

    this.socialAuthService.signIn(socialPlatformProvider).then(
      (userSocialData) => {
        this.userData = userSocialData
        this.name = this.userData.name
        this.userData.socialType = socialPlatform
        // console.log(socialPlatform + " sign in data : ", this.userData);
        var req = { email: this.userData.email, socialToken: this.userData.token, socialType: socialPlatform }
        this.bookingService.socialLogin(req).subscribe(
          res => {
            const data = res.body['data']
            if (res.body['error'] === 'true') {
              Swal({
                title: 'Error',
                text: res.body['message'],
                type: 'error',
                confirmButtonText: 'Ok',
              });
            } else {
              if (res.body['data'].token != null) {
                Cookie.set('token', res.body['data'].token);
                Cookie.set('image', res.body['data'].image);
                Cookie.set('userName', res.body['data'].userName);
                Cookie.set('id', res.body['data'].id);
                this.router.navigate(['/']);
              } else {
                this.router.navigate(['/socialsignup'], { state: this.userData });
              }
            }
          });
        // Now sign-in with userData        
        if (this.userData != null) {
          this.authorized = true;
          this.user = this.userData;
        }
      }
    );
  }
  /* End */
  public signOut() {
    this.socialAuthService.signOut();
    this.authorized = false;
  }
  /* Login */
  loginSubmit() {
    this.errorMsg = true;
    this.spinner.show();
    const loginData = this.loginForm.value;

    // stop here if form is invalid
    if (this.loginForm.invalid) {
      this.spinner.hide();
      return;
    }

    const loginSaveData = this.loginForm.value;
    var logindata = { mobileNumber: loginData.mobileNumber, countryCode: loginData.countryCode }
    this.bookingService.login(logindata).subscribe(
      res => {
        this.spinner.hide();
        const data = res.body['data']
        if (res.body['error'] === 'true') {
          Swal({
            title: 'Error',
            text: res.body['message'],
            type: 'error',
            confirmButtonText: 'Ok',
          });
        } else {
          // Cookie.set('token', res.body['data'].token);
          // Cookie.set('image', res.body['data'].image);
          // Cookie.set('userName', res.body['data'].userName);
          this.hideOTP = true;
          this.spinner.hide();
          // var logindata = { mobileNumber: loginData.mobileNumber, countryCode: loginData.countryCode, isNewUser: data.isNewUser }
          // this.router.navigate(['/verifyotp'], { state: logindata });
        }
      });

  }
  /* end */
  morningTimeslotSelect(bookingTime, timeArray) {
    this.bookingTime = bookingTime;
    this.morningTimeSlots = []
    var len = timeArray.length
    for (var i = 0; i < len; i++) {
      if (bookingTime === timeArray[i].timeslotId) {
        var myobj = { timeslotName: timeArray[i].timeslotName, fromTime: timeArray[i].fromTime, timeslotId: timeArray[i].timeslotId, status: 'true' }
      } else {
        var myobj =
          { timeslotName: timeArray[i].timeslotName, fromTime: timeArray[i].fromTime, timeslotId: timeArray[i].timeslotId, status: 'false' }
      }
      this.morningTimeSlots.push(myobj)
    }
  }
  eveningTimeslotSelect(bookingTime, timeArray) {
    this.bookingTime = bookingTime;
    this.eveningTimeSlots = []
    var len = timeArray.length
    for (var i = 0; i < len; i++) {
      if (bookingTime === timeArray[i].timeslotId) {
        var myobj = { timeslotName: timeArray[i].timeslotName, fromTime: timeArray[i].fromTime, timeslotId: timeArray[i].timeslotId, status: 'true' }
      } else {
        var myobj =
          { timeslotName: timeArray[i].timeslotName, fromTime: timeArray[i].fromTime, timeslotId: timeArray[i].timeslotId, status: 'false' }
      }
      this.eveningTimeSlots.push(myobj)
    }
  }
  selectDate(date, dateArray) {
    this.spinner.show();
    var dateReq = new Date(date);
    this.getDateStatus(dateReq, dateArray)
    this.bookingDate = date
    const doctordata = { doctorsId: this.doctorId, date: date }
    this.bookingService.booking(doctordata).subscribe(
      res => {
        this.spinner.hide();
        this.morningTimeSlots = res.body['data'].doctorAvailableMorningTimeslots;
        this.eveningTimeSlots = res.body['data'].doctorAvailableEveningTimeslots;
      },
      err => {
        this.spinner.hide();
        this.router.navigateByUrl('/serverError');
      }
    );

  }
  getDateStatus(date, dateArray) {
    this.dates = []
    var len = dateArray.length
    var last = new Date(date)
    var day = last.getDate();
    var month = last.getMonth() + 1;
    var year = last.getFullYear();
    var dateString = year + '-' + month + '-' + day
    for (var i = 0; i < len; i++) {
      if (dateString === dateArray[i].date) {
        var myobj = { dayName: dateArray[i].dayName, date: dateArray[i].date, datedisplay: dateArray[i].datedisplay, yearDisplay: dateArray[i].yearDisplay, status: 'true' }
      } else {
        var myobj = { dayName: dateArray[i].dayName, date: dateArray[i].date, datedisplay: dateArray[i].datedisplay, yearDisplay: dateArray[i].yearDisplay, status: 'false' }
      }
      this.dates.push(myobj)
    }
  }
  onSubmit() {
    this.accessToken = Cookie.get('token');
    if (this.accessToken == undefined) {
      Swal({
        title: '',
        text: 'To place your booking now, log in to your existing account or sign up.',
        type: 'error',
        confirmButtonText: 'Ok',
      });
      // jQuery("#exampleModalCenter").modal("show");
      $('#exampleModalCenter').modal('toggle')
    } else {
      this.spinner.show();
      if (this.doctorData == undefined) {
        // console.log("******** empty")
      } else {
        if (this.doctorData.isTelehealth) {
          this.teleHealth = '1'
        }
        else {
          this.teleHealth = '0'
        }
        this.doctorId = parseInt(this.doctorData.doctorId);
        const doctordata = { doctorsId: this.doctorId, timeslotId: this.bookingTime, bookingType: 'web', bookingDate: this.bookingDate, waitingList: '0', isTelemateBooking: this.teleHealth, categoryId: this.categoryId }
        this.bookingService.newBooking(doctordata).subscribe(
          res => {
            this.spinner.hide();
            const data = res.body['data']
            if (res.body['error'] == true) {
              Swal({
                title: 'Error',
                text: res.body['message'],
                type: 'error',
                confirmButtonText: 'Ok',
              });
            } else {
              this.spinner.hide();
              window.location.href = 'https://app.docnowonline.com/docnow-enterprise/bookingsuccess/index.html';
            }
          });
      }
    }

  }

  getDates(date) {
    this.dates = []
    var dayNames = ['Sun', 'Mon', 'Tue', 'Wed', 'Thus', 'Fri', 'Sat'];
    var months = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun ', 'Jul ', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
    for (var days = 0; days < 7; days++) {
      var last = new Date(date.getTime() + (days * 24 * 60 * 60 * 1000));
      var day = last.getDate();
      var month = last.getMonth() + 1;
      var thisMonth = months[last.getMonth()];
      var year = last.getFullYear();
      var dateString = year + '-' + month + '-' + day
      var datedisplay = day + ' ' + thisMonth + ' '
      var d = new Date(dateString);
      var dayName = dayNames[d.getDay()];
      if (date.getDate() === day) {
        var myobj = { dayName: dayName, date: dateString, datedisplay: datedisplay, yearDisplay: year, status: 'true' }
      } else {
        var myobj = { dayName: dayName, date: dateString, datedisplay: datedisplay, yearDisplay: year, status: 'false' }
      }
      this.dates.push(myobj)
    }
  }

  signupForm() {
    this.registerError = true;
    const registerData = this.registerForm.value;
    if (this.registerForm.invalid) {
      return;
    }
    const registerSaveData = { countryCode: registerData.countryCode, mobileNumber: registerData.mobileNumber, email: registerData.email, firstName: registerData.firstname, lastName: registerData.lastname }
    this.bookingService.register(registerSaveData).subscribe(
      res => {
        const data = res.body['data']
        if (res.body['error'] === 'true') {
          Swal({
            title: 'Error',
            text: res.body['message'],
            type: 'error',
            confirmButtonText: 'Ok',
          });
        } else {
          Swal({
            title: 'Success',
            text: res.body['message'],
            type: 'success',
            confirmButtonText: 'Ok',
          });
          Cookie.set('token', res.body['data'].token);
          Cookie.set('image', res.body['data'].image);
          Cookie.set('userName', res.body['data'].userName);
          jQuery("#exampleModalCenter").modal("hide");
        }
      });
  }
  /* OTP  */
  otpSubmit() {
    this.otpError = true;
    this.spinner.show();
    // stop here if form is invalid
    if (this.loginForm.invalid) {
      return;
    }
    // if (this.adminData.type == 'socialLogin') {
    //   var data = { mobileNumber: this.registerForm.value.mobileNumber, countryCode: this.registerForm.value.countryCode, OTP: this.otpNumber }
    //   this.verifyotpService.verifySocialotp(data).subscribe(
    //     res => {
    //       if (res.body['error'] === 'true') {
    //         this.spinner.hide();
    //         Swal({
    //           title: 'Error',
    //           text: res.body['message'],
    //           type: 'error',
    //           confirmButtonText: 'Ok',
    //         });
    //       } else {
    //         console.log("**********inside verify otp component")
    //         console.log("adminData", this.adminData)
    //         var data = { email: this.adminData.email, socialToken: this.adminData.token, mobileNumber: this.adminData.mobileNumber, countryCode: this.adminData.countryCode, firstName: this.adminData.firstName, lastName: this.adminData.lastName, socialType: this.adminData.socialType }
    //         this.verifyotpService.socialSignupLogin(data).subscribe(
    //           res => {
    //             const data = res.body['data']
    //             console.log("data", data)
    //             if (res.body['error'] === 'true') {
    //               this.spinner.hide();
    //               Swal({
    //                 title: 'Error',
    //                 text: res.body['message'],
    //                 type: 'error',
    //                 confirmButtonText: 'Ok',
    //               });
    //             } else {
    //               window.location.href = 'https://app.docnowonline.com/docnow-enterprise/signupsuccess/index-1.html';
    //               gtag('event', 'conversion', { 'send_to': 'AW-623317282/5hbyCJi35O8BEKKinKkC' });
    //               Cookie.set('token', res.body['data'].token);
    //               Cookie.set('image', res.body['data'].image);
    //               Cookie.set('userName', res.body['data'].userName);
    //               Cookie.set('id', res.body['data'].id);
    //               this.spinner.hide();
    //               this.router.navigate(['/user/profilesettings'], { state: data });
    //             }
    //           });
    //       }
    //     });

    // } else {
    var data = { mobileNumber: this.loginForm.value.mobileNumber, countryCode: this.loginForm.value.countryCode, OTP: this.otpNumber }

    this.verifyotpService.login(data).subscribe(
      res => {
        this.spinner.hide();
        const data = res.body['data']
        if (res.body['error'] === 'true') {
          Swal({
            title: 'Error',
            text: res.body['message'],
            type: 'error',
            confirmButtonText: 'Ok',
          });
        } else {
          Cookie.set('token', res.body['data'].token);
          Cookie.set('image', res.body['data'].image);
          Cookie.set('userName', res.body['data'].userName);
          this.spinner.hide();
          if (this.adminData.isNewUser == 1) {
            this.router.navigate(['/updateprofile'], { state: data });
          }
          else {
            jQuery("#exampleModalCenter").modal("hide");
          }
        }
      });

    // }

  }
  onOtpChange(data) {
    this.otpNumber = data
  }
  resendotp() {
    var logindata = { mobileNumber: this.loginForm.value.mobileNumber, countryCode: this.loginForm.value.countryCode }
    this.verifyotpService.verifyotp(logindata).subscribe(
      res => {
        this.spinner.hide();
        const data = res.body['data']
        if (res.body['error'] === 'true') {
          Swal({
            title: 'Error',
            text: res.body['message'],
            type: 'error',
            confirmButtonText: 'Ok',
          });
        } else {
          this.otpNumber = ''
          Swal({
            title: 'Success',
            text: "OTP Resent Successfully",
            type: 'success',
            confirmButtonText: 'Ok',
          });
        }
      });
  }
}

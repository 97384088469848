import { Component, OnInit } from '@angular/core';
import { Observable, OperatorFunction } from 'rxjs';
import { Cookie } from 'ng2-cookies/ng2-cookies';
import Swal from 'sweetalert2';
import { NewBillingService } from './new-billing.service';
import { FormBuilder, FormGroup, Validators, FormControl } from '@angular/forms';

@Component({
	selector: 'app-new-billing',
	templateUrl: './new-billing.component.html',
	styleUrls: ['./new-billing.component.css']
})
export class NewBillingComponent implements OnInit {

	doctorForm: any;
	categoryArr: any;
	constructor(
		private service: NewBillingService,
		private formBuilder: FormBuilder) {
		this.doctorForm = this.formBuilder.group({
			location: ['', Validators.required],
			firstName: ['', Validators.required],
			lastName: ['', Validators.required],
			businessName: [''],
			phone: ['', Validators.required],
			email: ['', Validators.required],
			specialty: ['', Validators.required],
			recaptcha: ['', Validators.required]
		})
	}

	ngOnInit() {
		this.service.searchDefaultData().subscribe(async res => {
			var cat = res.body['data'].categories
			this.categoryArr = cat
		})
	}

	submit() {
		// if (this.doctorForm.dirt) {
		var request = {
			location: this.doctorForm.value.location,
			name: "",
			phone: this.doctorForm.value.phone,
			email: this.doctorForm.value.email,
			businessName: this.doctorForm.value.businessName,
			providerName: this.doctorForm.value.firstName + " " + this.doctorForm.value.lastName,
			emr: "",
			insurance: "",
			specialty: this.doctorForm.value.specialty,
			hospitalAffiliation: "",
			recaptcha: this.doctorForm.value.recaptcha,
			from: "DocNow + Billing"
		}
		console.log("data", request)
		this.service.requestData(request).subscribe(async res => {
			console.log("res", res)
			if (res["body"]["error"] == "false") {
				Swal({
					title: 'Thank you!',
					text: 'We have received your request and one of our representatives will be in touch within the next 24-48 hours. In the meantime if you have any questions please submit them to sales@docnowonline.com',
					type: 'success',
					showConfirmButton: true,
					confirmButtonColor: '#3085d6',
					cancelButtonColor: '#d33',
					confirmButtonText: 'Okay',
					allowOutsideClick: false
				}).then(async (result) => {
					if (result.value) {
						window.location.reload()
					}
				})
			} else {
				Swal('Oops...', res["body"]["message"], 'error')
			}
		})
		// } else {
		// 	Swal('Oops...', 'Please Enter Valid Details', 'error')
		// }
	}

	resolved(captchaResponse: string, res) {
		console.log(`Resolved response token: ${captchaResponse}`);
		this.doctorForm.patchValue({ recaptcha: captchaResponse })
	}

}

import { Component, OnInit } from '@angular/core';
import { MapsAPILoader, MouseEvent, AgmMap, LatLngBounds, LatLngBoundsLiteral } from '@agm/core';
import { ListhospitalService } from './listhospital.service';


declare var google: any;


@Component({
  selector: 'app-listhospital',
  templateUrl: './listhospital.component.html',
  styleUrls: ['./listhospital.component.css'],
})
export class ListhospitalComponent implements OnInit {
  public lat: any;
  public lng: any;
  public zoom: number;
  markers: any;
  bound: any;

  public openedWindow: number;

   agmMap: AgmMap;


  constructor(
    private mapApiLoader: MapsAPILoader,
    private listhospitalService: ListhospitalService
  ) { }

  ngOnInit() {
    this.lat = this.listhospitalService.lat;
    this.lng = this.listhospitalService.lng;
    this.zoom = this.listhospitalService.zoom;

    this.setCurrentPosition();
    this.mapApiLoader.load();

    // Zoom to new location after search
    this.listhospitalService.newCoordinators.subscribe(
      (coords: { lat: number, lng: number, zoom: number }) => {
        if (coords) {
          this.lat = coords.lat;
          this.lng = coords.lng;
          this.zoom = coords.zoom;
          this.mapApiLoader.load();
        }
      }
    );
  }
  ngAfterViewInit() {
    // console.log(this.agmMap);
    this.agmMap.mapReady.subscribe(map => {
      const bounds: LatLngBounds = new google.maps.LatLngBounds();
      for (const mm of this.markers) {
        bounds.extend(new google.maps.LatLng(mm.latitude, mm.longitude));
      }
      // console.log(bounds);
      // map.fitBounds(bounds);
      this.bound = bounds;
    });
  }

  mapClicked($event: MouseEvent) {
    // console.log($event);
  }

  clickedMarker(label: string, index: number) {
    console.log(`Clicked the marker: ${label || index}`);
    this.openedWindow = index;
  }

  private setCurrentPosition() {
    if ('geolocation' in navigator) {
      navigator.geolocation.getCurrentPosition((position) => {
        this.lat = this.listhospitalService.lat = position.coords.latitude;
        this.lng = this.listhospitalService.lng = position.coords.longitude;
        this.zoom = 10;
      });
    }

  }
  isInfoWindowOpen(index: number) {
    return this.openedWindow === index;
  }


}

import { Injectable } from '@angular/core';
import { environment } from '../../../environments/environment';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Cookie } from 'ng2-cookies/ng2-cookies';

@Injectable({
  providedIn: 'root'
})

export class HeaderService {

  baseUrl = environment.baseUrl;
  accessToken = Cookie.get('token');

  constructor(private http: HttpClient) { }

  logout() {

    const httpHeaders = new HttpHeaders({
      'authorization': this.accessToken,
      'role': 'user',
      'Content-Type': 'application/json'
    });

    return this.http.get(this.baseUrl + '/user/logout', {

      headers: httpHeaders,
      observe: 'response'
    });
  }

}

import { Injectable } from '@angular/core';
import { environment } from '../../environments/environment';
import { HttpClient, HttpHeaders } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})

export class SocialsignupService {

  baseUrl = environment.baseUrl;

  constructor(private http: HttpClient) { }

  sendOtp(apiData) {
    const httpHeaders = new HttpHeaders({
      'Content-Type': 'application/json'
    });

    return this.http.post(this.baseUrl + '/user/getSocialOTP', apiData, {

      headers: httpHeaders,
      observe: 'response'
    });
  }
}




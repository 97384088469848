import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-audiocall',
  templateUrl: './audiocall.component.html',
  styleUrls: ['./audiocall.component.css']
})
export class AudiocallComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}

import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { DoctorProfileService } from './doctorprofile.service'
import { NgxSpinnerService } from "ngx-spinner";
import { MouseEvent } from '@agm/core';
import Swal from 'sweetalert2';
import { FavouritesService } from '../favourites/favourites.service';
import { Cookie } from 'ng2-cookies/ng2-cookies';
import { environment } from '../../environments/environment';
import { SeoService } from '../layouts/seo.service';

@Component({
  selector: 'app-doctorprofile',
  templateUrl: './doctorprofile.component.html',
  styleUrls: ['./doctorprofile.component.css']
})
export class DoctorprofileComponent implements OnInit {
  doctorId = Cookie.get('doctorId');
  // doctorId: Number;
  doctorProfileDetails: any;
  doctorReviewDetails: any;
  doctorReviewStasticsDetails: any;
  doctorAvailableMorningTimeslots: any;
  doctorAvailableEveningTimeslots: any;
  getDoctorsSuggesionDetails: any;
  averageRating: any;
  newVariable: any;
  whatsappText: any;
  website: any;
  adminData: any;
  isVIP: String;
  email: String;
  doctorAddress: String;
  doctorsName: String;
  description: String;
  doctorsImage: String;
  education: String;
  docnowStream: String;
  mostHelpful: any = 1;
  recent: any = 2;
  critical: any = 3;
  experience: any;
  speciality: any;
  officeNumber: any;
  geoLocation$: any;
  isDoctor: any = 1;
  isTelehealth: any = 0;
  doctorData: any;
  doctorsList: any;
  hospitalsList: any;
  isChatEnabled: any;
  doctorVerifiedStatus: any;
  doctorProfileLink: any;
  showmorereview: boolean = false;

  private started = false;
  users: Array<any> = [{
    active: false,
  }
  ]

  appURL = environment.appURL;

  // google maps zoom level
  zoom: number = 13;

  // initial center position for the map
  lat: number = 13.0827;
  lng: number = 80.2707;
  children: any = 1;
  hospital: any = 2;
  markers: [];
  id: string;
  setTitleText: string;
  favid: any;
  user: any;
  plus: any;
  descript: any;
  servicesJSON: any;
  accessToken = Cookie.get('token');
  hide: boolean;
  todayDate: any;
  placeId: any;


  constructor(
    private doctorProfileService: DoctorProfileService,
    private favouritesService: FavouritesService,
    private router: Router,
    private route: ActivatedRoute,
    private spinner: NgxSpinnerService,
    private seoObj: SeoService
  ) {
    this.adminData = this.router.getCurrentNavigation().extras.state;
  }
  private loadSeoTags() {
    this.seoObj.setTitle(this.setTitleText)
    this.seoObj.updateDescription(this.description)
    this.seoObj.setAuthor('DocNow')
    this.seoObj.setKeyword('Find a Doctor - Doctor Reviews - Online Doctor Appointments - Doctor Near Me - Great Family Doctor')
    this.seoObj.setOGTitle(this.setTitleText)
    this.seoObj.setOGDescription(this.description)
  }
  ngOnInit() {
    // if (this.route.params['value'].id != undefined || this.adminData != undefined) {
    if (this.route.params['value'].id) {
      // console.log("params", this.route.params['value'].id)
      this.doctorId = this.route.params['value'].id
      // this.doctorProfileLink = this.appURL + this.doctorId
      this.viewDoctorprofile();
    }
    // console.log("adminData", this.adminData)

    this.spinner.show();
    if (this.accessToken != null) {
      this.hide = true
    } else {
      this.hide = false
    }
    if (this.adminData == undefined) {
      this.spinner.hide();
      this.viewDoctorprofile();
      //this.router.navigateByUrl('/search');

    } else {
      this.doctorId = this.adminData.doctorId
      this.viewDoctorprofile();
    }
    // } else {
    //   Swal({
    //     title: 'Error',
    //     text: 'Doctor Profile Not Found !',
    //     type: 'error',
    //   })
    // }

  }

  copyText() {
    var text = this.doctorProfileLink
    this.newVariable = window.navigator;
    this.newVariable.clipboard.writeText(text).then(function() {
      console.log('Async: Copying to clipboard was successful!');
    }, function(err) {
      console.error('Async: Could not copy text: ', err);
    });
  }


  viewDoctorprofile() {
    var current = new Date();
    var day = current.getDate();
    var month = current.getMonth() + 1;
    var year = current.getFullYear();
    var dateString = year + '-' + month + '-' + day
    var data = { doctorsId: this.doctorId, date: dateString }
    this.doctorProfileLink = this.appURL + 'doctorprofile/' + this.doctorId
    Cookie.set("bookingURL", this.doctorProfileLink)
    this.doctorProfileService.viewDoctorProfile(data).subscribe(
      res => {
        console.log(res.body, 'result')
        this.spinner.hide();
        this.doctorProfileDetails = res.body['data'].doctorProfileDetails;
        this.doctorReviewDetails = res.body['data'].doctorReviewDetails;
        this.doctorReviewStasticsDetails = res.body['data'].doctorReviewStasticsDetails;
        this.doctorAvailableMorningTimeslots = res.body['data'].doctorAvailableMorningTimeslots;

        this.doctorAvailableEveningTimeslots = res.body['data'].doctorAvailableEveningTimeslots;
        this.getDoctorsSuggesionDetails = res.body['data'].getDoctorsSuggesionDetails;
        this.placeId = this.doctorProfileDetails[0].placeId
        this.website = this.doctorProfileDetails[0].website
        this.doctorsName = this.doctorProfileDetails[0].doctorsName
        this.docnowStream = this.doctorProfileDetails[0].docnowStream
        this.isChatEnabled = this.doctorProfileDetails[0].isChatEnabled
        this.doctorVerifiedStatus = this.doctorProfileDetails[0].doctorVerifiedStatus
        this.description = this.doctorProfileDetails[0].description
        this.descript = this.doctorProfileDetails[0].longDescription ? this.doctorProfileDetails[0].longDescription : "This provider has not updated their full profile yet"
        this.doctorsImage = this.doctorProfileDetails[0].doctorsImage
        this.doctorAddress = this.doctorProfileDetails[0].doctorAddress
        this.email = this.doctorProfileDetails[0].email
        this.education = this.doctorProfileDetails[0].education
        this.averageRating = this.doctorProfileDetails[0].averageRating ? this.doctorProfileDetails[0].averageRating : "Not Ranked Yet"
        this.experience = this.doctorProfileDetails[0].experience ? this.doctorProfileDetails[0].experience : "This provider has not provided experience data yet"
        this.speciality = this.doctorProfileDetails[0].speciality
        this.lat = this.doctorProfileDetails[0].doctorLat
        this.lng = this.doctorProfileDetails[0].doctorLong
        this.officeNumber = this.doctorProfileDetails[0].officeNumber
        this.favid = this.doctorProfileDetails[0].favStatus
        this.plus = this.doctorProfileDetails[0].plusEnabled
        this.todayDate = this.doctorAvailableMorningTimeslots[0].date;
        this.servicesJSON = JSON.parse(this.doctorProfileDetails[0].servicesJSON)
        console.log(this.todayDate, 'date');
        var location = this.doctorAddress.split(',')
        var city = location[1]
        var specialityName = Cookie.get('specialityName') ? ' ' + Cookie.get('specialityName') + ' | Book Now' : ' | Book Now'
        this.setTitleText = this.doctorsName + ' ' + this.education + ' ' + city + specialityName
        this.loadSeoTags()
        this.whatsappText = "Get in touch with " + this.doctorsName + " from the below link " + this.doctorProfileLink
      },
      err => {
        this.spinner.hide();
        this.router.navigateByUrl('/serverError');
      }
    );


  }

  getUserLocation(options?) {
    if (!this.started) {
      this.started = true;
      navigator.geolocation.getCurrentPosition(
        (position) => {
          this.geoLocation$.next(position);
        },
        (err) => {
          this.geoLocation$.error(err);
        },
        options
      );
    }
  }




  doctorProfile(id, type) {
    if (type == 1) {
      this.doctorData = { doctorId: id }
    }
    else {
      this.doctorData = { doctorId: id, isTelehealth: '1' }
    }
    this.spinner.hide();
    this.router.navigate(['/booking'], { state: this.doctorData });
  }

  filter(id) {
    console.log("filter id", id)
    if (id === 'googleReview') {
      if (this.placeId) {
        this.doctorProfileService.getGoogleReview({ "placeId": this.placeId }).subscribe(res => {
          console.log("google response", res)
          this.doctorReviewDetails = res.body['data'].reviews
        })
      } else {
        Swal({
          title: 'OOPS!',
          text: 'No Google Reviews Found',
          type: 'error',
        })
      }
    } else {
      if (id == "1") {
        this.showmorereview = true
      }
      this.spinner.show();
      const filterData = { doctorsId: this.doctorId, page: '1', filter: id }
      this.doctorProfileService.reviewDetails(filterData).subscribe(
        res => {
          this.spinner.hide();
          this.doctorReviewDetails = res.body['data'].reviews;
          // this.spinner.hide();
          // const data = res.body['data']
          // if (res.body['error'] === 'true') {
          //   Swal({
          //     title: 'Error',
          //     text: res.body['message'],
          //     type: 'error',
          //     confirmButtonText: 'Ok',
          //});
          //}
        }
      )
    }
  }
  favourites(id) {
    this.spinner.show();
    // doctor.active = !doctor.active
    var data = { id: id, type: 'doctor' }
    this.doctorProfileService.favourDetails(data).subscribe(
      res => {
        if (res.body['error'] === 'false') {
          Swal({
            title: 'Success',
            text: 'Successfully added to your medical team',
            type: 'success',
          })
          this.spinner.hide();
          this.viewDoctorprofile();
          if (this.favid == 1) {
            this.favid = 1;
          }
          else {
            this.favid = 0;
          }
        } else {
          this.spinner.hide();

        }

      })
  }
  doctorLocation(id, user) {
    user.active = !user.active
    if (user.active) {
      Swal({
        title: 'Success',
        text: 'Added Successfully',
        type: 'warning',
      })
    } else {
      Swal({
        title: 'Success',
        text: 'Removed successfully from your medical team',
        type: 'warning',
      })
    }
    var data = { id: id, type: 'doctor' }

    this.doctorProfileService.favourDetails(data).subscribe(
      res => {
        this.spinner.hide();
      })
  }

  unfavourite(id) {
    this.spinner.show();
    const doctorData = { id: id, type: 'doctor' }
    this.favouritesService.unfavouriteDoctor(doctorData).subscribe(async res => {
      Swal({
        title: 'Success',
        text: 'UnFavourite Successfully',
        type: 'success',
      })
      this.viewDoctorprofile();
      // this.favouritesService.doctorList().subscribe(async res => {
      //   this.doctorsList = res.body['data'].doctors;
      //   this.hospitalsList = res.body['data'].hospitals;
      //   this.spinner.hide();

      // },
      //   err => {
      //   this.spinner.hide();
      //     this.router.navigateByUrl('/serverError');
      //   }
      // )

    })
  }
}

import { Injectable } from '@angular/core';
import { environment } from '../../environments/environment';
import { HttpClient, HttpHeaders, HttpResponse } from '@angular/common/http';
@Injectable({
  providedIn: 'root'
})

export class HospitallocationsService {

  baseUrl = environment.baseUrl;
  accessToken = localStorage.getItem('accessToken');

  constructor(private http: HttpClient) { }

  getChildrenHospital(data) {
    const httpHeaders = new HttpHeaders({
      'Content-Type': 'application/json'
    });

    return this.http.post(this.baseUrl + '/user/listChildrenHospital', data, {

      headers: httpHeaders,
      observe: 'response'
    });
  }

  listHospital(data) {
    // var data = {
    //   latitude:'13.08268020', longitude:'80.27071840'

    // }
    const httpHeaders = new HttpHeaders({
      'Content-Type': 'application/json'
    });

    return this.http.post(this.baseUrl + '/user/listHospital', data, {

      headers: httpHeaders,
      observe: 'response'
    });
  }
  searchDoctor(data) {
    const httpHeaders = new HttpHeaders({
      'Content-Type': 'application/json'
    });

    return this.http.post(this.baseUrl + '/user/searchHospitals', data, {

      headers: httpHeaders,
      observe: 'response'
    });
  }
  searchHospital(data) {
    const httpHeaders = new HttpHeaders({
      'Content-Type': 'application/json'
    });

    return this.http.post(this.baseUrl + '/user/searchChildrenHospitals', data, {

      headers: httpHeaders,
      observe: 'response'
    });
  }

}
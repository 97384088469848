import { Component, OnInit } from '@angular/core';
declare var jQuery;
@Component({
  selector: 'app-preeroom',
  templateUrl: './preeroom.component.html',
  styleUrls: ['./preeroom.component.css']
})
export class PreeroomComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }
ngAfterViewInit(){
  jQuery(function($) {
    $(document).on('click', '.lightboxgallery-gallery-item', function(event) {
      $("body").css("overflow", "auto");
      event.preventDefault();
      $(this).lightboxgallery({
        showCounter: true,
        showTitle: true,
        showDescription: true
      });
    });
  });
}
}

import { Injectable } from '@angular/core';
import { environment } from '../../environments/environment';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Cookie } from 'ng2-cookies/ng2-cookies';
import { from } from 'rxjs';

@Injectable({
  providedIn: 'root'
})

export class IndexService {

  baseUrl = environment.baseUrl;
  accessToken = Cookie.get('token');
  // accessToken = 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpZCI6Mjk5LCJtb2JpbGVOdW1iZXIiOiI4ODM4OTQyNTEwIiwiaWF0IjoxNTk3NjEzMjU2fQ.AXn_NgKWOwjyHCt9jmQ4UQrNv-mcIaV5L1Je0W6gGbA'

  constructor(private http: HttpClient) { }

  search(apiData) {
    const httpHeaders = new HttpHeaders({
      'role': 'user',
      'Content-Type': 'application/json',
    });
    return this.http.post(this.baseUrl + '/user/searchAllCategory', apiData, {
      headers: httpHeaders,
      observe: 'response'
    });
  }

  searchDefaultData() {
    const httpHeaders = new HttpHeaders({
      'role': 'user',
      'Content-Type': 'application/json',
    });
    return this.http.get(this.baseUrl + '/user/getAllCategory', {
      headers: httpHeaders,
      observe: 'response'
    });
  }
  blogList() {
    const httpHeaders = new HttpHeaders({
      'Content-Type': 'application/json',
      'authorization': this.accessToken,
      'role': 'user',
    });

    return this.http.get(this.baseUrl + '/user/homedashboard', {

      headers: httpHeaders,
      observe: 'response'
    });
  }
  topSpecialityService() {
    const httpHeaders = new HttpHeaders({
      'Content-Type': 'application/json',
      'authorization': this.accessToken,
      'role': 'user',
    });

    return this.http.get(this.baseUrl + '/user/homedashboard', {

      headers: httpHeaders,
      observe: 'response'
    });
  }
  subscriptionData() {
    const httpHeaders = new HttpHeaders({
      'Content-Type': 'application/json',
      'authorization': this.accessToken,
      'role': 'user',
    });

    return this.http.get(this.baseUrl + '/user/homedashboard', {

      headers: httpHeaders,
      observe: 'response'
    });
  }

  logout() {
    sessionStorage.clear();
  }
  UnsubscribePlus(apiData) {
    const httpHeaders = new HttpHeaders({
      'role': 'user',
      'authorization': this.accessToken,
      'Content-Type': 'application/json',
    });
    return this.http.post(this.baseUrl + '/user/unSubscribeDocnowPlusPayment', apiData, {
      headers: httpHeaders,
      observe: 'response'
    });
  }
  UnsubscribePay(apiData) {
    const httpHeaders = new HttpHeaders({
      'role': 'user',
      'authorization': this.accessToken,
      'Content-Type': 'application/json',
    });
    return this.http.post(this.baseUrl + '/user/unSubscribeDocnowDirectPayment', apiData, {
      headers: httpHeaders,
      observe: 'response'
    });
  }
}

import { Component, OnInit } from '@angular/core';
import { LoadJsService } from './load-js.service';
import { SeoService } from '../layouts/seo.service';


@Component({
	selector: 'app-homelayout',
	templateUrl: './homelayout.component.html',
	styleUrls: ['./homelayout.component.css']
})
export class HomelayoutComponent implements OnInit {

	constructor(private jsObj: LoadJsService,
		private seoObj: SeoService
	) { }

	ngOnInit() {
		this.loadScripts()
		this.loadSeoTags()
	}

	private loadSeoTags() {
		this.seoObj.setTitle('DocNow | Find a Doctor - Doctor Reviews - Online Doctor Appointments')
		this.seoObj.setDescription('Find the right doctor, today with DocNow. Read real patient reviews from verified patients and book an appointment with a nearby, doctor. It’s fast and easy in three clicks, and totally free. Thousands of patients use DocNow to find their doctor online. Learn more at docnowonline.com')
		this.seoObj.setAuthor('DocNow')
		this.seoObj.setKeyword('Find a Doctor - Doctor Reviews - Online Doctor Appointments - Doctor Near Me - Great Family Doctor')
		this.seoObj.setOGTitle('DocNow | Find a Doctor - Doctor Reviews - Online Doctor Appointments')
		this.seoObj.setOGDescription('Find the right doctor, today with DocNow. Read real patient reviews from verified patients and book an appointment with a nearby, doctor. It’s fast and easy in three clicks, and totally free. Thousands of patients use DocNow to find their doctor online. Learn more at docnowonline.com')
	}
	private loadScripts() {
		this.jsObj.load('jquery').then(data => {
			this.jsObj.load('popper').then(data => {
				this.jsObj.load('bootstrap').then(data => {
					this.jsObj.load('slick').then(data => {
						this.jsObj.load('script').then(data => {
							this.jsObj.load('moment').then(data => {
								this.jsObj.load('daterangepicker').then(data => {
									this.jsObj.load('resizesensor').then(data => {
										this.jsObj.load('sidebar').then(data => {
											this.jsObj.load('fancybox').then(data => {
												this.jsObj.load('datetimepicker').then(data => {
													this.jsObj.load('select2').then(data => {
													}).catch(error => console.log(error));
												}).catch(error => console.log(error));
											}).catch(error => console.log(error));
										}).catch(error => console.log(error));
									}).catch(error => console.log(error));
								}).catch(error => console.log(error));
							}).catch(error => console.log(error));
						}).catch(error => console.log(error));
					}).catch(error => console.log(error));
				}).catch(error => console.log(error));
			}).catch(error => console.log(error));
		}).catch(error => console.log(error));
	}

}



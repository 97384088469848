import { Component, OnInit } from '@angular/core';
import {SidebarService} from './sidebar.service';
import { NgxSpinnerService } from "ngx-spinner";
import { Cookie } from 'ng2-cookies/ng2-cookies';
import { Router } from '@angular/router';

@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.css']
})
export class SidebarComponent implements OnInit {
  accessToken = Cookie.get('token');
  userName = Cookie.get('userName');
  image = Cookie.get('image');
 
  DOB: any;
  userCity: any;
  userState: any;
  hideProfile: number = 1;

  constructor(
    private sidebarService:SidebarService,
    private spinner: NgxSpinnerService,
    private router:Router,

  ) { }
  ngOnChanges()	{
    if (this.accessToken == undefined) {
      this.hideProfile = 1;
     } else {
      this.hideProfile = 0;
       this.sidebarService.getUser().subscribe(
         async response => {
           const data = response.body['data']
          //  console.log(data,'***')
          //  console.log(data.profileDetails[0].userName,'***')
          //  console.log(data.profileDetails[0].DOB,'***')
           this.image = data[0].image
           console.log(this.image)
           this.userName = data.profileDetails[0].userName
           this.DOB = data.profileDetails[0].DOB
           this.userCity = data.profileDetails[0].userCity
           this.userState = data[0].userState
         })
     }
  }

  ngOnInit() {
    if (this.accessToken == undefined) {
     this.hideProfile = 1;
    } else {
     this.hideProfile = 0;
      this.sidebarService.getUser().subscribe(
        async response => {
          const data = response.body['data']
          // console.log(data,'***')
          // console.log(data.profileDetails[0].userName,'***')
          // console.log(data.profileDetails[0].DOB,'***')
          this.image = data.profileDetails[0].image
          // console.log(this.image)
          this.userName = data.profileDetails[0].userName
          this.DOB = data.profileDetails[0].DOB
          this.userCity = data.profileDetails[0].userCity
          this.userState = data.profileDetails[0].userState
        })
    }


}
logout() {
  this.spinner.show();
  // this.sidebarService.logout().subscribe(
  //   async response => {
  // this.spinner.hide();
  //     const data = response.body['data']
  //     if (response.body['error'] === 'true') {
  //     }
  //     else {
        Cookie.deleteAll('/');
        Cookie.delete('token');
        Cookie.delete('image');
        Cookie.delete('userName');
        Cookie.delete('latitude');
        Cookie.delete('latitude');
        Cookie.delete('doctorId');
        localStorage.clear();
        sessionStorage.clear();
        this.spinner.hide();
        this.router.navigateByUrl('/login');
//       }
//     }
//   );
}
}

import { Component, OnInit } from '@angular/core';
declare var $;

@Component({
  selector: 'app-urgentpage',
  templateUrl: './urgentpage.component.html',
  styleUrls: ['./urgentpage.component.css']
})
export class UrgentpageComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }
  
  data: any = [{
    "parentName": "What is an urgent care center?",
    "childProperties":
      [
        { "propertyName": "An urgent care center is a same day walk-in clinic that provides high quality medical care for patients with minor illnesses and injuries that are not life threatening." }
      ]
  }, {
    "parentName": "Can I use the urgent care center as my primary physician?",
    "childProperties":
      [
        { "propertyName": "Urgent care centers are not designed to be a replacement for your primary physician. They provide patients with a convenient alternative when your primary physician is unavailable for an immediate appointment." }
      ]
  }, {
    "parentName": "Will I be charged if I am unable to be treated at an urgent care center?",
    "childProperties":
      [
        { "propertyName": "Each Urgent Care is operated differently. However, please keep in mind that healthcare advice involves time and effort on part of the healthcare providers. This involvement is not offered at free of cost unless explicitly notified by the Urgent Care facility. Whether you are a self-pay patient or covered by insurance, expect to pay the service fee to your Urgent Care providers. If the medical provider has examined you and determined another form of treatment is necessary even when we are unable to treat the condition, you will be charged for the office visit." }
      ]
  },{
    "parentName": "Is an urgent care the same as an emergency room?",
    "childProperties":
      [
        { "propertyName": "Urgent Cares are used to seek immediate medical advice and care for all non-life-threatening illnesses. For serious medical conditions please visit the hospital emergency room or call 911 for immediate help. Emergency rooms are equipped to treat more serious illnesses and injuries like seizures, excessive bleeding, major trauma, obstetric complications, chest pain, etc." }
      ]
  },
  {
    "parentName": "When should I go to an urgent care center?",
    "childProperties":
      [
        { "propertyName": "Urgent care centers are equipped to treat and manage certain non-life threatening injuries and illnesses, such as sprains, skin rashes, fractures, infections, etc. If your primary care physician is unavailable to treat common illnesses like a cold and the flu, an urgent care center is a good alternative. Life-threatening emergency requires an immediate visit to the hospital emergency room and should not be treated at an urgent care center." }
      ]
  }

];
    ngAfterViewInit(){
      if (window.location.href.indexOf("urgent-page") > -1) {
        $('nav').addClass('addShadow');
      }
    }
    toggleAccordian(event, index) {
      const element = event.target;
      element.classList.toggle("active");
      if (this.data[index].isActive) {
        this.data[index].isActive = false;
      } else {
        this.data[index].isActive = true;
      }
      const panel = element.nextElementSibling;
      if (panel.style.maxHeight) {
        panel.style.maxHeight = null;
      } else {
        panel.style.maxHeight = panel.scrollHeight + "px";
      }
    }
}

import { Component, OnInit } from '@angular/core';
import { NgxSpinnerService } from "ngx-spinner";
import { Cookie } from 'ng2-cookies/ng2-cookies';
import { MouseEvent } from '@agm/core';
import { FavouritesService } from './favourites.service';
import { Router, ActivatedRoute } from '@angular/router';
import Swal from 'sweetalert2';



@Component({
  selector: 'app-favourites',
  templateUrl: './favourites.component.html',
  styleUrls: ['./favourites.component.css']
})
export class FavouritesComponent implements OnInit {
doctorsList: any;
hospitalsList: any;
  doctorId: Number = 1;
    zoom: number = 10;
lat: number = 51.673858;
  lng: number = 7.815982;
  urgentcareList: any;
  status: number;




  constructor(
    private spinner: NgxSpinnerService,
    private favouritesService: FavouritesService,
    private router: Router



  ) { }

  ngOnInit() {
    this.spinner.show();
    this.favouritesService.doctorList().subscribe(async res => {
      this.doctorsList = res.body['data'].doctors;
      this.hospitalsList = res.body['data'].hospitals;
  this.urgentcareList = res.body['data'].urgentCare;

      this.spinner.hide();
      // console.log(this.doctorsList.length)
      // console.log(this.hospitalsList.length)
      // console.log(this.urgentcareList.length)
if(this.doctorsList.length == 0){
this.status = 1
}
      
    },
      err => {
      this.spinner.hide();
        this.router.navigateByUrl('/serverError');
      }
)
}


doctorProfile(id) {
    const doctorData = { doctorId: id }
    // console.log(doctorData)


    this.router.navigate(['/doctorprofile'], { state: doctorData });
  }

doctorBooking(id) {
    const doctorData = { doctorId: id }
    // console.log(doctorData)


    this.router.navigate(['/booking'], { state: doctorData });
    
  }

  unfavourite(id) {
  this.spinner.show();

  // console.log(id)
    const doctorData = { id: id,type: 'doctor' }
    // console.log(doctorData)
    this.favouritesService.unfavouriteDoctor(doctorData).subscribe(async res => {
      if (res.body['error'] === 'false') {
        Swal({
          title: 'Success',
          text: 'Sucessfully removed from Medical Team',
          type: 'success',
        }) 
        this.favouritesService.doctorList().subscribe(async res => {
          this.doctorsList = res.body['data'].doctors;
          this.hospitalsList = res.body['data'].hospitals;
          this.urgentcareList = res.body['data'].urgentCare;
        
          this.spinner.hide();
        
        },
          err => {
          this.spinner.hide();
            this.router.navigateByUrl('/serverError');
          }
        )

      } else {

      }
    
  })
}

 unfavouriteHospoital(id) {
  this.spinner.show();

  // console.log(id)
    const doctorData = { id: id,type: 'hospital' }
    // console.log(doctorData)
    this.favouritesService.unfavouriteDoctor(doctorData).subscribe(async res => {

// console.log(res)
if (res.body['error'] === 'false') {
  Swal({
    title: 'Success',
    text: 'Sucessfully Removed From Medical Team',
    type: 'success',
  }) 
  this.favouritesService.doctorList().subscribe(async res => {
    this.doctorsList = res.body['data'].doctors;
    this.hospitalsList = res.body['data'].hospitals;
    this.urgentcareList = res.body['data'].urgentCare;
  
    this.spinner.hide();
    
  },
    err => {
    this.spinner.hide();
      this.router.navigateByUrl('/serverError');
    }
  )
} else {

}    
  })

    
  }
  mapClicked($event: MouseEvent) {
    // this.markers.push({
    //   lat: $event.coords.lat,
    //   lng: $event.coords.lng,
    //   draggable: true
    // });
  }
  
  
  markerDragEnd(m: marker, $event: MouseEvent) {
    console.log('dragEnd', m, $event);
  }
 
 
  
  // markers: marker[] = [
  //   {
  //    lat: 51.673858,
  //    lng: 7.815982,
  //    label: 'A',
  //    draggable: true
  //   },
  //   {
  //    lat: 51.373858,
  //    lng: 7.215982,
  //    label: 'B',
  //    draggable: false
  //   },
  //   {
  //    lat: 51.723858,
  //    lng: 7.895982,
  //    label: 'C',
  //    draggable: true
  //   }
  // ]
  
}

// just an interface for type safety.
interface marker {
  lat: number;
  lng: number;
  label?: string;
  draggable: boolean;

}



import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
interface Scripts {
	name: string;
	src: string;
}

var path = environment.scriptBasePath

export const ScriptStore: Scripts[] = [
	{ name: 'jquery', src: path + 'assets/js/jquery.min.js' },
	{ name: 'popper', src: path + 'assets/js/popper.min.js' },
	{ name: 'bootstrap', src: path + 'assets/js/bootstrap.min.js' },
	{ name: 'slick', src: path + 'assets/js/slick.js' },
	{ name: 'script', src: path + 'assets/js/script.js' },
	{ name: 'moment', src: path + 'assets/js/moment.min.js' },
	{ name: 'daterangepicker', src: path + 'assets/plugins/daterangepicker/daterangepicker.js' },
	{ name: 'resizesensor', src: path + 'assets/plugins/theia-sticky-sidebar/ResizeSensor.js' },
	{ name: 'sidebar', src: path + 'assets/plugins/theia-sticky-sidebar/theia-sticky-sidebar.js' },
	{ name: 'fancybox', src: path + 'assets/plugins/fancybox/jquery.fancybox.min.js' },
	{ name: 'datetimepicker', src: path + 'assets/js/bootstrap-datetimepicker.min.js' },
	{ name: 'select2', src: path + 'assets/plugins/select2/js/select2.min.js' },
];

declare var document: any;

@Injectable({
	providedIn: 'root'
})

export class LoadJsService {
	private scripts: any = {};

	constructor() {
		ScriptStore.forEach((script: any) => {
			this.scripts[script.name] = {
				loaded: false,
				src: script.src
			};
		});
	}

	load(...scripts: string[]) {
		const promises: any[] = [];
		scripts.forEach((script) => promises.push(this.loadScript(script)));
		return Promise.all(promises);
	}

	loadScript(name: string) {
		return new Promise((resolve, reject) => {
			if (!this.scripts[name].loaded) {
				// console.log("if of first if")
				//load script
				let script = document.createElement('script');
				script.type = 'text/javascript';
				script.src = this.scripts[name].src;
				script.async = true;
				script.charset = 'utf-8';
				if (script.readyState) {  //IE
					// console.log("second of first if")
					script.onreadystatechange = () => {
						if (script.readyState === "loaded" || script.readyState === "complete") {
							script.onreadystatechange = null;
							this.scripts[name].loaded = false;
							resolve({ script: name, loaded: true, status: 'Loaded' });
						}
					};
				} else {  //Others
					// console.log("else of second if")
					script.onload = () => {
						this.scripts[name].loaded = false;
						resolve({ script: name, loaded: true, status: 'Loaded' });
					};
				}
				script.onerror = (error: any) => resolve({ script: name, loaded: false, status: 'Loaded' });
				document.getElementsByTagName('head')[0].appendChild(script);
			} else {
				// console.log("else of first if")
				resolve({ script: name, loaded: true, status: 'Already Loaded' });
			}
		});
	}

}
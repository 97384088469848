import { Component, OnInit } from '@angular/core';
import { LoadJsService } from './load-js.service';
import { SeoService } from '../layouts/seo.service';
import { from } from 'rxjs';

@Component({
	selector: 'app-layouts',
	templateUrl: './layouts.component.html',
	styleUrls: ['./layouts.component.css']
})
export class LayoutsComponent implements OnInit {

	constructor(private jsObj: LoadJsService,
		private seoObj: SeoService
	) {
		// this.loadScripts();
	}

	private loadSeoTags() {
		this.seoObj.setTitle('DocNow | Find a Doctor - Doctor Reviews - Online Doctor Appointments')
		this.seoObj.setDescription('Find the right doctor, today with DocNow. Read real patient reviews from verified patients and book an appointment with a nearby, doctor. It’s fast and easy in three clicks, and totally free. Thousands of patients use DocNow to find their doctor online. Learn more at docnowonline.com')
		this.seoObj.setAuthor('DocNow')
		this.seoObj.setKeyword('Find a Doctor - Doctor Reviews - Online Doctor Appointments - Doctor Near Me - Great Family Doctor')
		this.seoObj.setOGTitle('DocNow | Find a Doctor - Doctor Reviews - Online Doctor Appointments')
		this.seoObj.setOGDescription('Find the right doctor, today with DocNow. Read real patient reviews from verified patients and book an appointment with a nearby, doctor. It’s fast and easy in three clicks, and totally free. Thousands of patients use DocNow to find their doctor online. Learn more at docnowonline.com')
	}

	ngOnInit() {
		this.loadSeoTags()
		// this.loadScripts();
	}


	// loadScripts() {
	// 	const dynamicScripts = [
	// 		'../../assets/js/jquery.min.js',
	// 		'../../assets/js/popper.min.js',
	// 		'../../assets/js/bootstrap.min.js',
	// 		'../../assets/js/slick.js',
	// 		'../../assets/js/script.js',
	// 		'../../assets/js/moment.min.js',
	// 		'../../assets/plugins/daterangepicker/daterangepicker.js',
	// 		'../../assets/plugins/theia-sticky-sidebar/ResizeSensor.js',
	// 		'../../assets/plugins/theia-sticky-sidebar/theia-sticky-sidebar.js',
	// 		'../../assets/plugins/fancybox/jquery.fancybox.min.js',
	// 		'../../assets/js/bootstrap-datetimepicker.min.js',
	// 		'../../assets/plugins/select2/js/select2.min.js',
	// 	];
	// 	for (let i = 0; i < dynamicScripts.length; i++) {
	// 		const node = document.createElement('script');
	// 		node.src = dynamicScripts[i];
	// 		node.type = 'text/javascript';
	// 		node.async = true;
	// 		node.charset = 'utf-8';
	// 		document.getElementsByTagName('head')[0].appendChild(node);
	// 	}
	// }

	private loadScripts() {
		this.jsObj.load('jquery').then(async data => {
			this.jsObj.load('script').then(async data => {
				this.jsObj.load('popper').then(async data => {
					this.jsObj.load('bootstrap').then(async data => {
						this.jsObj.load('slick').then(async data => {
							this.jsObj.load('moment').then(async data => {
								this.jsObj.load('daterangepicker').then(async data => {
									this.jsObj.load('resizesensor').then(async data => {
										this.jsObj.load('sidebar').then(async data => {
											this.jsObj.load('fancybox').then(async data => {
												this.jsObj.load('datetimepicker').then(async data => {
													this.jsObj.load('select2').then(async data => {
													}).catch(error => console.log(error));
												}).catch(error => console.log(error));
											}).catch(error => console.log(error));
										}).catch(error => console.log(error));
									}).catch(error => console.log(error));
								}).catch(error => console.log(error));
							}).catch(error => console.log(error));
						}).catch(error => console.log(error));
					}).catch(error => console.log(error));
				}).catch(error => console.log(error));
			}).catch(error => console.log(error));
		}).catch(error => console.log(error));
	}

}

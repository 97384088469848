import { Injectable } from '@angular/core';
import { environment } from '../../environments/environment';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Cookie } from 'ng2-cookies/ng2-cookies';
import { from } from 'rxjs';

@Injectable({
  providedIn: 'root'
})

export class BloglistService {

  baseUrl = environment.baseUrl;

  constructor(private http: HttpClient) { }

  blogList(data) {
    const httpHeaders = new HttpHeaders({
      'Content-Type': 'application/json'
    });

    return this.http.post(this.baseUrl + '/user/listAllBlog', data, {

      headers: httpHeaders,
      observe: 'response'
    });
  }
  latestblogList() {
    const httpHeaders = new HttpHeaders({
      'Content-Type': 'application/json'
    });

    return this.http.get(this.baseUrl + '/user/listLatestBlog', {

      headers: httpHeaders,
      observe: 'response'
    });
  }

  searchBlog(data) {
    // console.log(data,'data')
    const httpHeaders = new HttpHeaders({
      'Content-Type': 'application/json'
    });

    return this.http.post(this.baseUrl + '/user/searchBlog', data, {

      headers: httpHeaders,
      observe: 'response'
    });
  }
}

import { Component, OnInit, ViewChild, ElementRef, NgZone } from '@angular/core';
import { MouseEvent, MapsAPILoader } from '@agm/core';
import { HospitallocationsService } from '../hospitallocation/hospitallocation.service';
import { Router, ActivatedRoute } from '@angular/router';
import { NgxSpinnerService } from "ngx-spinner";
import { DoctorProfileService } from '././../doctorprofile/doctorprofile.service'
import { Cookie } from 'ng2-cookies/ng2-cookies';
import { FormBuilder, FormGroup, Validators, FormControl } from '@angular/forms';
import Swal from 'sweetalert2';
@Component({
  selector: 'app-children-hospital-location',
  templateUrl: './children-hospital-location.component.html',
  styleUrls: ['./children-hospital-location.component.css']
})
export class ChildrenHospitalLocationComponent {
  // google maps zoom level
  zoom: number = 8;
  @ViewChild('search')
  public searchElementRef: ElementRef;
  location: string;
  searchForm: any;
  geoCoder: any;
  errormessage: String;
  address: string;
  // initial center position for the map
  lat: number;
  lng: number;
  isSingle: any = 1;
  children: any = 1;
  hospital: any = 2;
  markers: [];
  users: Array<any> = [{
    active: false,
  }
  ]
  hospitalImage: any;
  hospitalName: any;
  education: any;
  mobileNumber: any;
  hospitalAddress: any;
  doctor: any;
  keyword = 'hospitalName';
  data: any;
  data1: any;
  data2: any;
  errorMsg: string;
  isLoadingResult: boolean;
  item: any;
  hospitalLat: any;
  hospitalLong: any;
  id: any;
  adminData: { [k: string]: any; };
  latitude: any;
  longitude: any;
  cardhide: any;
  hide: boolean;
  addtomymedicalteamstatus: boolean = false;




  constructor(
    private hospitallocationsService: HospitallocationsService,
    private router: Router,
    private spinner: NgxSpinnerService,
    private doctorProfileService: DoctorProfileService,
    private formBuilder: FormBuilder,
    private mapsAPILoader: MapsAPILoader,
    private ngZone: NgZone
  ) {
    this.adminData = this.router.getCurrentNavigation().extras.state;
    console.log(this.adminData)
    this.latitude = Cookie.get('latitude');
    this.longitude = Cookie.get('longitude');
    this.lat = parseFloat(this.latitude)
    this.lng = parseFloat(this.longitude)
    this.searchForm = this.formBuilder.group({
      location: ['', Validators.required]
    })
  }
  ngOnInit() {
    var accessToken = Cookie.get('token');
    if (accessToken == undefined) {
      this.addtomymedicalteamstatus = true
    }
    this.spinner.show();
    if (this.adminData == undefined) {
      this.latitude = '13.08268020'
      this.longitude = '80.27071840'
    } else {
      this.latitude = this.adminData.latitude;
      this.longitude = this.adminData.longitude;
    }
    const filter = { latitude: this.latitude, longitude: this.longitude }
    this.hospitallocationsService.getChildrenHospital(filter).subscribe(async res => {
      this.spinner.hide();
      if (res.body['data'].hospital != '') {
        this.hide = false
        this.markers = res.body['data'].hospital
        this.hospitalImage = res.body['data'].hospital[0].hospitalImage
        this.hospitalName = res.body['data'].hospital[0].hospitalName
        this.education = res.body['data'].hospital[0].education
        this.mobileNumber = res.body['data'].hospital[0].mobileNumber
        this.hospitalAddress = res.body['data'].hospital[0].hospitalAddress

      } else {
        this.spinner.show();
        var filter = { latitude: this.latitude, longitude: this.longitude }

        this.hospitallocationsService.listHospital(filter).subscribe(async response => {
          this.spinner.hide();
          if (response.body['data'].hospital != '') {
            this.hide = false
          }
          else {
            this.hide = true
          }
        });
      }


    });

    // Map Loader
    this.mapsAPILoader.load().then(() => {
      this.setCurrentLocation();
      this.geoCoder = new google.maps.Geocoder;

      let autocomplete = new google.maps.places.Autocomplete(this.searchElementRef.nativeElement);
      autocomplete.addListener("place_changed", () => {
        this.ngZone.run(() => {
          //get the place result
          let place: google.maps.places.PlaceResult = autocomplete.getPlace();
          //set latitude, longitude and zoom
          this.latitude = place.geometry.location.lat();
          this.longitude = place.geometry.location.lng();
          Cookie.set('latitude', this.latitude)
          Cookie.set('longitude', this.longitude);
          this.errormessage = ""
          this.zoom = 4;
        });
      });
    });
    // End Map Loader
  }
  private setCurrentLocation() {
    if ('geolocation' in navigator) {
      navigator.geolocation.getCurrentPosition((position) => {
        // console.log(position)
        this.latitude = position.coords.latitude;
        this.longitude = position.coords.longitude;
        Cookie.set('latitude', this.latitude)
        Cookie.set('longitude', this.longitude);
        this.zoom = 8;
        this.getAddress(this.latitude, this.longitude);
      });
    }
  }
  markerDragEnd($event: MouseEvent) {
    this.latitude = $event.coords.lat;
    this.longitude = $event.coords.lng;
    this.getAddress(this.latitude, this.longitude);
  }
  getAddress(latitude, longitude) {
    // console.log(latitude, longitude)
    this.geoCoder.geocode({ 'location': { lat: latitude, lng: longitude } }, (results, status) => {
      if (status === 'OK') {
        // console.log(results)
        if (results[0]) {
          this.zoom = 12;
          this.address = results[0].formatted_address;
          // console.log(this.address)
          this.location = this.address
          this.searchForm = new FormGroup({
            location: new FormControl(this.address)
          })
        } else {
          window.alert('No results found');
        }
      } else {
        window.alert('Geocoder failed due to: ' + status);
      }

    });
  }
  getServerResponse(data) {
    const doctordata = { name: data, latitude: this.latitude, longitude: this.longitude }
    this.isLoadingResult = true;
    this.hospitallocationsService.searchDoctor(doctordata).subscribe(async res => {
      this.hospitallocationsService.searchHospital(doctordata).subscribe(async response => {
        this.data1 = res.body['data'];
        this.data2 = response.body['data'];
        this.data = this.data1.concat(this.data2)
        this.isLoadingResult = false;
      });
      this.isLoadingResult = false;
    });
  }
  searchdoctor() {
    console.log("console", this.latitude, this.longitude)
    Cookie.set('latitude', this.latitude)
    Cookie.set('longitude', this.longitude);
    const filter = { latitude: this.latitude, longitude: this.longitude }
    this.setCurrentLocation();
    this.hospitallocationsService.getChildrenHospital(filter).subscribe(async res => {
      this.spinner.hide();
      if (res.body['data'].hospital != '') {
        this.hide = false
        this.markers = res.body['data'].hospital
        this.hospitalImage = res.body['data'].hospital[0].hospitalImage
        this.hospitalName = res.body['data'].hospital[0].hospitalName
        this.education = res.body['data'].hospital[0].education
        this.mobileNumber = res.body['data'].hospital[0].mobileNumber
        this.hospitalAddress = res.body['data'].hospital[0].hospitalAddress

      } else {
        this.spinner.show();
        var filter = { latitude: this.latitude, longitude: this.longitude }

        this.hospitallocationsService.listHospital(filter).subscribe(async response => {
          this.spinner.hide();
          if (response.body['data'].hospital != '') {
            this.hide = false
          }
          else {
            this.hide = true
          }
        });
      }


    });
  }
  searchCleared() {
    // console.log('searchCleared');
    // this.data = [];
  }
  selectEvent(item) {
    this.item = item
    this.isSingle = 0
    this.hospitalImage = item.hospitalImage
    this.hospitalName = item.hospitalName
    this.education = item.education
    this.mobileNumber = item.mobileNumber
    this.hospitalAddress = item.hospitalAddress
    this.hospitalLat = item.hospitalLat
    this.hospitalLong = item.hospitalLong
    this.id = item.id



  }
  onChangeSearch(val: string) {
    // fetch remote data from here
    // And reassign the 'data' which is binded to 'data' property.
  }
  onFocused(e) {
    // console.log(e)
    // do something when input is focused
  }
  listHospital(data) {
    this.isSingle = 1
    if (data == 1) {
      this.spinner.show();
      const filter = { latitude: this.latitude, longitude: this.longitude }

      this.hospitallocationsService.getChildrenHospital(filter).subscribe(async res => {
        this.spinner.hide();
        // console.log(res)
        this.markers = res.body['data'].hospital
      });
    } else {
      this.spinner.show();
      var filter = { latitude: this.latitude, longitude: this.longitude }

      this.hospitallocationsService.listHospital(filter).subscribe(async res => {
        this.spinner.hide();
        this.markers = res.body['data'].hospital
      });
    }
  }
  hospitalFavourite(id, user) {
    user.active = !user.active

    var data = { id: id, type: 'hospital' }
    this.doctorProfileService.favourDetails(data).subscribe(
      res => {
        // console.log(res)
        this.spinner.hide();

        if (user.active) {
          Swal({
            title: 'Success',
            text: 'Added Sucessfully',
            type: 'warning',
          })
        } else {
          Swal({
            title: 'Success',
            text: 'Removed Sucessfully',
            type: 'warning',
          })

        }

      }
    )
  }
  clickedMarker(label, index: number) {
    console.log(`clicked the marker: ${label || index}`)
    // console.log(label);
    this.hospitalImage = label.hospitalImage
    this.hospitalName = label.hospitalName
    this.education = label.education
    this.mobileNumber = label.mobileNumber
    this.hospitalAddress = label.hospitalAddress
  }

  mapClicked($event: MouseEvent) {
    // this.markers.push({
    //   lat: $event.coords.lat,
    //   lng: $event.coords.lng,
    //   draggable: true
    // });
  }





  // markers: marker[] = [
  //   {
  //    lat: 51.673858,
  //    lng: 7.815982,
  //    label: 'A',
  //    draggable: true
  //   },
  //   {
  //    lat: 51.373858,
  //    lng: 7.215982,
  //    label: 'B',
  //    draggable: false
  //   },
  //   {
  //    lat: 51.723858,
  //    lng: 7.895982,
  //    label: 'C',
  //    draggable: true
  //   }
  // ]

}
// just an interface for type safety.
interface marker {
  lat: number;
  lng: number;
  label?: string;
  draggable: boolean;
}


import { Injectable } from '@angular/core';
import { environment } from '../../environments/environment';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Cookie } from 'ng2-cookies/ng2-cookies';

@Injectable({
  providedIn: 'root'
})

export class FamilymemberService {

  baseUrl = environment.baseUrl;
  accessToken = Cookie.get('token');
  constructor(private http: HttpClient) { }


  updateProfile(apiData) {
      // console.log(apiData)
    const httpHeaders = new HttpHeaders({
      'Content-Type': 'application/json',
      'authorization': this.accessToken,
      'role': 'user'
    });

    return this.http.post(this.baseUrl + '/user/addMyFamilyMembers', apiData, {

      headers: httpHeaders,
      observe: 'response'
    });
  }
}
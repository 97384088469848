import { Component, OnInit } from '@angular/core';
import { DirectdoctorService } from './directdoctor.service';
import { Router, ActivatedRoute } from '@angular/router';
import { NgxSpinnerService } from "ngx-spinner";

@Component({
  selector: 'app-directdoctor',
  templateUrl: './directdoctor.component.html',
  styleUrls: ['./directdoctor.component.css']
})
export class DirectdoctorComponent implements OnInit {
  page = 1;
  pages: any;
  categoryId: Number = 1;
  doctorId: Number = 1;
  doctorList: any;
  categoriesList: any;
  adminData: any;
  doctorVerifiedStatus: any;
  filterhide: any;
  latitude: any;
  longitude: any;
  // categories1: any =[
  //        {
  //           "categoryId":1,
  //           "categoryName":"Urgent Care",
  //           "categoryImage":"https://app.docnowmd.com/uploads/admin/1598335835713.png",
  //           "count":0
  //        },
  //        {
  //           "categoryId":2,
  //           "categoryName":"children Urgent Care",
  //           "categoryImage":"https://app.docnowmd.com/uploads/admin/1598335889427.png",
  //           "count":0
  //        },
  //        ]

  constructor(private directDoctorService: DirectdoctorService,
    private router: Router,
    private spinner: NgxSpinnerService
  ) {
    this.adminData = this.router.getCurrentNavigation().extras.state;
  }

  ngOnInit() {
    this.spinner.show();
    if (this.adminData == undefined) {
      this.spinner.hide();
      this.filterUrgentDoctor();
    } else {
      if (this.adminData.isUrgentCare == 1) {
        //this.filterhide='hide';
        this.filterUrgentDoctor();
      } else {
        this.categoryId = this.adminData.categoryId;
        this.latitude = this.adminData.latitude;
        this.longitude = this.adminData.longitude;
        //this.filterhide='unHide';
        this.filterUrgentDoctor();
      }
    }

    this.directDoctorService.categoryList().subscribe(
      res => {
        this.spinner.hide();
        this.categoriesList = res.body['data'].categories;
      },
      err => {
        this.spinner.hide();
        this.router.navigateByUrl('/serverError');
      }
    );

  }
  filterUrgentDoctor() {
    var filter = { categoryId: this.categoryId, page: this.page, latitude: this.latitude, longitude: this.longitude, filter: JSON.stringify({ "normalDoctors": 0, "teleHealth": 0 }) }
    this.directDoctorService.directDoctorList(filter).subscribe(
      response => {
        this.spinner.hide();
        this.doctorList = response.body['data'].doctors;
      },
      err => {
        this.spinner.hide();
        this.router.navigateByUrl('/serverError');
      }
    );
  }

  filterDoctor() {
    var filter = { categoryId: JSON.stringify(this.categoryId), page: this.page, latitude: this.latitude, longitude: this.longitude, filter: JSON.stringify({ "normalDoctors": 0, "teleHealth": 0 }) }
    this.directDoctorService.Directdoctor(filter).subscribe(
      response => {
        this.spinner.hide();
        this.doctorList = response.body['data'].doctors;
        // var categoryArr = this.categoriesList
        // categoryArr.forEach(function(data, index) {
        //   if (this.categoryId === data.categoryId) {
        //     data.status = true
        //   } else {
        //     data.status = false
        //   }
        // })
      },
      err => {
        this.spinner.hide();
        this.router.navigateByUrl('/serverError');
      }
    );
  }

  Doctor() {
    var filter = { categoryId: JSON.stringify(this.categoryId), page: this.page, latitude: this.latitude, longitude: this.longitude, filter: JSON.stringify({ "normalDoctors": 0, "teleHealth": 0, latitude: this.latitude, longitude: this.longitude }) }
    this.directDoctorService.directDoctorList(filter).subscribe(
      response => {
        this.spinner.hide();
        this.doctorList = response.body['data'].doctors;
      },
      err => {
        this.spinner.hide();
        this.router.navigateByUrl('/serverError');
      }
    );
  }



  searchdoctorList() {
    const categoryData = { categoryId: this.categoryId }

    this.router.navigate(['/doctorprofile'], { state: categoryData });

  }
  doctorBooking(doctorId) {
    const doctorData = { doctorId: doctorId }

    this.router.navigate(['/booking'], { state: doctorData });
  }

  doctorProfile(doctorId) {
    const doctorData = { doctorId: doctorId }
    this.router.navigate(['/doctorprofile'], { state: doctorData });
  }

  //filter
  doctorFilter(event) {
    this.spinner.show();
    //this.filterDoctor();
    this.categoryId = event.categoryId
    var filter = { categoryId: JSON.stringify(this.categoryId), page: this.page, latitude: this.latitude, longitude: this.longitude, filter: JSON.stringify({ "normalDoctors": 0, "teleHealth": 0 }) }
    this.directDoctorService.directDoctorList(filter).subscribe(
      response => {
        this.spinner.hide();
        this.doctorList = response.body['data'].doctors;
        // var categoryArr = this.categoriesList
        // categoryArr.forEach(function(data, index) {
        //   if (this.categoryId === data.categoryId) {
        //     data.status = true
        //   } else {
        //     data.status = false
        //   }
        // })
      },
      err => {
        this.spinner.hide();
        this.router.navigateByUrl('/serverError');
      }
    );
  }
}


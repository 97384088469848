import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { BloglistService } from './blog-list.service';
import { NgxSpinnerService } from "ngx-spinner";


@Component({
  selector: 'app-blog-list',
  templateUrl: './blog-list.component.html',
  styleUrls: ['./blog-list.component.css']
})
export class BlogListComponent implements OnInit {
  blogsList: any;
  latestblogList: any;
  pages: number;
  page = 1;
  name: any;
  list: any;
  keyword = 'title';
  item: any;
  data: any;
  constructor(
    private bloglistService: BloglistService,
    private router: Router,
    private spinner: NgxSpinnerService
  ) { }

  ngOnInit() {
    var data = { page: 1 }
    this.spinner.show();
    this.bloglistService.blogList(data).subscribe(async res => {
      this.blogsList = res.body['data'].hospital;
      console.log(this.blogsList, 'blogsList')
      this.pages = res.body['data'].pageCount * 10;
      this.spinner.hide();
    },
      err => {
        this.router.navigateByUrl('/serverError');
      }
    );

    this.bloglistService.latestblogList().subscribe(async res => {

      this.latestblogList = res.body['data'].hospital;
      console.log(this.latestblogList, 'latestblog')
      this.spinner.hide();
    },
      err => {
        this.router.navigateByUrl('/serverError');
      }
    );

  }
  getBlogs(page) {
    var data = { page: page }

    this.bloglistService.blogList(data).subscribe(async res => {
      this.blogsList = res.body['data'].hospital;
      this.pages = res.body['data'].pageCount * 10;
      this.spinner.hide();
    },
      err => {
        this.router.navigateByUrl('/serverError');
      }
    );

  }
  blogDetails(blogs) {
    var routeLink = '/blogDetails/' + blogs.id + '/' + blogs.title
    this.router.navigate([routeLink], { state: blogs });
    // this.router.navigate(['/blogDetails'], { state: blogs });
  }
  Details(hospital) {
    console.log(hospital)
    var routeLink = '/blogDetails/' + hospital.id + '/' + hospital.title
    this.router.navigate([routeLink], { state: hospital });
    // this.router.navigate(['/blogDetails'], { state: hospital });
  }
  getblogList(data) {

    var requestData = {
      name: data
    }
    this.bloglistService.searchBlog(requestData).subscribe(async response => {

      console.log(response.body, 'response');
      this.list = response.body['data'].blogDetails;


    });
  }
  selectEvent(item) {
    this.item = item
    console.log(this.item, 'item');
    var routeLink = '/blogDetails/' + item.id + '/' + item.title
    this.router.navigate([routeLink], { state: item });
    // this.router.navigate(['/blogDetails'], { state: item });
  }

}


import { Component, OnInit, Output, ViewChild } from '@angular/core';
import { NgbTypeahead } from '@ng-bootstrap/ng-bootstrap';
import { EventEmitter } from 'events';
// import {Observable, Subject, OperatorFunction} from 'rxjs';
// import {debounceTime, distinctUntilChanged, filter, map, merge} from 'rxjs/operators';
import { HomeService } from '../home/home.service';
import { Cookie } from 'ng2-cookies/ng2-cookies';

import { Observable, Subject, merge, OperatorFunction } from 'rxjs';
import { debounceTime, distinctUntilChanged, filter, map, } from 'rxjs/operators';

@Component({
  selector: 'app-location',
  templateUrl: './location.component.html',
  styleUrls: ['./location.component.css']
})
export class LocationComponent implements OnInit {
  name = 'Angular 6';
  @ViewChild('instance') instance: NgbTypeahead;
  focus$ = new Subject<string>();
  click$ = new Subject<string>();


  dropdownList: any;
  list = [];
  public model: any;

  // @Input() message: string;
  // @Output() informParent = new EventEmitter();
  constructor(private homeService: HomeService,) { }

  ngAfterContentInit() {
    this.homeService.searchDefaultData().subscribe(async res => {
      this.dropdownList = res.body['data'].categories
      this.model = Cookie.get('specialityName');

      this.list = this.dropdownList.map(data => data.categoryName)
      // console.log(this.dropdownList)
      // var cat = res.body['data'].categories
      // this.categoryArr = cat
      // this.filteredcategory = this.categoryArr.map(data =>{
      //   return data.categoryName
      // })
      // console.log('category',this.list)

    })
  }
  ngOnInit() {

    this.homeService.searchDefaultData().subscribe(async res => {
      this.dropdownList = res.body['data'].categories

      this.list = this.dropdownList.map(data => data.categoryName)
      // console.log(this.dropdownList)
      // var cat = res.body['data'].categories
      // this.categoryArr = cat
      // this.filteredcategory = this.categoryArr.map(data =>{
      //   return data.categoryName
      // })
      // console.log('category',this.list)

    })


  }


  // search = (text$: Observable<string>) =>
  //   text$.pipe(
  //     debounceTime(200),
  //     distinctUntilChanged(),
  //     merge(this.focus$),
  //     merge(this.click$.pipe(filter(() => !this.instance.isPopupOpen()))),
  //     map( (term) => {
  //       console.log({term});
  //      return  term === '' 
  //       ? ''
  //       : this.dropdownList
  //         .filter(v => v.categoryName.toLowerCase().indexOf(term.toLowerCase()) > -1)
  //         .slice(0, 10)
  //     })
  //     );


  search: OperatorFunction<string, string[]> = (text$: Observable<string>) => {
    const debouncedText$ = text$.pipe(debounceTime(200), distinctUntilChanged());
    const clicksWithClosedPopup$ = this.click$.pipe(filter(() => !this.instance.isPopupOpen()));
    const inputFocus$ = this.focus$;

    return merge(debouncedText$, inputFocus$, clicksWithClosedPopup$).pipe(
      map(term => (term === '' ? this.list
        : this.list.filter(v => v.toLowerCase().indexOf(term.toLowerCase()) > -1)).slice(0, 100))
    );
  }

  formatter = (x: { categoryName: string }) => x.categoryName;

  searchUrl(url) {
    if (url) {
      window.open(url, '_blank');
    }
  }


  onItemSelect(data: any) {
    var filterted = this.dropdownList.filter(res => res.categoryName == data.item)
    // console.log('filtered',filterted[0])
    Cookie.set('categoryId', filterted[0].categoryId);
    Cookie.set('specialityName', filterted[0].categoryName);
  }
}

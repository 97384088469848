import { Injectable } from '@angular/core';
import { environment } from '../../environments/environment';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Cookie } from 'ng2-cookies/ng2-cookies';

@Injectable({
  providedIn: 'root'
})
export class EditFamilyService {

    baseUrl = environment.baseUrl;
    accessToken = Cookie.get('token');
    constructor(private http: HttpClient) { }
  
    familyData() {
      const httpHeaders = new HttpHeaders({
        'Content-Type': 'application/json',
        'authorization': this.accessToken,
        'role': 'user'
      });
  
      return this.http.get(this.baseUrl + '/user/getProfile', {
  
        headers: httpHeaders,
        observe: 'response'
      });
    }
   
      editFamily(data) {
        const httpHeaders = new HttpHeaders({
          'role': 'user',
          'Content-Type': 'application/json',
          'authorization': this.accessToken,
        });
    
        return this.http.post(this.baseUrl + '/user/updateMyFamilyMembers', data, {
    
          headers: httpHeaders,
          observe: 'response'
        });
    
    
      }
    }
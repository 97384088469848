import { Injectable } from '@angular/core';
import { environment } from '../../environments/environment';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Cookie } from 'ng2-cookies/ng2-cookies';



@Injectable({
  providedIn: 'root'
})

export class BookingService {

  baseUrl = environment.baseUrl;
  accessToken = Cookie.get('token');
  constructor(private http: HttpClient) { }

  booking(data) {
    const httpHeaders = new HttpHeaders({
      'Content-Type': 'application/json'
    });

    return this.http.post(this.baseUrl + '/user/listDoctorDetailView', data, {

      headers: httpHeaders,
      observe: 'response'
    });
  }
  newBooking(data) {
    var accessToken = Cookie.get('token');
    const httpHeaders = new HttpHeaders({
      'Content-Type': 'application/json',
      'authorization': accessToken,
      'role': 'user'
    });

    // console.log("data", data, this.baseUrl, httpHeaders, this.accessToken)

    return this.http.post(this.baseUrl + '/user/newBooking', data, {

      headers: httpHeaders,
      observe: 'response'
    });
  }

  // Login api
  login(apiData) {

    const httpHeaders = new HttpHeaders({
      'Content-Type': 'application/json'
    });

    return this.http.post(this.baseUrl + '/user/loginAndRegister', apiData, {

      headers: httpHeaders,
      observe: 'response'
    });
  }

  socialLogin(apiData) {
    const httpHeaders = new HttpHeaders({
      'Content-Type': 'application/json'
    });
    if (apiData.socialType == 'google') {
      return this.http.post(this.baseUrl + '/user/checkGoogleLogin', apiData, {
        headers: httpHeaders,
        observe: 'response'
      });
    }
    if (apiData.socialType == 'facebook') {
      return this.http.post(this.baseUrl + '/user/checkFacebookLogin', apiData, {
        headers: httpHeaders,
        observe: 'response'
      });
    }
  }

  register(apiData) {

    const httpHeaders = new HttpHeaders({
      'Content-Type': 'application/json'
    });

    return this.http.post(this.baseUrl + '/user/register', apiData, {
      headers: httpHeaders,
      observe: 'response'
    });
  }

  logout() {
    sessionStorage.clear();
  }
}
import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { RegisterService } from './register.service';
import { Router, ActivatedRoute } from '@angular/router';
import { Cookie } from 'ng2-cookies/ng2-cookies';
import Swal from 'sweetalert2';
declare let gtag: Function;

@Component({
  selector: 'app-register',
  templateUrl: './register.component.html',
  styleUrls: ['./register.component.css']
})
export class RegisterComponent implements OnInit {
  registerForm: FormGroup;
  submitted = false;

  constructor(
    private formBuilder: FormBuilder,
    private registerService: RegisterService,
    private router: Router,
    private route: ActivatedRoute,
  ) { }

  ngOnInit() {
    this.registerForm = this.formBuilder.group({
      countryCode: ['', [Validators.required,]],
      mobileNumber: ['', [Validators.required,]],
      email: ['', [Validators.required,]],
      firstname: ['', [Validators.required,]],
      lastname: ['', [Validators.required,]]
    });

  }
  // convenience getter for easy access to form fields
  get f() { return this.registerForm.controls; }

  onSubmit() {
    this.submitted = true;
    const registerData = this.registerForm.value;


    // stop here if form is invalid
    if (this.registerForm.invalid) {
      return;
    }
    const registerSaveData = { countryCode: registerData.countryCode, mobileNumber: registerData.mobileNumber, email: registerData.email, firstName: registerData.firstname, lastName: registerData.lastname }
    this.registerService.register(registerSaveData).subscribe(
      res => {
        const data = res.body['data']
        if (res.body['error'] === 'true') {
          Swal({
            title: 'Error',
            text: res.body['message'],
            type: 'error',
            confirmButtonText: 'Ok',
          });
        } else {
          Swal({
            title: 'Success',
            text: res.body['message'],
            type: 'error',
            confirmButtonText: 'Ok',
          });
          window.location.href = 'https://app.docnowonline.com/docnow-enterprise/signupsuccess/index.html';
          // this.router.navigateByUrl('/login');
        }
      });
  }
}




import {
  Component,
  AfterViewInit,
  OnDestroy,
  ViewChild,
  ElementRef,
  ChangeDetectorRef,
  OnInit
} from '@angular/core';

import { NgForm } from '@angular/forms';
import {
  StripeService,
  ElementOptions,
  ElementsOptions,
  StripeCardComponent
} from 'ngx-stripe';

@Component({
  selector: 'app-stripee',
  templateUrl: './stripee.component.html',
  styleUrls: ['./stripee.component.css']
})
export class StripeeComponent implements AfterViewInit, OnDestroy , OnInit {
  @ViewChild('cardInfo') cardInfo: ElementRef;
  card: any;
  cardHandler = this.onChange.bind(this);
  error: string;
  constructor(private cd: ChangeDetectorRef) { }
  ngOnInit(): void {
    throw new Error('Method not implemented.');
  }
  cardOptions: ElementOptions = {
    style: {
      base: {
        iconColor: '#666EE8',
        color: '#31325F',
        fontWeight: 300,
        fontFamily: '"Helvetica Neue", Helvetica, sans-serif',
        fontSize: '18px',
        '::placeholder': {
          color: '#CFD7E0'
        }
      }
    }
  };
  ngAfterViewInit():void {
    
    var  elements
    this.card = elements.create('card');
    this.card.mount(this.cardInfo.nativeElement);
    this.card.addEventListener('change', this.cardHandler);
  }
  ngOnDestroy():void{
    this.card.removeEventListener('change', this.cardHandler);
    this.card.destroy();
  }
  onChange({ error }) {
    if (error) {
      this.error = error.message;
    } else {
      this.error = null;
    }
    this.cd.detectChanges();
  }
  async onSubmit(form: NgForm) {
    var  stripe
    const { token, error } = await stripe.createToken(this.card);
if (error) {
  console.log('Something is wrong:', error);
} else {
  console.log('Success!', token);
  // <span class="code-annotation">// ...send the token to the your backend to process the charge</span>
}
}
}
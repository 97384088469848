import { Component, OnInit } from '@angular/core';
import { Cookie } from 'ng2-cookies/ng2-cookies';
import { Router, ActivatedRoute } from '@angular/router';
import { HeaderService } from './header.service';
import { NgxSpinnerService } from "ngx-spinner";
import { from } from 'rxjs';
import { environment } from '../../../environments/environment';
import { SeoService } from '../seo.service';
declare var $;
@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.css']
})
export class HeaderComponent implements OnInit {
  appURL = environment.appURL;
  accessToken = Cookie.get('token');
  userName = Cookie.get('userName');
  plusMemberId = Cookie.get('plusMemberId');
  image = Cookie.get('image');
  hide: boolean;
  hidePlus: boolean;
  classtoggle = false;
  constructor(
    private seoObj: SeoService,
    public headerService: HeaderService,
    private router: Router,
    private route: ActivatedRoute,
    private spinner: NgxSpinnerService
  ) { }

  ngOnInit() {
    this.seoObj.createLinkForCanonicalURL()
    this.spinner.show();
    if (this.userName != null) {
      this.hide = true
    } else {
      this.hide = false
    }
    if (this.plusMemberId != "invalid") {
      this.hidePlus = true
    } else {
      this.hidePlus = false
    }
    this.spinner.hide();

  }
  toggle() {
    console.log('hi');
    this.classtoggle = !this.classtoggle;
  }
  logout() {
    this.spinner.show();
    
    Cookie.deleteAll('/');
    Cookie.delete('token');
    Cookie.delete('image');
    Cookie.delete('userName');
    Cookie.delete('latitude');
    Cookie.delete('latitude');
    Cookie.delete('doctorId');
    localStorage.clear();
    sessionStorage.clear();
    this.spinner.hide();
    this.router.navigateByUrl('/');
    window.location.replace(this.appURL);
    
  }
}

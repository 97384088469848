import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import { FamilymemberService } from './familymember.service';
import { NgxSpinnerService } from "ngx-spinner";
import Swal from 'sweetalert2';






@Component({
  selector: 'app-familymember',
  templateUrl: './familymember.component.html',
  styleUrls: ['./familymember.component.css']
})
export class FamilymemberComponent implements OnInit {
  memberForm: FormGroup;
    submitted = false;
    memberData:any;
    // name: any;
    // gender: any;
    // dob: any;
    // type: any;
    selectedOption: any;
    selectedOption1: any;

    sex =[
      { Gender: "Male", value: 1 },
      { Gender: "Female", value: 2},
  
    ]

    spouse =[
      { type: "children ", value: 1 },
      { type: "mother ", value: 2},
      { type: "father", value: 3},
  
  
    ]
  // profileForm: FormGroup;

  constructor(
    private formBuilder: FormBuilder,
    private router: Router,
    private route: ActivatedRoute,
    private spinner: NgxSpinnerService,
    private familymemberService: FamilymemberService, 

  )
  
  {
    this.memberForm = this.formBuilder.group({
      name: ['', Validators.required],
      gender: ['', Validators.required],
      dob: ['', Validators.required],
      type: ['', Validators.required]

   })
  }
  get f() { return this.memberForm.controls; }

  ngOnInit() {
  }
  
  onSubmit(){
    this.submitted = true;
    this.spinner.show();
    
  const memberData = this.memberForm.value;
    // console.log(memberData)
    // stop here if form is invalid
    if (this.memberForm.invalid) {
        return;
    }
    else{

    }
    const memberdata = { name: memberData.name,type: memberData.type,gender: memberData.gender,dob: memberData.dob,}
  
      this.familymemberService.updateProfile(memberdata).subscribe(
        res => {
          this.spinner.hide();
          const data = res.body['data']
          if (res.body['error'] === 'true') {
          this.spinner.hide();
  
            Swal({
              
              title: 'Error',
              text: res.body['message'],
              type: 'error',
              confirmButtonText: 'Ok',
            });
          } else {
          this.spinner.hide();
  
            this.router.navigate(['/user/profileupdate'],{state: memberdata});
  
          }
        });
  
    }
}



import { Injectable } from '@angular/core';
import { environment } from '../../environments/environment';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Cookie } from 'ng2-cookies/ng2-cookies';



@Injectable({
    providedIn: 'root'
})

export class DoctorProfileService {

    baseUrl = environment.baseUrl;
    googleMapKey = environment.googleMapKey;

    accessToken = Cookie.get('token');


    constructor(private http: HttpClient) { }

    viewDoctorProfile(data) {
        if (this.accessToken) {
            const httpHeaders = new HttpHeaders({
                'Content-Type': 'application/json',
                'authorization': this.accessToken,
                'role': 'user'
            });
            return this.http.post(this.baseUrl + '/user/listDoctorDetailView', data, {
                headers: httpHeaders,
                observe: 'response'
            });
        } else {
            const httpHeaders = new HttpHeaders({
                'Content-Type': 'application/json'
            });
            return this.http.post(this.baseUrl + '/user/listDoctorDetailView', data, {
                headers: httpHeaders,
                observe: 'response'
            });
        }
    }

    categoryList() {
        const httpHeaders = new HttpHeaders({
            'Content-Type': 'application/json'
        });

        return this.http.get(this.baseUrl + '/user/getAllCategory', {

            headers: httpHeaders,
            observe: 'response'
        });
    }
    reviewDetails(filterData) {
        const httpHeaders = new HttpHeaders({
            'Content-Type': 'application/json'
        });

        return this.http.post(this.baseUrl + '/user/listReviews', filterData, {

            headers: httpHeaders,
            observe: 'response'
        });
    }
    getGoogleReview(data) {
        const httpHeaders = new HttpHeaders({
            'Content-Type': 'application/json'
        });

        return this.http.post(this.baseUrl + '/user/listGoogleReview', data, {
            headers: httpHeaders,
            observe: 'response'
        });
    }
    favourDetails(apiData) {
        // console.log(apiData)
        const httpHeaders = new HttpHeaders({
            'Content-Type': 'application/json',
            'authorization': this.accessToken,
            'role': 'user'
        });

        return this.http.post(this.baseUrl + '/user/favourite', apiData, {

            headers: httpHeaders,
            observe: 'response'
        });


    }
    removeFavourDetails(apiData) {
        // console.log(apiData)
        const httpHeaders = new HttpHeaders({
            'Content-Type': 'application/json',
            'authorization': this.accessToken,
            'role': 'user'
        });

        return this.http.post(this.baseUrl + '/user/removeFromFavourite', apiData, {

            headers: httpHeaders,
            observe: 'response'
        });


    }
}
import { Component, OnInit } from '@angular/core';
import { Cookie } from 'ng2-cookies/ng2-cookies';
import { Router, ActivatedRoute } from '@angular/router';
import { NgxSpinnerService } from "ngx-spinner";
import { from } from 'rxjs';
import { environment } from '../../environments/environment';
import { SeoService } from '../layouts/seo.service';
import { GainPatientService } from '../gain-patient/gain-patient.service';
import Swal from 'sweetalert2';
import { FormBuilder, FormGroup, Validators, FormControl } from '@angular/forms';

@Component({
  selector: 'app-about',
  templateUrl: './about.component.html',
  styleUrls: ['./about.component.css']
})
export class AboutComponent implements OnInit {
  appURL = environment.appURL;
  doctorForm: any;
  categoryArr: any;
  classtoggle = false;
  footerYear: any = new Date().getFullYear();
  constructor(
    private seoObj: SeoService,
    private router: Router,
    private route: ActivatedRoute,
    private spinner: NgxSpinnerService,
    private service: GainPatientService,
    private formBuilder: FormBuilder
  ) {
    this.doctorForm = this.formBuilder.group({
      location: ['', Validators.required],
      firstName: ['', Validators.required],
      lastName: ['', Validators.required],
      businessName: [''],
      phone: ['', Validators.required],
      email: ['', Validators.required],
      specialty: ['', Validators.required],
      recaptcha: ['', Validators.required]
    })
  }

  private loadSeoTags() {
    this.seoObj.setTitle('Join | DocNow')
    this.seoObj.setDescription('There’s no better way to deliver the greatest healthcare experience we all deserve.')
    this.seoObj.setAuthor('DocNow LLC')
    this.seoObj.setKeyword('Join | DocNow')
    this.seoObj.setOGTitle('Join | DocNow')
    this.seoObj.setOGDescription('There’s no better way to deliver the greatest healthcare experience we all deserve.')
  }

  ngOnInit() {
    this.loadSeoTags()
    this.service.searchDefaultData().subscribe(async res => {
      var cat = res.body['data'].categories
      this.categoryArr = cat
    })
  }

  submit() {
    // if (this.doctorForm.dirt) {
    var request = {
      location: this.doctorForm.value.location,
      name: "",
      phone: this.doctorForm.value.phone,
      email: this.doctorForm.value.email,
      businessName: this.doctorForm.value.businessName,
      providerName: this.doctorForm.value.firstName + " " + this.doctorForm.value.lastName,
      emr: "",
      insurance: "",
      specialty: this.doctorForm.value.specialty,
      hospitalAffiliation: "",
      recaptcha: this.doctorForm.value.recaptcha
    }
    console.log("data", request)
    this.service.requestData(request).subscribe(async res => {
      console.log("res", res)
      if (res["body"]["error"] == "false") {
        Swal({
          title: 'Thank you!',
          text: 'We have received your request and one of our representatives will be in touch within the next 24-48 hours. In the meantime if you have any questions please submit them to sales@docnowonline.com',
          type: 'success',
          showConfirmButton: true,
          confirmButtonColor: '#3085d6',
          cancelButtonColor: '#d33',
          confirmButtonText: 'Okay',
          allowOutsideClick: false
        }).then(async (result) => {
          if (result.value) {
            window.location.reload()
          }
        })
      } else {
        Swal('Oops...', res["body"]["message"], 'error')
      }
    })
    // } else {
    //   Swal('Oops...', 'Please Enter Valid Details', 'error')
    // }
  }

  resolved(captchaResponse: string, res) {
    console.log(`Resolved response token: ${captchaResponse}`);
    this.doctorForm.patchValue({ recaptcha: captchaResponse })
  }
}

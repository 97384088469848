import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http'
import { from, Observable } from 'rxjs';
import { environment } from '../../environments/environment';
import { Cookie } from 'ng2-cookies/ng2-cookies';


@Injectable({
  providedIn: 'root'
})
export class PaymentService {
  accessToken = Cookie.get('token');

  httpheaders = new HttpHeaders({
    'Content-Type': [],
    'Accept': 'application/json',
    'lang': 'default',
    'authorization': this.accessToken,
    'role': 'doctor'
  });

  baseUrl = environment.baseUrl;
  private handleError(error: Response) {
    return Observable.throw(error.statusText);
  }
  constructor(private http: HttpClient,) {
    // console.log(this.cookie.get('Token').split('|')[0]);

  }
  // Create Customer
  createcustomer() {
    return this.http.get(`${this.baseUrl}/createCustomer`, {
      headers: this.httpheaders,
      observe: 'response'
    });
  }

  listCards(){
    return this.http.get(`${this.baseUrl}/user/listCard`, {
      headers: this.httpheaders,
      observe: 'response'
    });
  }

  deleteCard(data){
    return this.http.post(`${this.baseUrl}/user/deleteCard`, data, {
      headers: this.httpheaders,
      observe: 'response'
    });
  }

  addCard(data){
    return this.http.post(`${this.baseUrl}/user/addCard`, data, {
      headers: this.httpheaders,
      observe: 'response'
    });
  }

  stripePayment(data){
    return this.http.post(`${this.baseUrl}/stripePayment`, data, {
      headers: this.httpheaders,
      observe: 'response'
    });
  }
}

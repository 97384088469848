import { Component, OnInit } from '@angular/core';
import { WalletService } from './wallet.service';
import { Router } from '@angular/router';
import { NgxSpinnerService } from "ngx-spinner";
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { AccountdetailsService } from '../accountdetails/accountdetails.service';
import Swal from 'sweetalert2';
@Component({
  selector: 'app-wallet',
  templateUrl: './wallet.component.html',
  styleUrls: ['./wallet.component.css']
})
export class WalletComponent implements OnInit {
  cardForm: FormGroup;
  walletBalance: any;
  walletTransaction: any;
  pages: number;
  page = 1;
  submitted: boolean;
  cardList: any;
  constructor(
    private WalletService: WalletService,
    private formBuilder: FormBuilder,
    private router: Router,
    private spinner: NgxSpinnerService,
    private AccountdetailsService: AccountdetailsService,
  ) {
    this.cardForm = this.formBuilder.group({
      amount: ['', [Validators.required,]],
      cardNumber: ['']
      // expiry: ['', [Validators.required, Validators.maxLength(16)]],
      // cvc: ['', [Validators.required, Validators.maxLength(16)]],
    }, {
      });
  }
  // convenience getter for easy access to form fields
  get f() {
    return this.cardForm.controls;
  }
  ngOnInit() {
    this.spinner.show();
    this.AccountdetailsService.listCard().subscribe(
      response => {
        this.spinner.hide();
        this.cardList = response.body['data'];
      },
      err => {
        this.spinner.hide();
        this.router.navigateByUrl('/serverError');
      }
    );
    var data = { page: 1 }
    this.WalletService.myWallet().subscribe(
      response => {
        this.spinner.hide();
        this.walletBalance = response.body['data'].walletBalance;
        this.walletTransaction = response.body['data'].walletTransaction;
        this.pages = response.body['data'].pageCount * 10;
      },
      err => {
        this.spinner.hide();
        this.router.navigateByUrl('/serverError');
      }
    );
  }
  onSubmit() {
    this.submitted = true;
    // stop here if form is invalid
    if (this.cardForm.invalid) {
      return;
    }
    var cardData = this.cardForm.value;
    var data = { amount: cardData.amount, source: cardData.cardNumber }
    this.WalletService.addMoneyToWallet(data).subscribe(
      response => {
        this.spinner.hide();
        if (response.body['error'] === 'true') {
          Swal({
            title: 'Error',
            text: response.body['message'],
            type: 'error',
            confirmButtonText: 'Ok',
          });
        } else {
          this.WalletService.myWallet().subscribe(
            response => {
              this.spinner.hide();
              this.walletBalance = response.body['data'].walletBalance;
              this.walletTransaction = response.body['data'].walletTransaction;
              this.pages = response.body['data'].pageCount * 10;
            },
            err => {
              this.spinner.hide();
              this.router.navigateByUrl('/serverError');
            }
          );
        }
      },
      err => {
        this.spinner.hide();
        this.router.navigateByUrl('/serverError');
      }
    );
    // display form values on success
  }
  addCard() {
    const cardData = { addcardview: true }
    this.router.navigate(['/user/payment'], { state: cardData });
  }
}
// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.
import Swal from 'sweetalert2';

export const environment = {
	production: false,
	baseUrl: 'https://app.docnowonline.com/api',
	googleMapKey: 'AIzaSyAIXtcNLueYoYGkWH0AoQiRqxUJn7a8A7Y',
	googleLoginKey: '52016978030-4j03bovjqri464gh49nikeespgok7elb.apps.googleusercontent.com',
	facebookLoginKey: '2456513114577853',
	ChatUrl: 'https://app.docnowonline.com/',
	scriptBasePath: '../../',
	// appURL: 'localhost:4200/',
	appURL: 'https://docnowonline.com/',
	swalalert(type, msg) {
		if (type === 'success') {
			return new Promise(resolve => {
				Swal({
					type: 'success',
					title: 'Success',
					text: msg,
					showConfirmButton: false,
					timer: 1500
				}).then(async (result) => {
					resolve(true);
				});
			});
		} else if (type === 'signup') {
			return new Promise(resolve => {
				Swal({
					type: 'success',
					title: 'Success',
					text: msg,
					showConfirmButton: true,
					timer: 7000
				}).then(async (result) => {
					timer: 1500
					resolve(result);
				});
			});
		} else if (type === 'emailsuccess') {
			return new Promise(resolve => {
				Swal({
					type: 'success',
					title: 'Success',
					text: msg,
					showConfirmButton: true
				}).then(async (result) => {
					resolve(result);
				});
			});
		} else if (type === 'payment') {
			return new Promise(resolve => {
				Swal({
					title: 'Payment',
					text: msg,
					type: 'warning',
					showCancelButton: false,
					confirmButtonColor: '#3085d6',
					cancelButtonColor: '#d33',
					confirmButtonText: 'Proceed',
					allowOutsideClick: false
				}).then(async (result) => {
					if (result.value) {
						Swal({
							title: msg + '!',
							text: '  ' + msg + '.',
							type: 'success',
							showConfirmButton: false,
							timer: 1000
						}).then(async (result) => {
							resolve(true);
						})
					}
				})
			});
		} else if (type === 'delete') {
			return new Promise(resolve => {
				Swal({
					title: 'Are you sure?',
					text: "You won't be able to revert this!",
					type: 'warning',
					showCancelButton: true,
					confirmButtonColor: '#3085d6',
					cancelButtonColor: '#d33',
					confirmButtonText: 'Yes, ' + msg + ' it!'
				}).then(async (result) => {
					if (result.value) {
						Swal({
							title: msg + '!',
							text: '  ' + msg + '.',
							type: 'success',
							showConfirmButton: false,
							timer: 1000
						}).then(async (result) => {
							resolve(true);
						})
					}
				})
			});
		} else if (type === 'nodata' || type === 'underconst') {
			Swal({
				title: '<strong>Attention<u>!</u></strong>',
				type: 'info',
				html: '<b>' + msg + '</b>',
				showCloseButton: true,
				showCancelButton: false,
				focusConfirm: false,
				confirmButtonText:
					'OK!',
				confirmButtonAriaLabel: 'Thumbs up, great!',
				cancelButtonText:
					'Cancel',
				cancelButtonAriaLabel: 'Thumbs down',
			})
		}
		else {
			Swal({
				type: type,
				title: 'OOPS!',
				text: msg,
				showConfirmButton: true,
			});
		}
	}
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.

import { Component, OnInit, ViewChild, ElementRef, NgZone } from '@angular/core';
import { MapsAPILoader, MouseEvent } from '@agm/core';
import { GoogleMapsAPIWrapper } from '@agm/core/services';
import { FormBuilder, FormGroup, Validators, FormControl } from '@angular/forms';
import { google } from "google-maps";
import { IndexService } from './index.service';
import { Cookie } from 'ng2-cookies/ng2-cookies';
import { from } from 'rxjs';
declare const google: any;
import { Router, ActivatedRoute } from '@angular/router';
import * as $ from '../../assets/js/jquery.min.js';
import { NgxSpinnerService } from "ngx-spinner";
import Swal from 'sweetalert2';
import { LoadJsService } from '../layouts/load-js.service';

@Component({
  selector: 'app-index',
  templateUrl: './index.component.html',
  styleUrls: ['./index.component.css']
})
export class IndexComponent implements OnInit {
  latitude: number;
  longitude: number;
  zoom: number;
  address: string;
  wikiItems: any[] = [];
  geoCoder: any;
  searchForm: any;
  speciality: any;
  blogsList: any;
  subscriptionData: any;
  directPayId: any;
  plusMemberId: any;
  categoryId: any;
  categoryArr: any[] = [];
    // categoryArr: [] =  [{"categoryId":1,"categoryName":"Cardiology","categoryImage":"https://app.docnowmd.com/uploads/admin/1598335835713.png","count":3},{"categoryId":2,"categoryName":"Pediatrician","categoryImage":"https://app.docnowmd.com/uploads/admin/1598335889427.png","count":2},{"categoryId":3,"categoryName":"Homeopathy","categoryImage":"https://app.docnowmd.com/uploads/admin/1598335944321.png","count":1},{"categoryId":4,"categoryName":"General Physician","categoryImage":"https://app.docnowmd.com/uploads/admin/1598336011800.png","count":1},{"categoryId":5,"categoryName":"Kidney Issues","categoryImage":"https://app.docnowmd.com/uploads/admin/1598336057099.png","count":1},{"categoryId":6,"categoryName":"Mental Wellness","categoryImage":"https://app.docnowmd.com/uploads/admin/1598336200563.png","count":2},{"categoryId":7,"categoryName":"Digestive","categoryImage":"https://app.docnowmd.com/uploads/admin/1598336261896.png","count":1},{"categoryId":8,"categoryName":"Cancer","categoryImage":"https://app.docnowmd.com/uploads/admin/1598336308094.png","count":1},{"categoryId":9,"categoryName":"Surgery","categoryImage":"https://app.docnowmd.com/uploads/admin/1598336337095.png","count":1},{"categoryId":10,"categoryName":"Dental Care","categoryImage":"https://app.docnowmd.com/uploads/admin/1598336378592.png","count":1}]
  topSpeciality: any;
  recentlyVisitedDoctors: any;
  recentlyVisitedDoctorsShow: boolean = false;
  accessToken = Cookie.get('token');
  plus:any;
  memberId: any;
  date: any;
  lat:any;
  lng:any;

  @ViewChild('search')
  public searchElementRef: ElementRef;
  location: string;
 
  constructor(
    private mapsAPILoader: MapsAPILoader,
    private indexService: IndexService,
    private ngZone: NgZone,
    private formBuilder: FormBuilder,
    private router: Router,
    private spinner: NgxSpinnerService,
    private jsObj: LoadJsService
  ) {
    // this.loadScripts()
    this.searchForm = this.formBuilder.group({
      location: ['', Validators.required],
      speciality: ['', Validators.required]
    });
  }

  searchSpeciality(event) {
    // console.log('i am called', this.searchForm.value)
    const data = { name: this.searchForm.value.speciality }
    this.indexService.search(data).subscribe(async res => {
      this.speciality = res.body['data'].categories;
      // console.log('countries', this.speciality)
    })
  }

  dropdownList = [];
  selectedItems = [];
  dropdownSettings = {};

  ngOnInit() {
    this.spinner.show();
    this.router.routeReuseStrategy.shouldReuseRoute = function () {
      return false;
    };
  if (this.accessToken == undefined) {
  this.directPayId = 'invalid'
  this.plusMemberId = 'invalid'
  }

  this.indexService.searchDefaultData().subscribe(async res => {
      this.dropdownList = res.body['data'].categories
      var cat = res.body['data'].categories
      this.categoryArr = cat
    })

    this.setCurrentLocation();
    this.spinner.show();
    this.indexService.blogList().subscribe(async res => {
      this.blogsList = res.body['data'].blogs;
      // console.log(this.blogsList)
      this.spinner.hide();
    },
      err => {
      this.spinner.hide();
        this.router.navigateByUrl('/serverError');
      }
    );
    this.indexService.subscriptionData().subscribe(async res => {
      this.subscriptionData = res.body['data'].userSubscriptionDetails;
      this.directPayId  = res.body['data'].userSubscriptionDetails[0].directPayId;
      this.plusMemberId  = res.body['data'].userSubscriptionDetails[0].plusMemberId;
  //  console.log(this.directPayId ,this.plusMemberId )
      this.spinner.hide();
    },
      err => {
      this.spinner.hide();
        this.router.navigateByUrl('/serverError');
      }
    );
    this.indexService.topSpecialityService().subscribe(async res => {
      this.topSpeciality = res.body['data'].topSpeciality;
      this.recentlyVisitedDoctors = res.body['data'].recentlyVisitedDoctors;
      // console.log(this.recentlyVisitedDoctors)
      if (this.recentlyVisitedDoctors.length > 0) {
        this.recentlyVisitedDoctorsShow = true
      }
      this.spinner.hide();
    },
      err => {
      this.spinner.hide();
        this.router.navigateByUrl('/serverError');
      }
    );


    //load Places Autocomplete
    this.mapsAPILoader.load().then(() => {
      this.setCurrentLocation();
      this.geoCoder = new google.maps.Geocoder;

      let autocomplete = new google.maps.places.Autocomplete(this.searchElementRef.nativeElement);
      autocomplete.addListener("place_changed", () => {
        this.ngZone.run(() => {
          //get the place result
          let place: google.maps.places.PlaceResult = autocomplete.getPlace();
          //set latitude, longitude and zoom
          this.latitude = place.geometry.location.lat();
          this.longitude = place.geometry.location.lng();
          // console.log('location details', this.latitude, this.longitude)
          this.zoom = 4;
        });
      });
    });

    var arr = []

    this.dropdownSettings = {
      singleSelection: true,
      text: "Search Doctors, Clinics, Hospitals, Diseases Etc",
      enableSearchFilter: true,
      classes: "form-control search-info form-group text",
      searchAutofocus: false,
      noDataLabel: "No Search Results Found",
      lazyLoading: true,
      enableFilterSelectAll: false,
      labelKey: 'categoryName',
      primaryKey: 'categoryId',
      searchBy: ["categoryName"],

    };
  
        
       
      
    // this.spinner.hide();

  }

  DirectView(subscriptionData){
    this.memberId = subscriptionData[0].directPayId;
    this.date = subscriptionData[0].directPayDate;

  }

  plusView(subscriptionData){
    this.memberId = subscriptionData[0].plusMemberId;
    this.date = subscriptionData[0].plusMemberDate;

  }

  onItemSelect(item: any) {
    this.categoryId = item.categoryId;
    Cookie.set('specialityId', item.categoryId);
    Cookie.set('specialityName', item.categoryName);
  }
  OnItemDeSelect(item: any) {
  }
  onSelectAll(items: any) {
  }
  onDeSelectAll(items: any) {
  }

  // Get Current Location Coordinates
  private setCurrentLocation() {
    if ('geolocation' in navigator) {
      navigator.geolocation.getCurrentPosition((position) => {
        // console.log(position)
        this.latitude = position.coords.latitude;
        this.longitude = position.coords.longitude;
        this.zoom = 8;
        this.getAddress(this.latitude, this.longitude);
      });
    }
  }


  markerDragEnd($event: MouseEvent) {
    this.latitude = $event.coords.lat;
    this.longitude = $event.coords.lng;
    this.getAddress(this.latitude, this.longitude);
  }

  getAddress(latitude, longitude) {
    // console.log(latitude, longitude)
    this.geoCoder.geocode({ 'location': { lat: latitude, lng: longitude } }, (results, status) => {
      if (status === 'OK') {
        // console.log(results)
        if (results[0]) {
          this.zoom = 12;
          this.address = results[0].formatted_address;
          // console.log(this.address)
          this.location = this.address
          this.searchForm = new FormGroup({
            location: new FormControl(this.address),
            speciality: new FormControl()
          })
        } else {
          window.alert('No results found');
        }
      } else {
        window.alert('Geocoder failed due to: ' + status);
      }

    });
  }


  searchdoctorList(id) {
    const categoryData = { categoryId: id, latitude: this.latitude, longitude : this.longitude }
    this.router.navigate(['/search'], { state: categoryData });


  }

  searchdoctor() {
    const categoryData = { categoryId: this.categoryId.toString(), latitude: this.latitude, longitude : this.longitude }
    this.router.navigate(['/search'], { state: categoryData });

  }


  onFocused(e) {
    // do something
  }

  //searchUrgentCareDoctor List
  searchUrgentCareDoctor() {
    const urgentCareData = { isUrgentCare: 1 , latitude: this.latitude, longitude : this.longitude }
    this.router.navigate(['/urgentcare'], { state: urgentCareData });
  }

  //Doctor List
  findDoctor() {
    const normalDoctorData = { categoryId:'1',latitude: this.latitude, longitude : this.longitude }
    this.router.navigate(['/search'], { state: normalDoctorData });
  }
  directDoctor() {
    const directDoctorData = { categoryId:'1',latitude: this.latitude, longitude : this.longitude }
    this.router.navigate(['/directDoctor'], { state: directDoctorData });
  }
  hospitallocation(){
    const hospitalData = { categoryId:'1',latitude: this.latitude, longitude : this.longitude }
    this.router.navigate(['/hospitallocation'], { state: hospitalData });
  }
  booking(id){
    const doctorData = { doctorId: id }

    this.router.navigate(['/booking'], { state: doctorData });

  }
  doctorprofile(id){
    const doctorData = { doctorId: id }

    this.router.navigate(['/doctorprofile'], { state: doctorData });
  }
  

  blogDetails(blogs){
    // console.log(blogs)
    this.router.navigate(['/blogDetails'],{ state: blogs });
  } 
  unsubscribePlus(){
    this.spinner.show();
    Swal({
      title: 'Put Some Words',
      input: 'text',
      inputValidator: (value) => {
        if (value) {
          var data= {reason:value}
          this.indexService.UnsubscribePlus(data).subscribe(async res => {
            window.location.reload();
           
          // console.log(res)
          Swal({
           title: 'success',
           text: 'Unsubscribe Successfully',
           type: 'success',
           showConfirmButton: false,
           timer: 1000
         });
            
          },);
        }
        return !value && 'You need to write something!'
      },
       type: "warning",
        showCancelButton: true,
        confirmButtonColor: '#DD6B55',
        confirmButtonText: 'Yes, I am sure!',
        cancelButtonText: "No, cancel it!",
        // closeOnConfirm: false,
        // closeOnCancel: false
    
    }),
    
          this.spinner.hide();
         
    
     
       
}
  unsubscribePay(){
    this.spinner.show();
   
    Swal({
  title: 'Put Some Words',
  input: 'text',
  inputValidator: (value) => {
    if (value) {
      var data= {reason:value}
      this.indexService.UnsubscribePay(data).subscribe(async res => {
        window.location.reload();
       
      // console.log(res)
      Swal({
       title: 'success',
       text: 'Unsubscribe Successfully',
       type: 'success',
       showConfirmButton: false,
       timer: 1000
     });
        
      },);
    }
    return !value && 'You need to write something!'
  },
   type: "warning",
    showCancelButton: true,
    confirmButtonColor: '#DD6B55',
    confirmButtonText: 'Yes, I am sure!',
    cancelButtonText: "No, cancel it!",
    // closeOnConfirm: false,
    // closeOnCancel: false

}),

      this.spinner.hide();
     

 
  
}


private loadScripts() {
    this.jsObj.load('jquery').then(async data => {
      this.jsObj.load('script').then(async data => {
          this.jsObj.load('bootstrap').then(async data => {
            this.jsObj.load('resizesensor').then(async data => {
              this.jsObj.load('popper').then(async data => {
                // this.jsObj.load('slick').then(async data => {
      //         this.jsObj.load('moment').then(async data => {
      //           this.jsObj.load('daterangepicker').then(async data => {
      //               this.jsObj.load('sidebar').then(async data => {
      //                 this.jsObj.load('fancybox').then(async data => {
      //                   this.jsObj.load('datetimepicker').then(async data => {
      //                     this.jsObj.load('select2').then(async data => {
      //                     }).catch(error => console.log(error));
      //                   }).catch(error => console.log(error));
      //                 }).catch(error => console.log(error));
      //               }).catch(error => console.log(error));
      //             }).catch(error => console.log(error));
      //           }).catch(error => console.log(error));
              // }).catch(error => console.log(error));
            }).catch(error => console.log(error));
          }).catch(error => console.log(error));
        }).catch(error => console.log(error));
      }).catch(error => console.log(error));
    }).catch(error => console.log(error));
  }
}

  

import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { AuthGuard } from './auth/auth.guard';
import { ReactiveFormsModule } from '@angular/forms';
import { HttpClientModule } from '@angular/common/http';
import { HttpModule } from '@angular/http';
import { RecaptchaModule, RecaptchaFormsModule } from 'ng-recaptcha';
import { RatingModule } from 'ng-starrating';
import { AutocompleteLibModule } from 'angular-ng-autocomplete';
import { SocketIoModule, SocketIoConfig } from 'ngx-socket-io';
import {
    SocialLoginModule,
    AuthServiceConfig,
    GoogleLoginProvider,
    FacebookLoginProvider,
} from "angular5-social-login";
import { NgOtpInputModule } from 'ng-otp-input';
import { NgxSpinnerModule } from 'ngx-spinner';
import { AgmCoreModule, GoogleMapsAPIWrapper } from '@agm/core';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { LoginComponent } from './login/login.component';
import { RegisterComponent } from './register/register.component';
import { ForgotpasswordComponent } from './forgotpassword/forgotpassword.component';
import { ChangepasswordComponent } from './changepassword/changepassword.component';
import { IndexComponent } from './index/index.component';
import { SearchComponent } from './search/search.component';
import { DoctorprofileComponent } from './doctorprofile/doctorprofile.component';
import { BookingComponent } from './booking/booking.component';
import { CheckoutComponent } from './checkout/checkout.component';
import { SuccessComponent } from './success/success.component';
import { InvoiceComponent } from './invoice/invoice.component';
import { PatientdashboardComponent } from './patientdashboard/patientdashboard.component';
import { FavouritesComponent } from './favourites/favourites.component';
import { ProfilesettingsComponent } from './profilesettings/profilesettings.component';
import { LayoutsComponent } from './layouts/layouts.component';
import { HeaderComponent } from './layouts/header/header.component';
import { FooterComponent } from './layouts/footer/footer.component';
import { UserlayoutComponent } from './userlayout/userlayout.component';
import { SidebarComponent } from './userlayout/sidebar/sidebar.component';
import { HomelayoutComponent } from './homelayout/homelayout.component';
import { ChatComponent } from './chat/chat.component';
import { FormsModule } from '@angular/forms';
import { PlusComponent } from './plus/plus.component';
import { VerifyotpComponent } from './verifyotp/verifyotp.component';
import { SocialsignupComponent } from './socialsignup/socialsignup.component';
import { AngularMultiSelectModule } from 'angular2-multiselect-dropdown';
import { UpdateprofileComponent } from './updateprofile/updateprofile.component';
import { AddaddressComponent } from './addaddress/addaddress.component';
import { ProfileupdateComponent } from './profileupdate/profileupdate.component';
import { environment } from '../environments/environment';
import { BlogListComponent } from './blog-list/blog-list.component';
import { BlogDetailsComponent } from './blog-details/blog-details.component';
import { DoctorProfile } from '../app/doctorprofile/doctorprofile';
import { ListhospitalComponent } from './listhospital/listhospital.component';
import { HospitallocationComponent } from './hospitallocation/hospitallocation.component';
import { WalletComponent } from './wallet/wallet.component';
import { AudiocallComponent } from './audiocall/audiocall.component';
import { VideocallComponent } from './videocall/videocall.component';
import { UrgentcareComponent } from './urgentcare/urgentcare.component';
import { AccountdetailsComponent } from './accountdetails/accountdetails.component';
import { ChatboxComponent } from './chatbox/chatbox.component';
import { NgProgressModule } from 'ngx-progressbar';
import { StripeeComponent } from './stripee/stripee.component';
import { PaymentComponent } from './payment/payment.component';
import { NgxStripeModule } from 'ngx-stripe';
import { FamilymemberComponent } from './familymember/familymember.component';
import { AddInsuranceComponent } from './add-insurance/add-insurance.component';
import { EditInsuranceComponent } from './edit-insurance/edit-insurance.component';
import { ChartsModule } from 'ng2-charts';
import { EditfamilyComponent } from './editfamily/editfamily.component';
import { DirectpayComponent } from './directpay/directpay.component';
import { DirectdoctorComponent } from './directdoctor/directdoctor.component';
import { HomeComponent } from './home/home.component';
import { ChildrenHospitalLocationComponent } from './children-hospital-location/children-hospital-location.component';
import { LocationComponent } from './location/location.component';
import { Ng2DeviceDetectorModule } from 'ng2-device-detector';
import { NearMeComponent } from './near-me/near-me.component';
import { AngularMyDatePickerModule } from 'angular-mydatepicker';
import { NearMeSpecialityComponent } from './near-me-speciality/near-me-speciality.component';
import { UrgentCareComponent } from './urgent-care/urgent-care.component';
import { GainPatientComponent } from './gain-patient/gain-patient.component';
import { VisionComponent } from './vision/vision.component';
import { UrgentpageComponent } from './urgentpage/urgentpage.component';
import { SuccessstoryComponent } from './successstory/successstory.component';
import { EmrintegrationComponent } from './emrintegration/emrintegration.component';
import { PracticegrowthComponent } from './practicegrowth/practicegrowth.component';
import { BlogComponent } from './blog/blog.component';
import { DocnowoncallComponent } from './docnowoncall/docnowoncall.component';
import { WaitlistComponent } from './waitlist/waitlist.component';
import { TelehealthComponent } from './telehealth/telehealth.component';
import { SwiperModule } from 'swiper/angular';
import { SeoComponent } from './seo/seo.component';
import { OurteamComponent } from './ourteam/ourteam.component';
import { CareerComponent } from './career/career.component';
import { PreeroomComponent } from './preeroom/preeroom.component';
import { SwiperComponent } from './home/swiper/swiper.component';
import { IvyCarouselModule } from 'angular-responsive-carousel';
import { AboutComponent } from './about/about.component';
import { NgxCarouselModule } from 'ngx-light-carousel';
import { NewBillingComponent } from './new-billing/new-billing.component'

const config: SocketIoConfig = { url: 'https://app.docnowonline.com/', options: {} };
// Configs 
export function getAuthServiceConfigs() {
    let config = new AuthServiceConfig(
        [
            {
                id: FacebookLoginProvider.PROVIDER_ID,
                provider: new FacebookLoginProvider(environment.facebookLoginKey)
            },
            {
                id: GoogleLoginProvider.PROVIDER_ID,
                provider: new GoogleLoginProvider(environment.googleLoginKey)
            },
        ]
    );
    return config;
}
@NgModule({
    declarations: [
        AppComponent,
        LoginComponent,
        RegisterComponent,
        ForgotpasswordComponent,
        ChangepasswordComponent,
        IndexComponent,
        SearchComponent,
        DoctorprofileComponent,
        BookingComponent,
        CheckoutComponent,
        SuccessComponent,
        InvoiceComponent,
        PatientdashboardComponent,
        FavouritesComponent,
        ProfilesettingsComponent,
        LayoutsComponent,
        HeaderComponent,
        FooterComponent,
        UserlayoutComponent,
        SidebarComponent,
        HomelayoutComponent,
        ChatComponent,
        PlusComponent,
        VerifyotpComponent,
        SocialsignupComponent,
        UpdateprofileComponent,
        AddaddressComponent,
        ProfileupdateComponent,
        BlogListComponent,
        BlogDetailsComponent,
        DoctorProfile,
        ListhospitalComponent,
        HospitallocationComponent,
        WalletComponent,
        AudiocallComponent,
        VideocallComponent,
        UrgentcareComponent,
        AccountdetailsComponent,
        ChatboxComponent,
        StripeeComponent,
        PaymentComponent,
        FamilymemberComponent,
        AddInsuranceComponent,
        EditInsuranceComponent,
        EditfamilyComponent,
        DirectpayComponent,
        DirectdoctorComponent,
        HomeComponent,
        ChildrenHospitalLocationComponent,
        LocationComponent,
        NearMeComponent,
        NearMeSpecialityComponent,
        UrgentCareComponent,
        GainPatientComponent,
        VisionComponent,
        UrgentpageComponent,
        SuccessstoryComponent,
        EmrintegrationComponent,
        PracticegrowthComponent,
        BlogComponent,
        DocnowoncallComponent,
        WaitlistComponent,
        TelehealthComponent,
        SeoComponent,
        OurteamComponent,
        CareerComponent,
        PreeroomComponent,
        SwiperComponent,
        AboutComponent,
        NewBillingComponent
    ],
    imports: [
        NgxCarouselModule,
        RecaptchaModule,
        RecaptchaFormsModule,
        SwiperModule,
        BrowserModule,
        IvyCarouselModule,
        RatingModule,
        AppRoutingModule,
        AngularMultiSelectModule,
        FormsModule,
        Ng2DeviceDetectorModule.forRoot(),
        ChartsModule,
        HttpModule,
        HttpClientModule,
        AutocompleteLibModule,
        ReactiveFormsModule,
        NgxSpinnerModule,
        NgProgressModule,
        AngularMyDatePickerModule,
        SocketIoModule.forRoot(config),
        AgmCoreModule.forRoot({
            apiKey: environment.googleMapKey,
            libraries: ['places']
        }),
        SocialLoginModule,
        NgOtpInputModule,
        NgbModule,
        NgxStripeModule.forRoot('pk_live_BudM1tUyIu1ES137E6XesJLR00J1wxKeom'),

    ],
    providers: [
        {
            provide: AuthServiceConfig,
            useFactory: getAuthServiceConfigs
        },
        NgxSpinnerModule,
        GoogleMapsAPIWrapper,
        AuthGuard],
    bootstrap: [AppComponent]
})
export class AppModule { }

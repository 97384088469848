import { Component, OnInit } from '@angular/core';
import { AccountdetailsService } from './accountdetails.service';
import { Router } from '@angular/router';
import { NgxSpinnerService } from "ngx-spinner";
import { PaymentService } from '../payment/payment.service';
import { environment } from 'src/environments/environment';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { WalletService } from '../wallet/wallet.service';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-accountdetails',
  templateUrl: './accountdetails.component.html',
  styleUrls: ['./accountdetails.component.css']
})
export class AccountdetailsComponent implements OnInit {
  walletBalance: any;
  cardList: any;
  pages: number;
  page = 1;
  cardForm: FormGroup;
  submitted: boolean;

  constructor(
    private AccountdetailsService: AccountdetailsService,
    private router: Router,
    private spinner: NgxSpinnerService,
    private service: PaymentService,
    private formBuilder: FormBuilder,
    private WalletService: WalletService,
  ) {
    this.cardForm = this.formBuilder.group({

      amount: ['', [Validators.required,]],
      cardNumber: ['', [Validators.required,]]
    }, {
      });
  }

  ngOnInit() {
    var data = { page: 1 }
    this.spinner.show();
    this.listCard();
  }

  listCard() {
    this.AccountdetailsService.listCard().subscribe(
      response => {
        this.spinner.hide();
        this.cardList = response.body['data'];
      },
      err => {
        this.spinner.hide();
        this.router.navigateByUrl('/serverError');
      }
    );
  }

  deleteCard(card) {
    var request = { cardId: card }
    Swal({
      title: 'delete',
      text: 'are you want delete',
      type: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085D6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'yes'
    }).then((result) => {
      if (result.value) {
        this.service.deleteCard(request).subscribe(
          res => {
            if (res.body['error'] === 'false') {
              Swal({
                title: 'success',
                text: 'card deleted',
                type: 'success',
                showConfirmButton: false,
                timer: 1000
              });
            }
          });
        this.AccountdetailsService.listCard().subscribe(
          response => {
            this.spinner.hide();
            this.cardList = response.body['data'];
          },
          err => {
            this.spinner.hide();
            this.router.navigateByUrl('/serverError');
          }
        );
      }
    })
  }

  onSubmit() {
    this.submitted = true;

    // stop here if form is invalid
    if (this.cardForm.invalid) {
      return;
    }
    var cardData = this.cardForm.value;
    var data = { amount: cardData.amount, source: cardData.cardNumber }
    this.WalletService.addMoneyToWallet(data).subscribe(
      response => {
        this.spinner.hide();
        if (response.body['error'] === 'true') {
          Swal({
            title: 'success',
            text: response.body['message'],
            type: 'success',
            confirmButtonText: 'Ok',
          });
        } else {
          this.router.navigateByUrl('/user/wallet');
        }
      },
      err => {
        this.spinner.hide();
        this.router.navigateByUrl('/serverError');
      }
    );

    // display form values on success
  }

  addCard() {
    const cardData = { addcardview: true }
    this.router.navigate(['/user/payment'], { state: cardData });
  }

}
import { Component, OnInit } from '@angular/core';
import { FooterService } from './footer.service';
import { Cookie } from 'ng2-cookies/ng2-cookies';
import { Router, ActivatedRoute } from '@angular/router';
import Swal from 'sweetalert2';


@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.css']
})
export class FooterComponent implements OnInit {
  dropdownList = [];
  categoryArr: any[] = [];
  categoryId: any;
  docnowLocations: any;
  catId: any = 1;
  latitude: any;
  longitude: any;
  footerYear: any = new Date().getFullYear();


  constructor(
    private footerservice: FooterService,
    private router: Router
  ) {
this.latitude = Cookie.get('latitude');
  this.longitude = Cookie.get('longitude');
   }

  ngOnInit() {
    this.footerservice.searchDefaultData().subscribe(async res => {
      this.dropdownList = res.body['data'].categories
      this.dropdownList = res.body['data'].categories

      var cat = res.body['data'].categories
      this.categoryArr = cat
    })

    this.footerservice.findLocation().subscribe(async res => {
      this.docnowLocations = res.body['data'].locations

    })

  }

  searchdoctorlist(data) {
    console.log("data",data)
    Cookie.set('specialityId', data.toString());
    Cookie.set('categoryId', data.toString());
    const categoryData = { categoryId: data.toString(), latitude: this.latitude, longitude: this.longitude }
    console.log("categoryData",categoryData)
    if(categoryData.latitude == null || categoryData.longitude == null){
        Swal('Oops...', 'Please Select Your Location!', 'error')
    }else{
    let currentUrl = this.router.url;
    this.router.navigateByUrl('/', {skipLocationChange: true}).then(() => {
        this.router.navigate([currentUrl]);
    });
    }
  }


}

import { Component, OnInit } from '@angular/core';
import SwiperCore , {
  Navigation,
  Pagination,
  Scrollbar,
  A11y,
} from 'swiper/core';
SwiperCore.use([Navigation, Pagination, Scrollbar, A11y]);
declare var $;
@Component({
  selector: 'app-telehealth',
  templateUrl: './telehealth.component.html',
  styleUrls: ['./telehealth.component.css']
})
export class TelehealthComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

  onSwiper(swiper) {
    console.log(swiper);
  }
  onSlideChange() {
    console.log('slide change');
  }
 
  data: any = [{
    "parentName": "What do 'Telehealth' and 'Telemedicine' mean?",
    "childProperties":
      [
        { "propertyName": "Both terms are interchangeable and describe a way to deliver and receive healthcare by using internet at the convenience of a location of your choosing. In most cases, your practitioner will offer an option to video chat." }
      ]
  }, {
    "parentName": "What equipment do I need to do a Telehealth visit?",
    "childProperties":
      [
        { "propertyName": "Patients will need a mobile device with an integrated camera and microphone and an internet connection. If your device doesn't have a built-in microphone or webcam, you'll need to get those for a Telehealth call." }
      ]
  }, {
    "parentName": "I'm not tech-savvy. Is Telehealth right for me?",
    "childProperties":
      [
        { "propertyName": "Patients of all ages regardless of how tech-savvy they are can use the Telehealth platform successfully. The platform is just like making a facetime call on your mobile device" }
      ]
  }, {
    "parentName": "What conditions can I get treated for via Telehealth?",
    "childProperties":
      [
        { "propertyName": "Telehealth is currently being used to treat conditions across many different specialties. Check with your doctor to see what conditions they treat via Telehealth" }
      ]
  }, {
    "parentName": "I am a physician; do I need to train my staff and patients on how to use this technology?",
    "childProperties":
      [
        { "propertyName": "No. There is no training required to use the DocNow Telehealth platform. It is as simple as using a smart device." }
      ]
  }, {
    "parentName": "Do I need to download the DocNow App to use Telehealth?",
    "childProperties":
      [
        { "propertyName": "Yes. DocNow Telehealth is a proprietary solution and can only work on the DocNow mobile app. However, the practitioner is not required to download the DocNow mobile app to conduct a Telehealth call. They will manage the Telehealth visit from their PC or Laptop." }
      ]
  }

];
    ngAfterViewInit(){
      if (window.location.href.indexOf("telehealth") > -1) {
        $('nav').addClass('addShadow');
      }
      $('.stat-number').each(function () {
        var size = $(this).text().split(".")[1] ? $(this).text().split(".")[1].length : 0;
        $(this).prop('Counter', 0).animate({
           Counter: $(this).text()
        }, {
           duration: 5000,
           step: function (func) {
              $(this).text(parseFloat(func).toFixed(size));
           }
        });
     });
    }
    toggleAccordian(event, index) {
      const element = event.target;
      element.classList.toggle("active");
      if (this.data[index].isActive) {
        this.data[index].isActive = false;
      } else {
        this.data[index].isActive = true;
      }
      const panel = element.nextElementSibling;
      if (panel.style.maxHeight) {
        panel.style.maxHeight = null;
     
      } else {
        panel.style.maxHeight = panel.scrollHeight + "px";
      
      }
    }

}

import { Injectable } from '@angular/core';
import { environment } from '../../environments/environment';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Cookie } from 'ng2-cookies/ng2-cookies';
import { from } from 'rxjs';

@Injectable({
	providedIn: 'root'
})
export class BlogDetailsService {

	baseUrl = environment.baseUrl;

	constructor(private http: HttpClient) { }

	blogDetailsList(data) {
		const httpHeaders = new HttpHeaders({
			'Content-Type': 'application/json'
		});
		return this.http.post(this.baseUrl + '/user/detailBlogView', data, {
			headers: httpHeaders,
			observe: 'response'
		});
	}
}

import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import { AddaddressService } from './addaddress.service';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-addaddress',
  templateUrl: './addaddress.component.html',
  styleUrls: ['./addaddress.component.css']
})
export class AddaddressComponent implements OnInit {
  addressForm: FormGroup;
  submitted = false;
  profileData: any;

  constructor(
    private formBuilder: FormBuilder,
    private addaddressService: AddaddressService,
    private router: Router,
    private route: ActivatedRoute
  ) { }

  ngOnInit() {
    this.addressForm = this.formBuilder.group({
      userAddress: ['', [Validators.required,]],
      city: ['', [Validators.required,]],
      state: ['', [Validators.required,]],
      zipcode: ['', [Validators.required,]],


    });

  }
  get f() {
    return this.addressForm.controls;
  }
  onSubmit() {
    this.submitted = true;

    // stop here if form is invalid
    if (this.addressForm.invalid) {
      return;
    }
    const addressData = this.addressForm.value;
    const addressdata = { userAddress: addressData.userAddress, city: addressData.city, state: addressData.state, zipcode: addressData.zipcode }
    this.addaddressService.addressData(addressdata).subscribe(
      res => {
        const data = res.body['data']
        if (res.body['error'] === 'true') {
          Swal({
            title: 'Error',
            text: res.body['message'],
            type: 'error',
            confirmButtonText: 'Ok',
          });
        } else {
          window.location.href = 'https://app.docnowonline.com/docnow-enterprise/signupsuccess/index-1.html';
          this.router.navigate(['/'], { state: addressdata });

        }
      });

  }
}

import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import { UpdateprofileService } from './updateprofile.service';
import Swal from 'sweetalert2';



@Component({
  selector: 'app-updateprofile',
  templateUrl: './updateprofile.component.html',
  styleUrls: ['./updateprofile.component.css']
})
export class UpdateprofileComponent implements OnInit {

  loginForm: FormGroup;
    submitted = false;
    profileData:any;
  userName: any;


  constructor(
    private formBuilder: FormBuilder,
    private updateprofileService: UpdateprofileService,
    private router: Router,
    private route: ActivatedRoute

  ) { }

  ngOnInit() {
    
    this.loginForm = this.formBuilder.group({
      userName:[ '', [Validators.required, ]],
      gender: ['', ],
      email: ['', [Validators.required, Validators.email]],



  });
}
onSubmit(){ 
  const profileData = this.loginForm.value;
  // console.log(this.loginForm.value)
  const profiledata = { userName: profileData.userName,gender: 'male',email: profileData.email,image:'demo.png' }
  // console.log(profiledata)
    this.updateprofileService.profileData(profiledata).subscribe(
      res => {
        const data = res.body['data']
        if (res.body['error'] === 'true') {
          Swal({
            title: 'Error',
            text: res.body['message'],
            type: 'error',
            confirmButtonText: 'Ok',
          });
        } else {
          this.router.navigate(['/addaddress'],{state: profiledata});

        }
      });

  }

}

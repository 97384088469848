import { Component, OnInit, ViewChild, ElementRef, NgZone } from '@angular/core';
import { SearchService } from './search.service';
import { Router, ActivatedRoute } from '@angular/router';
import { NgxSpinnerService } from "ngx-spinner";
import { Cookie } from 'ng2-cookies/ng2-cookies';
import { MouseEvent, MapsAPILoader } from '@agm/core';
import { FormBuilder, FormGroup, Validators, FormControl } from '@angular/forms';


@Component({
  selector: 'app-search',
  templateUrl: './search.component.html',
  styleUrls: ['./search.component.css']
})
export class SearchComponent implements OnInit {

  page = 1;
  pages: any;
  // categoryId: any = 1;
  doctorId: Number = 1;
  doctorList: any;
  categoriesList: any;
  adminData: any;
  doctorVerifiedStatus: any;
  filterhide: any;
  latitude: any;
  longitude: any;
  sort: Number = 1;
  address: string;
  location: string;
  // latitude = Cookie.get('latitude');
  // longitude = Cookie.get('longitude');
  categoryId = Cookie.get('categoryId');
  searchForm: any;
  geoCoder: any;
  errormessage: String
  zoom: number = 8;
  @ViewChild('search')
  public searchElementRef: ElementRef;
  constructor(private searchService: SearchService,
    private router: Router,
    private formBuilder: FormBuilder,
    private mapsAPILoader: MapsAPILoader,
    private spinner: NgxSpinnerService,
    private ngZone: NgZone
  ) {
    this.adminData = this.router.getCurrentNavigation().extras.state;
    this.searchForm = this.formBuilder.group({
      location: ['', Validators.required]
    })
  }

  ngOnInit() {
    this.setCurrentLocation();
    this.spinner.show();
    if (this.adminData == undefined) {
      // console.log('1')
      this.spinner.hide();
      this.latitude= this.latitude || "33.7489954"
      this.longitude= this.longitude || "-84.3879824"
      this.filterDoctor();
    } else {
      if (this.adminData.isUrgentCare == 1) {
        this.filterhide = 'hide';
        this.filterUrgentDoctor();
      } else {
        this.categoryId = this.adminData.categoryId;
        this.latitude = this.adminData.latitude;
        this.longitude = this.adminData.longitude;
        this.filterhide = 'unHide';
        this.filterDoctor();
      }
    }
    this.searchService.categoryList().subscribe(
      res => {
        this.spinner.hide();
        this.categoriesList = res.body['data'].categories;
      },
      err => {
        this.spinner.hide();

        this.router.navigateByUrl('/serverError');
      }
    );
    this.mapsAPILoader.load().then(() => {
      this.setCurrentLocation();
      this.geoCoder = new google.maps.Geocoder;

      let autocomplete = new google.maps.places.Autocomplete(this.searchElementRef.nativeElement);
      autocomplete.addListener("place_changed", () => {
        this.ngZone.run(() => {
          //get the place result
          let place: google.maps.places.PlaceResult = autocomplete.getPlace();
          //set latitude, longitude and zoom
          this.latitude = place.geometry.location.lat();
          this.longitude = place.geometry.location.lng();
          Cookie.set('latitude', this.latitude)
          Cookie.set('longitude', this.longitude);
          this.errormessage = ""
          this.zoom = 4;
        });
      });
    });
  }
  private setCurrentLocation() {
    if ('geolocation' in navigator) {
      navigator.geolocation.getCurrentPosition((position) => {
        // console.log(position)
        this.latitude = position.coords.latitude;
        this.longitude = position.coords.longitude;
        Cookie.set('latitude', this.latitude)
        Cookie.set('longitude', this.longitude);
        this.zoom = 8;
        this.getAddress(this.latitude, this.longitude);
      });
    }
  }
  getAddress(latitude, longitude) {
    // console.log(latitude, longitude)
    this.geoCoder.geocode({ 'location': { lat: latitude, lng: longitude } }, (results, status) => {
      if (status === 'OK') {
        // console.log(results)
        if (results[0]) {
          this.zoom = 12;
          this.address = results[0].formatted_address;
          // console.log(this.address)
          this.location = this.address
          this.searchForm = new FormGroup({
            location: new FormControl(this.address)
          })
        } else {
          window.alert('No results found');
        }
      } else {
        window.alert('Geocoder failed due to: ' + status);
      }

    });
  }
  markerDragEnd($event: MouseEvent) {
    this.latitude = $event.coords.lat;
    this.longitude = $event.coords.lng;
    this.getAddress(this.latitude, this.longitude);
  }
  searchdoctor() {
    console.log("console", this.latitude, this.longitude)
    Cookie.set('latitude', this.latitude)
    Cookie.set('longitude', this.longitude);
    this.filterDoctor();
  }
  filterDoctor() {
    // console.log(this.categoryId,'cat')
    var filter = { categoryId: this.categoryId || '1', latitude: this.latitude, longitude: this.longitude, page: this.page, filter: JSON.stringify({ "normalDoctors": 0, "teleHealth": 0 }) }
    this.searchService.doctorListService(filter).subscribe(
      response => {
        this.spinner.hide();
      // console.log('responsebody',response.body)
        this.doctorList = response.body['data'].doctors;
      this.pages = response.body['data'].pageCount * 10;
      console.log('page',this.doctorList)
        // var categoryArr = this.categoriesList
        // categoryArr.forEach(function(data, index) {
        //   if (this.categoryId === data.categoryId) {
        //     data.status = true
        //   } else {
        //     data.status = false
        //   }
        // })
      },
      err => {
        this.spinner.hide();
        this.router.navigateByUrl('/serverError');
      }
    );
  }

  filterUrgentDoctor() {
    var filter = { categoryId: JSON.stringify(this.categoryId), page: this.page, filter: JSON.stringify({ "normalDoctors": 0, "teleHealth": 0, latitude: this.latitude, longitude: this.longitude }) }
    this.searchService.urgentCareDoctorList(filter).subscribe(
      response => {
        this.spinner.hide();
        this.doctorList = response.body['data'].doctors;
        this.pages = response.body['data'].pageCount * 10;
        // console.log(this.doctorList,'#####')
      },
      err => {
        this.spinner.hide();
        this.router.navigateByUrl('/serverError');
      }
    );
  }




  searchdoctorList() {
    const categoryData = { categoryId: this.categoryId }
    this.router.navigate(['/doctorprofile'], { state: categoryData });
  }
  doctorBooking(doctorId) {
    const doctorData = { doctorId: doctorId }
    this.router.navigate(['/booking'], { state: doctorData });
  }

  doctorProfile(doctorId) {
    const doctorData = { doctorId: doctorId }
    Cookie.set('doctorId', doctorId);
    // console.log(doctorData)
    this.router.navigate(['/doctorprofile'], { state: doctorData });
  }

  //filter
  doctorFilter(event) {
    this.spinner.show();
    //this.filterDoctor();
    this.categoryId = event.categoryId
    Cookie.set('categoryId', this.categoryId)
    var filter = { categoryId: JSON.stringify(this.categoryId), page: this.page, latitude: this.latitude, longitude: this.longitude, filter: JSON.stringify({ "normalDoctors": 0, "teleHealth": 0 }) }
    this.searchService.doctorListService(filter).subscribe(
      response => {
        this.spinner.hide();
        this.doctorList = response.body['data'].doctors;
      this.pages = response.body['data'].pageCount * 10;
      console.log('page',this.pages)
        // var categoryArr = this.categoriesList
        // categoryArr.forEach(function(data, index) {
        //   if (this.categoryId === data.categoryId) {
        //     data.status = true
        //   } else {
        //     data.status = false
        //   }
        // })
      },
      err => {
        this.spinner.hide();
        this.router.navigateByUrl('/serverError');
      }
    );
  }
  sortFilter(event) {
    this.sort = event
    this.spinner.show();
    //this.filterDoctor();
    // this.categoryId = event.categoryId
    var filter = { categoryId: this.categoryId, page: this.page, latitude: this.latitude, longitude: this.longitude, filter: JSON.stringify({ "payedDoctors": 0, "teleHealth": 0, "gender": 0 }), sort: this.sort }
    this.searchService.doctorListService(filter).subscribe(
      response => {
        this.spinner.hide();
        this.doctorList = response.body['data'].doctors;
        this.pages = response.body['data'].pageCount * 10;
        // var categoryArr = this.categoriesList
        // categoryArr.forEach(function(data, index) {
        //   if (this.categoryId === data.categoryId) {
        //     data.status = true
        //   } else {
        //     data.status = false
        //   }
        // })
      },
      err => {
        this.spinner.hide();
        this.router.navigateByUrl('/serverError');
      }
    );
  }
  genderFilter(event) {
    this.sort = event
    this.spinner.show();
    //this.filterDoctor();
    // this.categoryId = event.categoryId
    var filter = { categoryId: this.categoryId, page: this.page, latitude: this.latitude, longitude: this.longitude, filter: JSON.stringify({ "payedDoctors": 0, "teleHealth": 0, "gender": event }), sort: this.sort }
    this.searchService.doctorListService(filter).subscribe(
      response => {

        this.spinner.hide();
        this.doctorList = response.body['data'].doctors;
        this.pages = response.body['data'].pageCount * 10;
        // var categoryArr = this.categoriesList
        // categoryArr.forEach(function(data, index) {
        //   if (this.categoryId === data.categoryId) {
        //     data.status = true
        //   } else {
        //     data.status = false
        //   }
        // })
      },
      err => {
        this.spinner.hide();
        this.router.navigateByUrl('/serverError');
      }
    );
  }

  getDoctor(page) {
    this.page =page
    var filter = { categoryId: this.categoryId, latitude: this.latitude, longitude: this.longitude, page:JSON.stringify(this.page), filter: JSON.stringify({ "normalDoctors": 0, "teleHealth": 0 }) }
    this.searchService.doctorListService(filter).subscribe(
      response => {
        this.spinner.hide();
      console.log('responsebody',response.body)
        this.doctorList = response.body['data'].doctors;
      this.pages = response.body['data'].pageCount * 10;
      // console.log('page',this.pages)
      },
      err => {
        this.spinner.hide();
        this.router.navigateByUrl('/serverError');
      }
    );

   
  }
}

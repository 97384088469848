import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { VerifyotpService } from './verifyotp.service';
import { Router, ActivatedRoute } from '@angular/router';
import { Cookie } from 'ng2-cookies/ng2-cookies';
import Swal from 'sweetalert2';
import { NgxSpinnerService } from "ngx-spinner";
import { environment } from '../../environments/environment';
declare let gtag: Function;

import {
  AuthService,
  SocialUser,
  FacebookLoginProvider,
  GoogleLoginProvider
} from 'angular5-social-login';

@Component({
  selector: 'app-verifyotp',
  templateUrl: './verifyotp.component.html',
  styleUrls: ['./verifyotp.component.css']
})
export class VerifyotpComponent implements OnInit {
  appURL = environment.appURL;
  loginForm: FormGroup;
  submitted = false;
  private user: SocialUser;
  public authorized: boolean = false;
  name: any;
  adminData: any;
  otpNumber: any;


  constructor(
    private formBuilder: FormBuilder,
    private verifyotpService: VerifyotpService,
    private router: Router,
    private route: ActivatedRoute,
    private socialAuthService: AuthService,
    private spinner: NgxSpinnerService
  ) {
    this.adminData = this.router.getCurrentNavigation().extras.state;
  }
  public socialSignIn(socialPlatform: string) {
    // console.log(socialPlatform)
    let socialPlatformProvider;
    if (socialPlatform == "facebook") {
      socialPlatformProvider = FacebookLoginProvider.PROVIDER_ID;
    } else if (socialPlatform == "google") {
      socialPlatformProvider = GoogleLoginProvider.PROVIDER_ID;
    }

    this.socialAuthService.signIn(socialPlatformProvider).then(
      (userData) => {
        // console.log(socialPlatform + " sign in data : ", userData);
        this.name = userData.name
        //         var data = { firstName: userData.email,lastName: this.userData.lastName, lastName: this.userData.lastName,countryCode: this.adminData.countryCode,OTP: this.otpNumber}
        // console.log(data)
        // this.verifyotpService.login(data).subscribe(
        //   res => {
        //   console.log(res)
        //     const data = res.body['data']
        //     if (res.body['error'] === 'true') {
        //       Swal({
        //         title: 'Error',
        //         text: res.body['message'],
        //         type: 'error',
        //         confirmButtonText: 'Ok',
        //       });
        //     } else {
        //        Cookie.set('token', res.body['data'].token);
        //        Cookie.set('image', res.body['data'].image);
        //        Cookie.set('userName', res.body['data'].userName);
        //               this.router.navigate(['/index'],{state: data});

        //     }
        //   });
        // console.log(this.name)
        // Now sign-in with userData        
        if (userData != null) {
          this.authorized = true;
          this.user = userData;
        }
      }
    );
  }
  public signOut() {
    this.socialAuthService.signOut();
    this.authorized = false;
  }
  get f() { return this.loginForm.controls; }

  ngOnInit() {
    if (this.adminData == undefined) {
      this.router.navigate(['/login']);
    }
    this.loginForm = this.formBuilder.group({
      OTP: [''],

    });

  }
  // convenience getter for easy access to form fields

  onSubmit() {
    this.submitted = true;
    this.spinner.show();
    // stop here if form is invalid
    if (this.loginForm.invalid) {
      return;
    }
    if (this.adminData.type == 'socialLogin') {
      var data = { mobileNumber: this.adminData.mobileNumber, countryCode: this.adminData.countryCode, OTP: this.otpNumber }
      this.verifyotpService.verifySocialotp(data).subscribe(
        res => {
          if (res.body['error'] === 'true') {
            this.spinner.hide();
            Swal({
              title: 'Error',
              text: res.body['message'],
              type: 'error',
              confirmButtonText: 'Ok',
            });
          } else {
            // console.log("**********inside verify otp component")
            // console.log("adminData", this.adminData)
            var data = { email: this.adminData.email, socialToken: this.adminData.token, mobileNumber: this.adminData.mobileNumber, countryCode: this.adminData.countryCode, firstName: this.adminData.firstName, lastName: this.adminData.lastName, socialType: this.adminData.socialType }
            this.verifyotpService.socialSignupLogin(data).subscribe(
              res => {
                const data = res.body['data']

                if (res.body['error'] === 'true') {
                  this.spinner.hide();
                  Swal({
                    title: 'Error',
                    text: res.body['message'],
                    type: 'error',
                    confirmButtonText: 'Ok',
                  });
                } else {
                  window.location.href = 'https://app.docnowonline.com/docnow-enterprise/signupsuccess/index-1.html';
                  gtag('event', 'conversion', { 'send_to': 'AW-623317282/5hbyCJi35O8BEKKinKkC' });
                  Cookie.set('token', res.body['data'].token);
                  Cookie.set('image', res.body['data'].image);
                  Cookie.set('userName', res.body['data'].userName);
                  Cookie.set('id', res.body['data'].id);
                  this.spinner.hide();
                  this.router.navigate(['/user/profilesettings'], { state: data });
                }
              });
          }
        });

    } else {
      var data = { mobileNumber: this.adminData.mobileNumber, countryCode: this.adminData.countryCode, OTP: this.otpNumber }
      this.verifyotpService.login(data).subscribe(
        res => {
          this.spinner.hide();
          const data = res.body['data']
          // console.log('data',data)
          if (res.body['error'] === 'true') {
            Swal({
              title: 'Error',
              text: res.body['message'],
              type: 'error',
              confirmButtonText: 'Ok',
            });
          } else {
            Cookie.set('token', res.body['data'].token);
            Cookie.set('image', res.body['data'].image);
            Cookie.set('userName', res.body['data'].userName);
            this.spinner.hide();
            if (this.adminData.isNewUser == 1) {
              this.router.navigate(['/updateprofile'], { state: data });

            }
            else {
              // if (Cookie.get('bookingURL')) {
              // const doctorData = { doctorId: parseInt(Cookie.get('doctorId')) }

              // console.log("data", doctorData)
              // debugger
              //   this.router.navigate(['/doctorprofile'], { state: doctorData })
              //     .then(() => {
              //       window.location.reload();
              //     });
              // } else {
              this.router.navigate(['/home'], { state: data });
              window.location.replace(this.appURL);
              // }
            }
          }
        });

    }

  }
  onOtpChange(data) {
    this.otpNumber = data
  }
  resendotp() {
    var logindata = { mobileNumber: this.adminData.mobileNumber, countryCode: this.adminData.countryCode }
    this.verifyotpService.verifyotp(logindata).subscribe(
      res => {
        this.spinner.hide();
        const data = res.body['data']
        if (res.body['error'] === 'true') {
          Swal({
            title: 'Error',
            text: res.body['message'],
            type: 'error',
            confirmButtonText: 'Ok',
          });
        } else {
          this.otpNumber = ''
          this.router.navigate(['/verifyotp'], { state: logindata });

        }
      });
  }
}
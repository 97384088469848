import { Component, OnInit, ViewChild, ElementRef, NgZone } from '@angular/core';
import { MapsAPILoader, MouseEvent } from '@agm/core';
import { GoogleMapsAPIWrapper } from '@agm/core/services';
import { FormBuilder, FormGroup, Validators, FormControl } from '@angular/forms';
import { google } from "google-maps";
import { Cookie } from 'ng2-cookies/ng2-cookies';
declare const google: any;
import { Router, ActivatedRoute } from '@angular/router';
import * as $ from '../../assets/js/jquery.min.js';
import { NgxSpinnerService } from "ngx-spinner";
import Swal from 'sweetalert2';
import { LoadJsService } from '../layouts/load-js.service';
import { SeoService } from '../layouts/seo.service';
import { from, Observable, OperatorFunction } from 'rxjs';
import { debounceTime, distinctUntilChanged, map } from 'rxjs/operators';
import { UrgentCareService } from './urgent-care.service'
import { AngularMyDatePickerDirective, IAngularMyDpOptions, IMyDateModel, HeaderAction } from 'angular-mydatepicker';
declare var require: any;
let locationsObject = require('../../locations.json');

@Component({
	selector: 'app-urgent-care',
	templateUrl: './urgent-care.component.html',
	styleUrls: ['./urgent-care.component.css']
})
export class UrgentCareComponent implements OnInit {

	@ViewChild('dp') mydp: AngularMyDatePickerDirective;
	accessToken = Cookie.get('token');
	userName = Cookie.get('userName');
	image = Cookie.get('image');
	page = 1;
	pages: any;
	doctorId: Number = 1;
	doctorList: any;
	plusdoctorList: any
	hide: boolean;
	latitude: any;
	longitude: any;
	zoom: number;
	address: string;
	wikiItems: any[] = [];
	geoCoder: any;
	searchForm: any;
	speciality: any;
	blogsList: any;
	subscriptionData: any;
	directPayId: any;
	plusMemberId: any;
	categoryId: any;
	errormessage: String
	categoryArr: any[] = [];
	topSpeciality: any;
	recentlyVisitedDoctors: any;
	recentlyVisitedDoctorsShow: boolean = false;
	plus: any;
	memberId: any;
	date: any;
	lat: any;
	lng: any;
	catId: any = 1;
	footerYear: any = new Date().getFullYear();
	@ViewChild('search')
	public searchElementRef: ElementRef;
	location: string;
	docnowLocations: any;
	filteredcategory: any[];
	private deviceInfo;
	private isMobile: boolean = false;
	search: any
	google:any
	constructor(
		private mapsAPILoader: MapsAPILoader,
		private homeService: UrgentCareService,
		private ngZone: NgZone,
		private formBuilder: FormBuilder,
		private router: Router,
		private route: ActivatedRoute,
		private spinner: NgxSpinnerService,
		private jsObj: LoadJsService,
		private seoObj: SeoService
	) {
		this.searchForm = this.formBuilder.group({
			location: ['', Validators.required],
			speciality: ['', Validators.required]
		})
		
	};
	myDatePickerOptions: IAngularMyDpOptions = {
		// options here...
	}
	// model: IMyDateModel = null;
	// call the clearDate() function of the directive
	clearDate(): void {
		this.mydp.clearDate();
	}

	// call the isDateValid() function of the directive
	checkDateValidity(): void {
		let valid: boolean = this.mydp.isDateValid();
		console.log('Valid date in the input box: ', valid);
	}

	// header action examples
	clickPreviousBtn(): void {
		this.mydp.headerAction(HeaderAction.PrevBtnClick);
	}

	clickNextBtn(): void {
		this.mydp.headerAction(HeaderAction.NextBtnClick);
	}

	clickMonthBtn(): void {
		this.mydp.headerAction(HeaderAction.MonthBtnClick);
	}

	clickYearBtn(): void {
		this.mydp.headerAction(HeaderAction.YearBtnClick);
	}
	private loadSeoTags() {
		this.seoObj.setTitle('DocNow | Find a Doctor Near You | Book Doctors Online')
		this.seoObj.setDescription('Search for your doctor, right now with DocNow. Book an appointment with a physician nearby. Sign up to DocNow Plus and get incredible discounts on regular urgent care visits.')
		this.seoObj.setAuthor('DocNow LLC')
		this.seoObj.setKeyword('DocNow | Find a Doctor Near You | Book Doctors Online')
		this.seoObj.setOGTitle('DocNow | Find a Doctor Near You | Book Doctors Online')
		this.seoObj.setOGDescription('Search for your doctor, right now with DocNow. Book an appointment with a physician nearby. Sign up to DocNow Plus and get incredible discounts on regular urgent care visits.')
	}
	searchSpeciality(event) {
		// console.log('i am called', this.searchForm.value)
		const data = { name: this.searchForm.value.speciality }
		this.homeService.search(data).subscribe(async res => {
			this.speciality = res.body['data'].categories;
			// console.log('countries', this.speciality)
		})
	}
	dropdownList = [];
	selectedItems = [];
	dropdownSettings = {};

	public model: any;

	getDoctorFromLocation(page, latitude, longitude) {
		// console.log("data", page, latitude, longitude)
		var filter = { categoryId: '1', latitude: this.latitude, longitude: this.longitude, page: JSON.stringify(page), filter: JSON.stringify({ "normalDoctors": 0, "teleHealth": 0 }) }
		this.homeService.urgentCareDoctorList(filter).subscribe(
			response => {
				this.spinner.hide();
				this.doctorList = response.body['data'].doctors;
				this.pages = response.body['data'].pageCount * 10;
			},
			err => {
				this.spinner.hide();
				this.router.navigateByUrl('/serverError');
			}
		);


	}

	getDocnowPlusUrgentCareFromLocation(page, latitude, longitude) {
		// console.log("data", page, latitude, longitude)
		var filter = { categoryId: '1', latitude: this.latitude, longitude: this.longitude, page: JSON.stringify(page), filter: JSON.stringify({ "normalDoctors": 0, "teleHealth": 0 }) }
		this.homeService.plusUrgentCareDoctorList(filter).subscribe(
			response => {
				this.spinner.hide();
				this.plusdoctorList = response.body['data'].docnowPlusNetworkUrgentCare;
				console.log(this.plusdoctorList,'list')
				this.pages = response.body['data'].pageCount * 10;
			},
			err => {
				this.spinner.hide();
				this.router.navigateByUrl('/serverError');
			}
		);

	}


	getDoctor(page) {
		this.page = page
		var filter = { categoryId: '1', latitude: this.latitude, longitude: this.longitude, page: JSON.stringify(this.page), filter: JSON.stringify({ "normalDoctors": 0, "teleHealth": 0 }) }
		this.homeService.urgentCareDoctorList(filter).subscribe(
			response => {
				this.spinner.hide();
				// console.log('responsebody', response.body)
				this.doctorList = response.body['data'].doctors;
				this.pages = response.body['data'].pageCount * 10;
				// console.log('page', this.pages)
			},
			err => {
				this.spinner.hide();
				this.router.navigateByUrl('/serverError');
			}
		);


	}

	ngOnInit() {
		let currentSearchLocation = this.route.params['value'].location
		var self = this;
		if (currentSearchLocation) {
			locationsObject.forEach(function(data, index) {
				if (currentSearchLocation === data.location) {
					self.search = data.name
					self.latitude = data.latitude
					self.longitude = data.longitude
					Cookie.set('latitude', self.latitude)
					Cookie.set('longitude', self.longitude);
				}
			})
		}
		var latitude = Cookie.get('latitude')
		var longitude = Cookie.get('longitude');
		// console.log("page", this.page)
		this.getDoctorFromLocation(this.page, latitude, longitude)
		this.getDocnowPlusUrgentCareFromLocation(this.page, latitude, longitude)
		this.loadSeoTags()
		this.spinner.show();
		if (this.userName != null) {
			this.hide = true
		} else {
			this.hide = false
		}
		this.spinner.hide();



		if (this.accessToken == undefined) {
			this.directPayId = 'invalid'
			this.plusMemberId = 'invalid'
		}

		this.homeService.searchDefaultData().subscribe(async res => {
			this.dropdownList = res.body['data'].categories
			// console.log(this.dropdownList)
			var cat = res.body['data'].categories
			this.categoryArr = cat
			this.filteredcategory = this.categoryArr.map(data => {
				return data.categoryName
			})

		})

		this.homeService.findLocation().subscribe(async res => {
			this.docnowLocations = res.body['data'].locations
			// console.log(this.docnowLocations)
		})

		this.setCurrentLocation();
		this.spinner.show();
		this.homeService.blogList().subscribe(async res => {
			this.blogsList = res.body['data'].blogs;
			// console.log(this.blogsList)
			this.spinner.hide();
		},
			err => {
				this.spinner.hide();
				// this.router.navigateByUrl('/serverError');
			}
		);
		this.homeService.subscriptionData().subscribe(async res => {
			this.subscriptionData = res.body['data'].userSubscriptionDetails;
			// console.log(this.subscriptionData)
			localStorage.setItem("subscriptionData", this.subscriptionData[0])
			if (this.subscriptionData.length != 0) {
				this.directPayId = res.body['data'].userSubscriptionDetails[0].directPayId;
				this.plusMemberId = res.body['data'].userSubscriptionDetails[0].plusMemberId;
				localStorage.setItem("plusMemberId", this.plusMemberId)
				//  console.log(this.directPayId ,this.plusMemberId )
			}
			this.spinner.hide();
		},
			err => {
				this.spinner.hide();
				// this.router.navigateByUrl('/serverError');
			}
		);
		this.homeService.topSpecialityService().subscribe(async res => {
			this.topSpeciality = res.body['data'].topSpeciality;
			this.recentlyVisitedDoctors = res.body['data'].recentlyVisitedDoctors;
			// console.log(this.recentlyVisitedDoctors)
			if (this.recentlyVisitedDoctors.length > 0) {
				this.recentlyVisitedDoctorsShow = true
			}
			this.spinner.hide();
		},
			err => {
				this.spinner.hide();
				// this.router.navigateByUrl('/serverError');
			}
		);


		//load Places Autocomplete
		// this.mapsAPILoader.load().then(() => {
		//   this.setCurrentLocation();
		//   this.geoCoder = new google.maps.Geocoder;
		//   let autocomplete = new google.maps.places.Autocomplete(this.searchElementRef.nativeElement);
		//   autocomplete.addListener("place_changed", () => {
		//     this.ngZone.run(() => {
		//       //get the place result
		//       let place: google.maps.places.PlaceResult = autocomplete.getPlace();
		//       //set latitude, longitude and zoom
		//       this.latitude = place.geometry.location.lat();
		//       this.longitude = place.geometry.location.lng();
		//       Cookie.set('latitude', this.latitude)
		//       Cookie.set('longitude', this.longitude);
		//       console.log('location details', this.latitude, this.longitude)
		//       this.errormessage = ""
		//       this.zoom = 4;
		//     });
		//   });
		// });

		var arr = []

		this.dropdownSettings = {
			singleSelection: true,
			text: "Search Speciality",
			enableSearchFilter: true,
			classes: "form-control search-info form-group text",
			searchAutofocus: false,
			noDataLabel: "No Search Results Found",
			lazyLoading: true,
			enableFilterSelectAll: false,
			labelKey: 'categoryName',
			primaryKey: 'categoryId',
			searchBy: ["categoryName"],
		};


		Cookie.delete('categoryId');
		Cookie.delete('specialityName');
		// this.spinner.hide();
	}

	doctorBooking(doctorId) {
		const doctorData = { doctorId: doctorId }
		this.router.navigate(['/booking'], { state: doctorData });
	}

	doctorProfile(doctorId) {
		const doctorData = { doctorId: doctorId }
		Cookie.set('doctorId', doctorId);
		this.router.navigate(['/doctorprofile'], { state: doctorData });
	}



	searchdoctorlist(data) {
		// console.log("data",data)
		Cookie.set('specialityId', data.toString());
		Cookie.set('categoryId', data.toString());
		const categoryData = { categoryId: data.toString(), latitude: this.latitude, longitude: this.longitude }
		// console.log("categoryData",categoryData)
		if (categoryData.latitude == null || categoryData.longitude == null) {
			Swal('Oops...', 'Please Select Your Location!', 'error')
		} else {
			this.router.navigate(['/search'], { state: categoryData });
		}

	}
	DirectView(subscriptionData) {
		this.memberId = subscriptionData[0].directPayId;
		this.date = subscriptionData[0].directPayDate;

	}

	plusView(subscriptionData) {
		this.memberId = subscriptionData[0].plusMemberId;
		this.date = subscriptionData[0].plusMemberDate;
	}


	onItemSelect(item: any) {
		// console.log("item", item)
		this.categoryId = item.categoryId;
		Cookie.set('specialityId', item.categoryId);
		Cookie.set('categoryId', item.categoryId);
		Cookie.set('specialityName', item.categoryName);
	}
	OnItemDeSelect(item: any) {
	}
	onSelectAll(items: any) {
	}
	onDeSelectAll(items: any) {
	}

	// Get Current Location Coordinates
	private setCurrentLocation() {
		if ('geolocation' in navigator) {
			navigator.geolocation.getCurrentPosition((position) => {
				// console.log(position)
				this.latitude = position.coords.latitude;
				this.longitude = position.coords.longitude;
				Cookie.set('latitude', this.latitude)
				Cookie.set('longitude', this.longitude);
				this.zoom = 8;
				this.getAddress(this.latitude, this.longitude);
			});
		}
	}


	markerDragEnd($event: MouseEvent) {
		this.latitude = $event.coords.lat;
		this.longitude = $event.coords.lng;
		this.getAddress(this.latitude, this.longitude);
	}

	getAddress(latitude, longitude) {
		// console.log(latitude, longitude)
		let geoCoder = this.google.maps.Geocoder();
		this.geoCoder.geocode({ 'location': { lat: latitude, lng: longitude } }, (results, status) => {
			// if (status === 'OK') {
			if (status === this.google.maps.GeocoderStatus.OK) {
				// console.log(results)
				if (results[0]) {
					this.zoom = 12;
					this.address = results[0].formatted_address;
					// console.log(this.address)
					this.location = this.address
					this.searchForm = new FormGroup({
						location: new FormControl(this.address),
						speciality: new FormControl()
					})
				} else {
					window.alert('No results found');
				}
			} else {
				window.alert('Geocoder failed due to: ' + status);
			}

		});
	}


	searchdoctorList(id) {
		const categoryData = { categoryId: id, latitude: this.latitude, longitude: this.longitude }
		this.router.navigate(['/search'], { state: categoryData });


	}

	searchdoctor() {

		this.categoryId = Cookie.get('categoryId');
		if (this.latitude && this.longitude) {
			if (this.categoryId) {
				const categoryData = { categoryId: this.categoryId.toString(), latitude: this.latitude, longitude: this.longitude }
				this.router.navigate(['/search'], { state: categoryData });
			} else {
				this.errormessage = "Please pick one Specialty from the below list"
			}
		} else {
			this.errormessage = "Please pick one Location from the below list"
		}

	}

	topSpecialityClick(categoryId) {
		Cookie.set('categoryId', categoryId);
		if (this.latitude && this.longitude) {
			this.searchdoctor()
		} else {
			Swal('Oops...', 'Please Select Your Location!', 'error')
		}
	}


	onFocused(e) {
		// do something
	}

	//searchUrgentCareDoctor List
	searchUrgentCareDoctor() {
		const urgentCareData = { isUrgentCare: 1, latitude: this.latitude, longitude: this.longitude }
		this.router.navigate(['/urgentcare'], { state: urgentCareData });
	}

	//Doctor List
	findDoctor() {
		const normalDoctorData = { categoryId: '1', latitude: this.latitude, longitude: this.longitude }
		this.router.navigate(['/search'], { state: normalDoctorData });
	}

	createAccount() {
		this.router.navigate(['/register']);
	}

	directDoctor() {
		// const directDoctorData = { categoryId:'1',latitude: this.latitude, longitude : this.longitude }
		// this.router.navigate(['/directDoctor'], { state: directDoctorData });
		const hospitalData = { categoryId: '1', latitude: this.latitude, longitude: this.longitude }
		this.router.navigate(['/ChildrenHospitalLocation'], { state: hospitalData });
	}
	hospitallocation() {
		const hospitalData = { categoryId: '1', latitude: this.latitude, longitude: this.longitude }
		this.router.navigate(['/hospitallocation'], { state: hospitalData });
	}
	booking(id) {
		const doctorData = { doctorId: id }

		this.router.navigate(['/booking'], { state: doctorData });

	}
	doctorprofile(id) {
		const doctorData = { doctorId: id }

		this.router.navigate(['/doctorprofile'], { state: doctorData });
	}


	blogDetails(blogs) {
		this.router.navigate(['/blogDetails'], { state: blogs });

	}


	unsubscribePlus() {
		this.spinner.show();
		Swal({
			title: 'Please Enter Your Feedback',
			input: 'text',
			inputValidator: (value) => {
				if (value) {
					var data = { reason: value }
					this.homeService.UnsubscribePlus(data).subscribe(async res => {
						// console.log(res)
						Swal({
							title: 'success',
							text: 'Unsubscribe Successfully',
							type: 'success',
							showConfirmButton: false,
							timer: 1000
						});
						window.location.reload();

					});
				}
				return !value && 'You need to write something!'
			},
			type: "warning",
			showCancelButton: true,
			confirmButtonColor: '#DD6B55',
			confirmButtonText: 'Yes, I am sure!',
			cancelButtonText: "No, cancel it!",
			// closeOnConfirm: false,
			// closeOnCancel: false

		}),

			this.spinner.hide();
	}
	unsubscribePay() {
		this.spinner.show();

		Swal({
			title: 'Please Enter Your Feedback',
			input: 'text',
			inputValidator: (value) => {
				if (value) {
					var data = { reason: value }
					this.homeService.UnsubscribePay(data).subscribe(async res => {
						window.location.reload();

						// console.log(res)
						Swal({
							title: 'success',
							text: 'Unsubscribe Successfully',
							type: 'success',
							showConfirmButton: false,
							timer: 1000
						});

					});
				}
				return !value && 'You need to write something!'
			},
			type: "warning",
			showCancelButton: true,
			confirmButtonColor: '#DD6B55',
			confirmButtonText: 'Yes, I am sure!',
			cancelButtonText: "No, cancel it!",
			// closeOnConfirm: false,
			// closeOnCancel: false

		}),

			this.spinner.hide();




	}
	login() {
		this.router.navigate(['/login']);

	}
	blog() {
		this.router.navigate(['/blogList']);

	}
	logout() {
		this.spinner.show();
		// this.homeService.logout().subscribe(
		//   async response => {
		// this.spinner.hide();
		//     const data = response.body['data']
		//     if (response.body['error'] === 'true') {
		//     }
		//     else {
		Cookie.deleteAll('/');
		Cookie.delete('token');
		Cookie.delete('image');
		Cookie.delete('userName');
		Cookie.delete('latitude');
		Cookie.delete('latitude');
		Cookie.delete('doctorId');
		localStorage.clear();
		sessionStorage.clear();
		this.spinner.hide();
		this.router.navigateByUrl('/login');
		// }
		// }
		// );

	}

}

import { Component, OnInit } from '@angular/core';
import { WalletService } from '../wallet/wallet.service';
import { Router } from '@angular/router';
import { NgxSpinnerService } from "ngx-spinner";
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { AccountdetailsService } from '../accountdetails/accountdetails.service';
import Swal from 'sweetalert2';
import { Cookie } from 'ng2-cookies/ng2-cookies';
declare let gtag: Function;

@Component({
  selector: 'app-plus',
  templateUrl: './plus.component.html',
  styleUrls: ['./plus.component.css']
})
export class PlusComponent implements OnInit {
  cardForm: FormGroup;
  walletBalance: any;
  walletTransaction: any;
  pages: number;
  page = 1;
  submitted: boolean;
  cardList: any;
  plan: string;
  days: string;
  accessToken: any;
  amount: string;
  constructor(
    private WalletService: WalletService,
    private formBuilder: FormBuilder,
    private router: Router,
    private spinner: NgxSpinnerService,
    private AccountdetailsService: AccountdetailsService,
  ) {
    this.cardForm = this.formBuilder.group({
      amount: ['', [Validators.required,]],
      cardNumber: ['']
      // expiry: ['', [Validators.required, Validators.maxLength(16)]],
      // cvc: ['', [Validators.required, Validators.maxLength(16)]],
    }, {
    });
  }
  // convenience getter for easy access to form fields
  get f() {
    return this.cardForm.controls;
  }
  ngOnInit() {

    this.spinner.show();

    this.AccountdetailsService.listCard().subscribe(
      response => {
        this.spinner.hide();
        this.cardList = response.body['data'];
      },
      err => {
        this.spinner.hide();
        this.router.navigateByUrl('/serverError');
      }
    );
    var data = { page: 1 }
    this.WalletService.myWallet().subscribe(
      response => {

        this.spinner.hide();
        this.walletBalance = response.body['data'].walletBalance;
        this.walletTransaction = response.body['data'].walletTransaction;
        this.pages = response.body['data'].pageCount * 10;
      },
      err => {
        this.spinner.hide();
        this.router.navigateByUrl('/serverError');
      }
    );
  }
  onSubmit() {
    this.submitted = true;
    this.spinner.show();


    // stop here if form is invalid
    if (this.cardForm.invalid) {
      this.spinner.hide();
      return;
    }
    var cardData = this.cardForm.value;
    var data = { amount: cardData.amount, source: cardData.cardNumber, days: this.days, plan: this.plan }
    this.WalletService.addplus(data).subscribe(
      response => {

        this.spinner.hide();
        if (response.body['error'] == true) {
          Swal({
            title: 'Error',
            text: response.body['message'],
            type: 'error',
            confirmButtonText: 'Ok',
          });
        } else {
          Swal({
            title: 'success',
            text: 'subscribe Successfully',
            type: 'success',
            showConfirmButton: false,
            timer: 1000
          });
          window.location.href = 'https://app.docnowonline.com/docnow-enterprise/plusplansuccess/index.html';
          gtag('event', 'conversion', {
            'send_to': 'AW-623317282/MuZKCKKQ4-8BEKKinKkC',
            'transaction_id': ''
          });
          this.spinner.hide();
          // window.location.reload()
          // this.router.navigate(['/home']);



        }

      },
      err => {

        this.spinner.hide();
        this.router.navigateByUrl('/serverError');
      }
    );
    // display form values on success
  }
  addCard() {
    this.spinner.hide();
    const cardData = { addcardview: true }
    this.router.navigate(['/user/payment'], { state: cardData });
  }

  oneMonthPlane1() {
    this.spinner.show();

    if (this.cardList == '') {
      this.spinner.hide();
      this.router.navigateByUrl('/user/payment');

    }

    this.accessToken = Cookie.get('token');
    if (this.accessToken == undefined) {
      this.spinner.hide();
      Swal({
        title: '',
        text: 'To buy your plan for DocNow Plus please log in to your existing account or sign up',
        type: 'error',
        confirmButtonText: 'Ok',
      });
      this.router.navigateByUrl('/login');
    }
    this.spinner.hide();

    this.amount = '11.99';
    this.days = '28'
    this.plan = 'individual'
    this.cardForm = new FormGroup({
      amount: new FormControl(this.amount),
      cardNumber: new FormControl(),

    })

  }

  sixMonthPlane1() {
    this.spinner.show();

    if (this.cardList == '') {
      this.spinner.hide();
      this.router.navigateByUrl('/user/payment');
    }

    this.accessToken = Cookie.get('token');
    if (this.accessToken == undefined) {
      this.spinner.hide();
      Swal({
        title: '',
        text: 'To buy your plan for DocNow Plus please log in to your existing account or sign up',
        type: 'error',
        confirmButtonText: 'Ok',
      });
      this.router.navigateByUrl('/login');
    }
    this.spinner.hide();

    this.amount = '17.99';
    this.days = '366'
    this.plan = 'family'
    this.cardForm = new FormGroup({
      amount: new FormControl(this.amount),
      cardNumber: new FormControl(),

    })

  }

  oneMonthPlane2() {
    this.spinner.show();

    if (this.cardList == '') {
      this.spinner.hide();
      this.router.navigateByUrl('/user/payment');

    }

    this.accessToken = Cookie.get('token');
    if (this.accessToken == undefined) {
      this.spinner.hide();
      Swal({
        title: '',
        text: 'To buy your plan for DocNow Plus please log in to your existing account or sign up',
        type: 'error',
        confirmButtonText: 'Ok',
      });
      this.router.navigateByUrl('/login');
    }
    this.spinner.hide();

    this.amount = '59.94';
    this.days = '183'
    this.plan = 'individual'
    this.cardForm = new FormGroup({
      amount: new FormControl(this.amount),
      cardNumber: new FormControl(),

    })

  }

  sixMonthPlane2() {
    this.spinner.show();

    if (this.cardList == '') {
      this.spinner.hide();
      this.router.navigateByUrl('/user/payment');

    }

    this.accessToken = Cookie.get('token');
    if (this.accessToken == undefined) {
      this.spinner.hide();
      Swal({
        title: '',
        text: 'To buy your plan for DocNow Plus please log in to your existing account or sign up',
        type: 'error',
        confirmButtonText: 'Ok',
      });
      this.router.navigateByUrl('/login');
    }
    this.spinner.hide();

    this.amount = '89.94';
    this.days = '183'
    this.plan = 'family'
    this.cardForm = new FormGroup({
      amount: new FormControl(this.amount),
      cardNumber: new FormControl(),

    })

  }
}

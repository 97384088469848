import { Component, OnInit } from '@angular/core';
declare var $;
@Component({
  selector: 'app-blog',
  templateUrl: './blog.component.html',
  styleUrls: ['./blog.component.css']
})
export class BlogComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }
  ngAfterViewInit(){
    if (window.location.href.indexOf("blogs") > -1) {
      $('nav').addClass('addShadow');
    }
  }
}

import { Injectable, Inject } from '@angular/core';
import { Meta, Title } from '@angular/platform-browser';
import { DOCUMENT } from '@angular/common';

@Injectable({
	providedIn: 'root'
})
export class SeoService {

	constructor(private title: Title,
		private meta: Meta,
		@Inject(DOCUMENT) private doc) { }

	setTitle(string) {
		this.title.setTitle(string);
	}

	setDescription(string) {
		this.meta.addTag({ name: 'description', content: string });
	}

	updateDescription(string) {
		this.meta.updateTag({ name: 'description', content: string });
	}

	setAuthor(string) {
		this.meta.updateTag({ name: 'author', content: string });
	}

	setKeyword(string) {
		this.meta.updateTag({ name: 'keywords', content: string });
	}

	setOGTitle(string) {
		this.meta.updateTag({ property: 'og:title', content: string })
	}

	setOGDescription(string) {
		this.meta.updateTag({ property: 'og:description', content: string })
	}

	setRobotsIndex() {
		this.meta.addTag({ name: 'robots', content: 'index, follow' });
	}

	createLinkForCanonicalURL() {
		let link: HTMLLinkElement = this.doc.createElement('link');
		link.setAttribute('rel', 'canonical');
		this.doc.head.appendChild(link);
		link.setAttribute('href', this.doc.URL);
	}


}

import { Injectable } from '@angular/core';
import { environment } from '../../environments/environment';
import { HttpClient, HttpHeaders } from '@angular/common/http';


@Injectable({
  providedIn: 'root'
})

export class SearchService {

  baseUrl = environment.baseUrl;

  constructor(private http: HttpClient) { }

  doctorListService(filter) {
    // console.log(filter)
    const httpHeaders = new HttpHeaders({
      'Content-Type': 'application/json'
    });

    return this.http.post(this.baseUrl + '/user/listDoctors', filter, {

      headers: httpHeaders,
      observe: 'response'
    });
  }

  urgentCareDoctorList(filter) {
    // console.log(filter)
    const httpHeaders = new HttpHeaders({
      'Content-Type': 'application/json'
    });

    return this.http.post(this.baseUrl + '/user/listUrgentCare', filter, {

      headers: httpHeaders,
      observe: 'response'
    });
  }

  categoryList() {
    const httpHeaders = new HttpHeaders({
      'Content-Type': 'application/json'
    });

    return this.http.get(this.baseUrl + '/user/getAllCategory', {

      headers: httpHeaders,
      observe: 'response'
    });
  }
}




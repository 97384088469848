import { Component, OnInit } from '@angular/core';
declare var $;
@Component({
  selector: 'app-emrintegration',
  templateUrl: './emrintegration.component.html',
  styleUrls: ['./emrintegration.component.css']
})
export class EmrintegrationComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }
  
  data: any = [{
    "parentName": "Why do I need this integration service?",
    "childProperties":
      [
        { "propertyName": "The integration service will make real time changes to your calendar without the need of managing two different systems. Your EHR/EMR system will remain the only true system of record that will feed into the DocNow appointment booking platform." }
      ]
  }, {
    "parentName": "Do you recommend an EHR/EMR system to be used by your platform?",
    "childProperties":
      [
        { "propertyName": "No. We work with all the major EHR/EMR systems in the market. You could choose any EHR/EMR system based on the requirements of your practice." }
      ]
  }, {
    "parentName": "Is there an extra cost for the DocNow EHR/EMR integration services?",
    "childProperties":
      [
        { "propertyName": "No. There is no extra cost associated with providing this service. It is part of the entire DocNow platform including several other features and functionalities." }
      ]
  }

];
    ngAfterViewInit(){
      if (window.location.href.indexOf("emr-ehr-integration") > -1) {
        $('nav').addClass('addShadow');
      }
    }
    toggleAccordian(event, index) {
      const element = event.target;
      element.classList.toggle("active");
      if (this.data[index].isActive) {
        this.data[index].isActive = false;
      } else {
        this.data[index].isActive = true;
      }
      const panel = element.nextElementSibling;
      if (panel.style.maxHeight) {
        panel.style.maxHeight = null;
      } else {
        panel.style.maxHeight = panel.scrollHeight + "px";
      }
    }
}

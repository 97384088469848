import { Injectable } from '@angular/core';
import { environment } from '../../environments/environment';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Cookie } from 'ng2-cookies/ng2-cookies';

@Injectable({
  providedIn: 'root'
})

export class InsuranceService {

  baseUrl = environment.baseUrl;
  accessToken = Cookie.get('token');
  constructor(private http: HttpClient) { }

  getProfile() {
    const httpHeaders = new HttpHeaders({
      'Content-Type': 'application/json',
      'authorization': this.accessToken,
      'role': 'user'
    });

    return this.http.get(this.baseUrl + '/user/getProfile', {

      headers: httpHeaders,
      observe: 'response'
    });
  }
  fileUpload(image: File) {
    const httpHeaders = new HttpHeaders({
      // 'Content-Type': 'application/json',
      // 'authorization': this.accessToken,
      // 'role': 'user'
    });

    const formdata: FormData = new FormData();
    formdata.append('image', image, image.name);
    return this.http.post(this.baseUrl + '/user/imageUpload', formdata, {

      headers: httpHeaders,
      observe: 'response'
    });

  }

  familyMembers() {
    const httpHeaders = new HttpHeaders({
      'Content-Type': 'application/json',
      'authorization': this.accessToken,
      'role': 'user',
    });
    return this.http.get(this.baseUrl + '/user/getMyFamilyMembers', {
      headers: httpHeaders,
      observe: 'response'
    });
  }

  addInsurance(data) {
    const httpHeaders = new HttpHeaders({
      'role': 'user',
      'Content-Type': 'application/json',
      'authorization': this.accessToken,
    });

    return this.http.post(this.baseUrl + '/user/addMyInsuranceDetails', data, {

      headers: httpHeaders,
      observe: 'response'
    });


  }
}

import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { LoginService } from './login.service';
import { Router, ActivatedRoute } from '@angular/router';
import { Cookie } from 'ng2-cookies/ng2-cookies';
import Swal from 'sweetalert2';
import { NgxSpinnerService } from "ngx-spinner";

import {
  AuthService,
  SocialUser,
  FacebookLoginProvider,
  GoogleLoginProvider
} from 'angular5-social-login';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})
export class LoginComponent implements OnInit {
  loginForm: FormGroup;
  submitted = false;
  private user: SocialUser;
  public authorized: boolean = false;
  name: any;
  userData: any;

  constructor(
    private formBuilder: FormBuilder,
    private loginService: LoginService,
    private router: Router,
    private route: ActivatedRoute,
    private socialAuthService: AuthService,
    private spinner: NgxSpinnerService
  ) { }

  public socialSignIn(socialPlatform: string) {
    let socialPlatformProvider;
    if (socialPlatform == "facebook") {
      socialPlatformProvider = FacebookLoginProvider.PROVIDER_ID;
    } else if (socialPlatform == "google") {
      socialPlatformProvider = GoogleLoginProvider.PROVIDER_ID;
    }

    this.socialAuthService.signIn(socialPlatformProvider).then(
      (userSocialData) => {
        this.userData = userSocialData
        this.name = this.userData.name
        this.userData.socialType = socialPlatform
        // console.log(socialPlatform + " sign in data : ", this.userData);
        var req = { email: this.userData.email, socialToken: this.userData.token, socialType: socialPlatform }
        this.loginService.socialLogin(req).subscribe(
          res => {
            const data = res.body['data']
            if (res.body['error'] === 'true') {
              Swal({
                title: 'Error',
                text: res.body['message'],
                type: 'error',
                confirmButtonText: 'Ok',
              });
            } else {
              if (res.body['data'].token != null) {
                Cookie.set('token', res.body['data'].token);
                Cookie.set('image', res.body['data'].image);
                Cookie.set('userName', res.body['data'].userName);
                Cookie.set('id', res.body['data'].id);
                this.router.navigate(['/']);
              } else {
                this.router.navigate(['/socialsignup'], { state: this.userData });
              }
            }
          });
        // Now sign-in with userData        
        if (this.userData != null) {
          this.authorized = true;
          this.user = this.userData;
        }
      }
    );
  }

  public signOut() {
    this.socialAuthService.signOut();
    this.authorized = false;
  }

  ngOnInit() {
    this.loginForm = this.formBuilder.group({
      mobileNumber: ['', Validators.required],
      countryCode: ['1', Validators.required]
    });

  }
  // convenience getter for easy access to form fields
  get f() { return this.loginForm.controls; }

  onSubmit() {
    this.submitted = true;
    this.spinner.show();
    const loginData = this.loginForm.value;

    // stop here if form is invalid
    if (this.loginForm.invalid) {
      this.spinner.hide();
      return;
    }

    const loginSaveData = this.loginForm.value;
    var logindata = { mobileNumber: loginData.mobileNumber, countryCode: loginData.countryCode }
    this.loginService.login(logindata).subscribe(
      res => {
        this.spinner.hide();
        const data = res.body['data']
        if (res.body['error'] === 'true') {
          Swal({
            title: 'Error',
            text: res.body['message'],
            type: 'error',
            confirmButtonText: 'Ok',
          });
        } else {
          // Cookie.set('token', res.body['data'].token);
          // Cookie.set('image', res.body['data'].image);
          // Cookie.set('userName', res.body['data'].userName);
          this.spinner.hide();
          var logindata = { mobileNumber: loginData.mobileNumber, countryCode: loginData.countryCode, isNewUser: data.isNewUser }
          this.router.navigate(['/verifyotp'], { state: logindata });
        }
      });

  }
}
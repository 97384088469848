import { Injectable } from '@angular/core';
import { environment } from '../../environments/environment';
import { HttpClient, HttpHeaders } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})

export class RegisterService {

  baseUrl = environment.baseUrl;

  constructor(private http: HttpClient) { }

  register(apiData) {

    const httpHeaders = new HttpHeaders({
      'Content-Type': 'application/json'
    });

    return this.http.post(this.baseUrl + '/user/register', apiData, {
      headers: httpHeaders,
      observe: 'response'
    });
  }

  logout() {
    sessionStorage.clear();
  }

}

import { Component, OnInit, ViewChild, ElementRef,NgZone } from '@angular/core';
import { FormBuilder, FormGroup, Validators, FormControl } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import { ProfileupdateService } from './profileupdate.service';
import Swal from 'sweetalert2';
import { NgxSpinnerService } from "ngx-spinner";
import { ChartDataSets, ChartOptions } from 'chart.js';
import { Label, Color } from 'ng2-charts';
import { HomeService } from '../home/home.service';
import { Chart } from 'chart.js';
import { MouseEvent, MapsAPILoader } from '@agm/core';
import { Cookie } from 'ng2-cookies/ng2-cookies';



@Component({
  selector: 'app-profileupdate',
  templateUrl: './profileupdate.component.html',
  styleUrls: ['./profileupdate.component.css']
})
export class ProfileupdateComponent implements OnInit {
  profileForm: FormGroup;
  bmiForm: FormGroup;
  submitted = false;
  registerData: any;
  userName: any;
  mobileNumber: any;
  email: any; 
  subscriptionData: any;
  gender: any;
  userAddress: any;
  city: any;
  address: string;
  state: any;
  zipcode: any;
  bloodGroup: any;
  DOB: any;
  image: any;
  formName: string;
  selectedImage: any;
  url: string | ArrayBuffer;
  familyMember: any;
  insuranceCarier: any;
  insuranceType: any;
  firstName: any;
  lastName: any;
  validity: any;
  insuranceImage1: any;
  memberId: any;
  plusStatus: boolean = false;
  date: any;
  insuranceImage2: any;
  insuranceData: number;
  chart:any = [];
  location: string;
  plus:any;
  latitude: any;
  geoCoder: any;
  longitude: any;
  searchForm: any;
  zoom: number = 8;
  errormessage: String
  @ViewChild('myCanvas') myCanvas: ElementRef;
  @ViewChild('search')
  public searchElementRef: ElementRef;
    public context: CanvasRenderingContext2D;
  public lineChartData = [
    // { data: [65, 59, 80, 81, 56, 55, 40], label: 'Series A' },
  ];
  public lineChartLabels: Label[] = ['January', 'February', 'March', 'April', 'May', 'June', 'July'];
  // public lineChartOptions: (ChartOptions & { annotation: any }) = {
  //   responsive: true,
  // };
  public lineChartColors: Color[] = [
    {
      borderColor: 'black',
      backgroundColor: 'rgba(255,0,0,0.3)',
    },
  ];
  // public lineChartLegend = true;
  public lineChartLegend: any[] = [
    { legend: { display: true  } } ,
    {  labels: { fontColor: 'black' }}
  ];
  public lineChartType = 'line';
  public lineChartPlugins = [];
  bmiHistory: any;
  BMI: any;
  docCard: any;
  doctorList: any;
  noUrgent: any;

  constructor(
    private formBuilder: FormBuilder,
    private ProfileupdateService: ProfileupdateService,
    private router: Router,
    private route: ActivatedRoute,
    private homeService: HomeService,
    private spinner: NgxSpinnerService,
    private mapsAPILoader: MapsAPILoader,
    private ngZone: NgZone
  ) {
    this.searchForm = this.formBuilder.group({
      location: ['', Validators.required]
    })
    this.profileForm = this.formBuilder.group({
      userName: ['', Validators.required,],
      mobileNumber: ['', Validators.required],
      countryCode: ['', Validators.required],
      email: ['', Validators.required],
      image: ['', Validators.required],
      gender: ['', Validators.required],
      userAddress: ['', Validators.required],
      userCity: ['', Validators.required],
      userState: ['', Validators.required],
      zipcode: ['', Validators.required],
      bloodGroup: ['', Validators.required],
      plusStatus: ['', Validators.required],
      DOB: ['', Validators.required]
    });
    this.bmiForm = this.formBuilder.group({
      weight: ['', [Validators.required,]],
      height: ['', [Validators.required,]]
    }, {
      });
  }
  ngAfterViewInit() {
    window.scroll(0,0);
    setTimeout(() => {
      console.log(this.myCanvas.nativeElement.innerText);
    }, 1000);
  }
  ngOnInit() {
    window.scroll(0,0);
    this.setCurrentLocation();
    this.docnowplus();
    this.mapsAPILoader.load().then(() => {
      this.setCurrentLocation();
      this.geoCoder = new google.maps.Geocoder;

      let autocomplete = new google.maps.places.Autocomplete(this.searchElementRef.nativeElement);
      autocomplete.addListener("place_changed", () => {
        this.ngZone.run(() => {
          //get the place result
          let place: google.maps.places.PlaceResult = autocomplete.getPlace();
          //set latitude, longitude and zoom
          this.latitude = place.geometry.location.lat();
          this.longitude = place.geometry.location.lng();
          Cookie.set('latitude', this.latitude)
          Cookie.set('longitude', this.longitude);
          this.errormessage = ""
          this.zoom = 4;
        });
      });
    });
    this.spinner.show();

    this.ProfileupdateService.bmiHistory().subscribe(
      response => {
        this.spinner.hide();
        this.bmiHistory = response.body['data'];
      
        var result = [];
        this.bmiHistory.forEach(function (key) {
          
          result.push(key.result);
        });
        
        var test = { data: result }
        this.context = (<HTMLCanvasElement>this.myCanvas.nativeElement).getContext('2d');
        this.chart = new Chart(this.context,{
          type: 'line',
          data:{
            labels:result,
            datasets: [
                  
              {
                  data:result,
                  borderColor:'#3cba9f',
                  fill:false
              }
              
              ]
          },
          options: {
            
            legend: {
                
                display: false
            },
            scales:{
              xAxes:[{
                display:true
              }],
              yAxes:[{
                display:true
              }]
            }

        }
        })

      },
      err => {
        this.spinner.hide();
        this.router.navigateByUrl('/serverError');
      }
    );

    this.homeService.subscriptionData().subscribe(async res => {
      
      this.subscriptionData = res.body['data'].userSubscriptionDetails;
      if (this.subscriptionData.length !=0 && this.subscriptionData[0].plusMemberId!='invalid') {
        this.plusStatus = true
        this.memberId = this.subscriptionData[0].plusMemberId;
        this.date = this.subscriptionData[0].plusMemberDate;
      }
      this.spinner.hide();
    },
      err => {
      this.spinner.hide();
        // this.router.navigateByUrl('/serverError');
      }
    );

    this.ProfileupdateService.getProfile().subscribe(
      res => {
       
        this.spinner.hide();

        this.userName = res.body['data'].profileDetails[0].userName;
        this.mobileNumber = res.body['data'].profileDetails[0].mobileNumber;
        this.email = res.body['data'].profileDetails[0].email;
        this.gender = res.body['data'].profileDetails[0].gender;
        this.userAddress = res.body['data'].profileDetails[0].userAddress;
        this.city = res.body['data'].profileDetails[0].userCity;
        this.state = res.body['data'].profileDetails[0].userState;
        this.zipcode = res.body['data'].profileDetails[0].zipcode;
        this.bloodGroup = res.body['data'].profileDetails[0].bloodGroup;
        this.DOB = res.body['data'].profileDetails[0].DOB;
        this.url = res.body['data'].profileDetails[0].image;
        if (res.body['data'].insuranceDetails.length != 0) {
          this.insuranceData = 0;
          this.insuranceCarier = res.body['data'].insuranceDetails[0].insuranceCarier;
          this.insuranceType = res.body['data'].insuranceDetails[0].insuranceType;
          this.firstName = res.body['data'].insuranceDetails[0].firstName;
          this.lastName = res.body['data'].insuranceDetails[0].lastName;
          this.memberId = res.body['data'].insuranceDetails[0].memberId;
          this.validity = res.body['data'].insuranceDetails[0].validity;
          this.insuranceImage1 = res.body['data'].insuranceDetails[0].insuranceImage1;
          this.insuranceImage2 = res.body['data'].insuranceDetails[0].insuranceImage2;
        } else {
          this.insuranceData = 1;
        };

        this.formName = 'Edit Profile';
        this.profileForm = new FormGroup({
          userName: new FormControl(this.userName),
          mobileNumber: new FormControl(this.mobileNumber),
          email: new FormControl(this.email),
          gender: new FormControl(this.gender),
          userAddress: new FormControl(this.userAddress),
          city: new FormControl(this.city),
          state: new FormControl(this.state),
          zipcode: new FormControl(this.zipcode),
          bloodGroup: new FormControl(this.userName),
        })
      },

    );
    this.ProfileupdateService.familyMembers().subscribe(
      response => {
        this.spinner.hide();
        this.familyMember = response.body['data'];

      },
      err => {
        this.spinner.hide();
        this.router.navigateByUrl('/serverError');
      }
    );

  }
  private setCurrentLocation() {
    if ('geolocation' in navigator) {
      navigator.geolocation.getCurrentPosition((position) => {
        // console.log(position)
        this.latitude = position.coords.latitude;
        this.longitude = position.coords.longitude;
        Cookie.set('latitude', this.latitude)
        Cookie.set('longitude', this.longitude);
        this.zoom = 8;
        this.getAddress(this.latitude, this.longitude);
      });
    }
  }
  getAddress(latitude, longitude) {
    // console.log(latitude, longitude)
    this.geoCoder.geocode({ 'location': { lat: latitude, lng: longitude } }, (results, status) => {
      if (status === 'OK') {
        // console.log(results)
        if (results[0]) {
          this.zoom = 12;
          this.address = results[0].formatted_address;
          // console.log(this.address)
          this.location = this.address
          this.searchForm = new FormGroup({
            location: new FormControl(this.address)
          })
        } else {
          window.alert('No results found');
        }
      } else {
        window.alert('Geocoder failed due to: ' + status);
      }

    });
  }

  docnowplus(){
    var data = {latitude: this.latitude, longitude: this.longitude}

    this.ProfileupdateService.DocnowPlusDetails(data).subscribe(async res=>{
      console.log(res.body,'doccard');
      this.docCard = res.body['data'].docnowPlusCard;
      this.doctorList = res.body['data'].docnowPlusNetworkUrgentCare;
      this.noUrgent = res.body['data'].docnowPlusNetworkUrgentCare.length
      console.log(this.noUrgent,'noUrgent');
     
    });
  }
  doctorBooking(doctorId) {
    const doctorData = { doctorId: doctorId }

    this.router.navigate(['/booking'], { state: doctorData });
  }
  doctorProfile(doctorId) {
    const doctorData = { doctorId: doctorId }
    Cookie.set('doctorId', doctorId);
    this.router.navigate(['/doctorprofile'], { state: doctorData });
  }
  handleFileInput(file: FileList) {

    this.selectedImage = file.item(0);
    // console.log(this.selectedImage)
    const reader = new FileReader();

    reader.readAsDataURL(this.selectedImage); // read file as data url

    reader.onload = (event) => { // called once readAsDataURL is completed
      this.url = reader.result;
      // console.log(this.url)

      this.ProfileupdateService.fileUpload(this.selectedImage).subscribe(
        res => {
          // console.log(res)
        }
      )
    };

  }

  onSubmit() {
    this.spinner.show();

    // console.log(this.profileForm)
    this.submitted = true;
    // stop here if form is invalid
    if (this.profileForm.invalid) {
      return;
    }

  }
  onBmiSubmit() {
    this.spinner.show();

    // console.log(this.bmiForm)
    this.submitted = true;
    // stop here if form is invalid
    if (this.bmiForm.invalid) {
      return;
    }

    var bmiData = this.bmiForm.value;
    this.BMI = (bmiData.weight / Math.pow(bmiData.height, 2));
    var data = { weight: bmiData.weight, height: bmiData.height, gender: this.gender, result: this.BMI }
    this.ProfileupdateService.addBmi(data).subscribe(
      response => {
        this.spinner.hide();
        this.spinner.hide();
        if (response.body['error'] == true) {
          Swal({
            title: 'Error',
            text: response.body['message'],
            type: 'error',
            confirmButtonText: 'Ok',
          });
        } else {
          Swal({
            title: 'Success',
            text: this.BMI,
            type: 'success',
            confirmButtonText: 'Ok',
          });
          this.ProfileupdateService.bmiHistory().subscribe(
            response => {
              this.spinner.hide();
              this.bmiHistory = response.body['data'];
              var result = [];
              this.bmiHistory.forEach(function (key) {
                // console.log(key);

                result.push(key.result);
              });
              // console.log(result);
              var test = { data: result }
              this.lineChartData.push(test);
              // console.log(this.lineChartData)
            },
            err => {
              this.spinner.hide();
              this.router.navigateByUrl('/serverError');
            }
          );
          this.bmiForm = new FormGroup({
            weight: new FormControl(''),
            height: new FormControl('')
          })
          // this.router.navigate(['/user/patientdashboard']);
        }
      },
      err => {
        this.spinner.hide();
        this.router.navigateByUrl('/serverError');
      }
    );

  }

    unsubscribePlus(){
    this.spinner.show();
    Swal({
      title: 'Please Enter Your Feedback',
      input: 'text',
      inputValidator: (value) => {
        if (value) {
          var data= {reason:value}
          this.homeService.UnsubscribePlus(data).subscribe(async res => {           
          // console.log(res)
          Swal({
           title: 'success',
           text: 'Unsubscribe Successfully',
           type: 'success',
           showConfirmButton: false,
           timer: 1000
         });
            window.location.reload();
            
          },);
        }
        return !value && 'You need to write something!'
      },
       type: "warning",
        showCancelButton: true,
        confirmButtonColor: '#DD6B55',
        confirmButtonText: 'Yes, I am sure!',
        cancelButtonText: "No, cancel it!",
        // closeOnConfirm: false,
        // closeOnCancel: false
    
    }),
    
          this.spinner.hide();   
}

  editMember(data) {
    // console.log(data)
    const memberData = { data }
    // console.log(memberData)

    this.router.navigate(['/user/editFamily'], { state: memberData });
  }
}
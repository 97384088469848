import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { BloglistService } from '../blog-list/blog-list.service'
import { BlogDetailsService } from './blog-details.service'
import { NgxSpinnerService } from "ngx-spinner";


@Component({
  selector: 'app-blog-details',
  templateUrl: './blog-details.component.html',
  styleUrls: ['./blog-details.component.css']
})
export class BlogDetailsComponent implements OnInit {
  blogsData: { [k: string]: any; };
  title: any;
  description: any;
  createdAt: any;
  updatedAt: any;
  id: any;
  latestblogList: any;
  image: any;
  name: any;
  list: any;
  keyword = 'title';
  item: any;

  constructor(
    private router: Router, private bloglistService: BloglistService, private spinner: NgxSpinnerService,
    private route: ActivatedRoute, private service: BlogDetailsService
  ) {
    this.blogsData = this.router.getCurrentNavigation().extras.state;
    const { redirect } = window.history.state;
    console.log("redirect", redirect)

  }

  ngOnInit() {
    if (this.route.params['value'].id) {
      var request = { "id": this.route.params['value'].id }
      this.service.blogDetailsList(request).subscribe(async res => {
        console.log('latestblog', res)
        this.blogsData = res["body"]["data"].blogDetails[0]
        this.title = this.blogsData.title;
        this.description = this.blogsData.description;
        this.createdAt = this.blogsData.createdAt;
        this.updatedAt = this.blogsData.updatedAt;
        this.id = this.blogsData.id;
        this.image = this.blogsData.image
        this.spinner.hide();
      },
        err => {
          this.router.navigateByUrl('/serverError');
        }
      );
      this.bloglistService.latestblogList().subscribe(async res => {
        this.latestblogList = res.body['data'].hospital;
        console.log(this.latestblogList, 'latestblog')
        this.spinner.hide();
      },
        err => {
          this.router.navigateByUrl('/serverError');
        }
      );
    } else {
      this.title = this.blogsData.title;
      this.description = this.blogsData.description;
      this.createdAt = this.blogsData.createdAt;
      this.updatedAt = this.blogsData.updatedAt;
      this.id = this.blogsData.id;
      this.image = this.blogsData.image

      this.bloglistService.latestblogList().subscribe(async res => {

        this.latestblogList = res.body['data'].hospital;
        console.log(this.latestblogList, 'latestblog')
        this.spinner.hide();
      },
        err => {
          this.router.navigateByUrl('/serverError');
        }
      );
    }
  }
  Details(hospital) {
    this.router.navigate(['/blogDetails'], { state: hospital })
      .then(() => {
        window.location.reload();
      });
  }
  getblogList(data) {

    var requestData = {
      name: data
    }
    this.bloglistService.searchBlog(requestData).subscribe(async response => {

      console.log(response.body, 'response');
      this.list = response.body['data'].blogDetails;


    });
  }
  selectEvent(item) {
    this.item = item
    console.log(this.item, 'item');

    this.router.navigate(['/blogDetails'], { state: item })
      .then(() => {
        window.location.reload();
      });

  }

}

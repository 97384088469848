import { Component, OnInit } from '@angular/core';
import { Socket } from 'ngx-socket-io';
import { ChatService } from './chatbox.service';
import { AppComponent } from 'src/app/app.component';
import { environment } from '../../environments/environment';
import * as $ from '../../assets/js/jquery.min.js';
import { Router, ActivatedRoute } from '@angular/router';
import { ProfileService } from './profile.service';
import { Cookie } from 'ng2-cookies/ng2-cookies';

@Component({
  selector: 'app-chatbox',
  templateUrl: './chatbox.component.html',
  styleUrls: ['./chatbox.component.css']
})
export class ChatboxComponent implements OnInit {

  constructor(private socket: Socket,
		private appspinner: AppComponent,
		private service: ChatService, 
		private ProfileService:ProfileService,
		private router: Router,
		private route: ActivatedRoute) {
		}
		chatboxshow = false;
		
		buttonName = 'Show';
		hide: any;
	content: string = '';
	patientname: string;
	doctorId: string;
	patientimage: string;
	myname: string;
	userId: string;
	chats: any;
	chatscount: any;
	userlist: any;
	insurance: any;
	insuranceImage1: any;
	insuranceImage2: any;
	showInsurance : boolean = false;
	resp:any;

	ngOnInit() {
		// $(".modal-backdrop").remove();
		this.ProfileService.getprofileListView().subscribe((res) => {
      if (res.body['error'] === 'true') {
      } else {
        if (res.body['data'].length > 0) {
          this.resp = res.body['data'][0];
        //   console.log('this.result', this.resp)
          
        //   	this.doctorId = localStorage.getItem('doctorId')
		// this.patientname = localStorage.getItem('patient_name')
		// this.patientimage = localStorage.getItem('patient_image')
		if (this.patientimage == 'null') {
			this.patientimage = 'https://app.docnowmd.com/uploads/doctor/1565933349104.png';
		}
	// 	   let name = JSON.parse(localStorage.getItem('docname'));
    //   this.docname = name.doctorDetails.doctorsName;
    //   this.docImage = name.doctorDetails.doctorsImage;
		// this.myname = localStorage.getItem('docname')
	  this.myname = Cookie.get('userName');
		// console.log('localnamechat',this.myname)
		var msg = {
			type: 'doctor',
			id: this.doctorId
		}

		// setInterval(() => {

		this.socket.emit("get_online", msg);

		this.socket.on("get_online_" + msg.type + "_" + msg.id + "_ack", doc => {
		});
		// }, 5000);

		// this.userId = localStorage.getItem('userId')
	  this.userId = Cookie.get('id');
		var data = {
			receiverID: this.userId,
			page: 1
		}
		this.getChatDetails(data)
		var now = new Date().toString();
		var date = new Date(now.toString())
		var year = date.getFullYear()
		var mnth = ("0" + (date.getMonth() + 1)).slice(-2)
		var day = ("0" + date.getDate()).slice(-2)
		//var dataRequest = {
		//	date: year + '-' + mnth + '-' + day
		//}
		var dataRequest = {
			date: "2019-12-06"
			}
		this.getChatList(dataRequest)

		this.socket.on("recievemessage", doc => {
			var str = parseInt(doc.time)
			var now = new Date(str).toString();
			var date = new Date(now.toString()),
				mnth = ("0" + (date.getMonth() + 1)).slice(-2),
				day = ("0" + date.getDate()).slice(-2),
				hours = ("0" + date.getHours()).slice(-2),
				minutes = ("0" + date.getMinutes()).slice(-2);
			doc.time = date.getFullYear() + '-' + mnth + '-' + day + ' ' + hours + ':' + minutes
			if (doc.senderType == 'user' && doc.senderID == this.userId) {
				// console.log('i am sender',)
				this.chats.push(doc)
				this.chatscount = this.chats.length
			}
			this.scrollToBottom()
		});
          // }
        }
      }
    },
      (err) => {
        console.log(err);
      })  

		
	}
	
	profileClick(people) {
		// this.classToggled = !this.classToggled;  
			// $('.media').stop().addClass('bgcolor');
			// $(this).stop().removeClass('bgcolor');
		//   $('.close').on('click', function() {
		// 	$('.container').stop().removeClass('active');
		//   });
		// console.log('people',people);
		// localStorage.setItem('userId', people.id)
		// localStorage.setItem('patient_name', people.userName)
		// localStorage.setItem('patient_image', people.image)
		// this.userId = localStorage.getItem('userId')
		// this.patientname = localStorage.getItem('patient_name')
		// this.patientimage = localStorage.getItem('patient_image')

		this.patientname = Cookie.get('userName');
		this.userId = Cookie.get('id');
	  this.patientimage = Cookie.get('image');						


		if (this.patientimage == 'null') {
			this.patientimage = 'https://app.docnowmd.com/uploads/doctor/1565933349104.png';
		}
		var data = {
			receiverID: this.userId,
			page: 1
		}
		this.getChatDetails(data)
	}


	getInsuranceDetails(){
		var data = {
			userId: this.userId
		}	
		this.appspinner.spinnerAlert('show');
		this.service.getInsuranceDetailsService(data).subscribe((res) => {
			if (res.body['error'] === 'true') {
				environment.swalalert('nodata', res.body['message']);
				this.appspinner.spinnerAlert('hide');
			} else {
				this.insurance = res.body['data']
				if(this.insurance.length > 0){
					this.showInsurance = true
					this.insuranceImage1 = this.insurance[0].insuranceImage1
					this.insuranceImage2 = this.insurance[0].insuranceImage2
				}
				// console.log('this.insurance',this.insurance)
				this.appspinner.spinnerAlert('hide');
				this.scrollToBottom()
			}
		})
	}


	sendMessages() {
		if (this.content == '') {
			environment.swalalert('nodata', "Enter Some Message");
		} else {
			// this.userId = localStorage.getItem('userId')
		  this.userId = Cookie.get('id');
			var currentTimeStamp = (new Date).getTime().toString()
			var message = {
				senderID: this.doctorId,
				time: currentTimeStamp,
				receiverID: this.userId,
				content: this.content,
				senderType: 'doctor',
				contentType: 'text'
			}
			// console.log('message', message)
			this.socket.emit("sendmessage", message);
			var now = new Date().toString();
			var date = new Date(now.toString()),
				year = date.getFullYear(),
				mnth = ("0" + (date.getMonth() + 1)).slice(-2),
				day = ("0" + date.getDate()).slice(-2),
				hours = ("0" + date.getHours()).slice(-2),
				minutes = ("0" + date.getMinutes()).slice(-2);
			message.time = year + '-' + mnth + '-' + day + ' ' + hours + ':' + minutes
			this.chats.push(message)
			this.content = ''
			this.chatscount = this.chats.length
			this.scrollToBottom()
		}
	}

	requestInsurance() {
		// this.userId = localStorage.getItem('userId')
		this.userId = Cookie.get('id');
		var currentTimeStamp = (new Date).getTime().toString()
		var message = {
			senderID: this.doctorId,
			time: currentTimeStamp,
			receiverID: this.userId,
			content: "Doctor Has Requested For Insurance Document Access",
			senderType: 'doctor',
			contentType: 'insurance_request'
		}
		// console.log('message', message)
		this.socket.emit("sendmessage", message);
		var now = new Date().toString();
		var date = new Date(now.toString()),
			year = date.getFullYear(),
			mnth = ("0" + (date.getMonth() + 1)).slice(-2),
			day = ("0" + date.getDate()).slice(-2),
			hours = ("0" + date.getHours()).slice(-2),
			minutes = ("0" + date.getMinutes()).slice(-2);
		message.time = year + '-' + mnth + '-' + day + ' ' + hours + ':' + minutes
		this.chats.push(message)
		this.content = ''
		this.chatscount = this.chats.length
		this.scrollToBottom()
	}

	swipe(data) {
	    window.open(data);
	}

	clearChat() {
		// this.userId = localStorage.getItem('userId')
		this.userId = Cookie.get('id');
		// console.log(this.userId)
	}

	getChatDetails(data) {
		if(data.receiverID){
		this.appspinner.spinnerAlert('show');
		this.service.getChatHistory(data).subscribe((res) => {
			if (res.body['error'] === 'true') {
				environment.swalalert('nodata', res.body['message']);
				this.appspinner.spinnerAlert('hide');
			} else {
				res.body['data'].forEach(function(data, index) {
					var str = parseInt(data.time)
					var now = new Date(str).toString();
					var date = new Date(now.toString()),
						mnth = ("0" + (date.getMonth() + 1)).slice(-2),
						day = ("0" + date.getDate()).slice(-2),
						hours = ("0" + date.getHours()).slice(-2),
						minutes = ("0" + date.getMinutes()).slice(-2);
					data.time = date.getFullYear() + '-' + mnth + '-' + day + ' ' + hours + ':' + minutes
				})
				this.chats = res.body['data']
				// console.log('chats',this.chats)
				this.chatscount = this.chats.length

				if(this.chatscount > 0){
		this.chatboxshow = true;

				}
				this.appspinner.spinnerAlert('hide');
				this.scrollToBottom()
			}
		})
		}
	}

	getChatList(data) {
		this.appspinner.spinnerAlert('show');
		this.service.getChatListService(data).subscribe((res) => {
      
			if (res.body['error'] === 'true') {
				environment.swalalert('nodata', res.body['message']);
				this.appspinner.spinnerAlert('hide');
			} else {
				this.userlist = res.body['data'];
				// console.log(this.userlist)
				// console.log('chatlst',this.userlist)
				this.patientname = this.userlist.userName;
				this.userId = this.userlist.id;
				this.patientimage = this.userlist.image;
				// console.log('chatlst',this.userId)
				localStorage.setItem('patient_name',this.patientname);
				localStorage.setItem('patient_image',this.patientimage);
				localStorage.setItem('user_id',this.userId);
				this.patientname = localStorage.getItem('patient_name');
				this.patientimage = localStorage.getItem('patient_image');
				this.patientname = localStorage.getItem('user_id');
				this.appspinner.spinnerAlert('hide');
			}
		})
	}

	scrollToBottom() {
		console.log($("#chat-bar")[0].scrollHeight)
		$("#chat-bar").animate({ scrollTop: 100000000 }, 1000);
	}
}

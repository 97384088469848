import { Component, OnInit } from '@angular/core';
import { PatientdashboardService } from './patientdashboard.service';
import { Router } from '@angular/router';
import { NgxSpinnerService } from "ngx-spinner";
import Swal from 'sweetalert2';

@Component({
  selector: 'app-patientdashboard',
  templateUrl: './patientdashboard.component.html',
  styleUrls: ['./patientdashboard.component.css']
})
export class PatientdashboardComponent implements OnInit {
  myBookings: any;
  bookingData: any;
  Speciality = 'Cardiology'
  upcommingBookings: any;
  pastBookings: any;
  bookingId: any;
  doctorsImage: any;
  doctorsName: any;
  mobileNumber: any;
  speciality: any;
  bookingDate: any;
  fromTime: any;
  bookingStatus: any;
  experience: any;
  doctorAddress: any;
  showCancel: any;




  constructor(
    private PatientdashboardService: PatientdashboardService,
    private router: Router,
    private spinner: NgxSpinnerService

  ) { }

  ngOnInit() {
    var last = new Date()
    var day = last.getDate();
    var month = last.getMonth() + 1;
    var year = last.getFullYear();
    var dateString = year + '-' + month + '-' + day
    // console.log(dateString, 'dateString')
    this.spinner.show();
    const bookingData = { date: dateString || '2020-12-10' }
    this.PatientdashboardService.myBookings(bookingData).subscribe(
      response => {
        this.spinner.hide();
        this.upcommingBookings = response.body['data'].upcommingBookings;
        this.pastBookings = response.body['data'].pastBookings;
        // console.log(response)
      },
      err => {
        this.spinner.hide();
        this.router.navigateByUrl('/serverError');
      }
    );

  }
  open(booking, type) {
    console.log("booking data", booking)
    this.bookingId = booking.bookingId;
    this.doctorsImage = booking.doctorsImage;
    this.doctorsName = booking.doctorsName;
    this.mobileNumber = booking.mobileNumber;
    this.speciality = booking.speciality;
    this.bookingDate = booking.bookingDate;
    this.fromTime = booking.fromTime;
    this.bookingStatus = booking.bookingStatus;
    this.experience = booking.experience;
    this.doctorAddress = booking.doctorAddress;
    this.showCancel = type

    // console.log(this.doctorsImage)


    console.log(type)
  }


  cancel(booking) {
    var data = { bookingId: booking, bookingStatus: 'canceled' }
    Swal({
      title: 'cancel',
      text: 'are you want cancel',
      type: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085D6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'yes'
    }).then((result) => {
      if (result.value) {
        this.PatientdashboardService.cancelBooking(data).subscribe(
          res => {
            if (res.body['error'] === 'false') {
              Swal({
                title: 'success',
                text: 'booking canceled',
                type: 'success',
                showConfirmButton: false,
                timer: 1000
              });
              var last = new Date()
              var day = last.getDate();
              var month = last.getMonth() + 1;
              var year = last.getFullYear();
              var dateString = year + '-' + month + '-' + day
              const bookingData = { date: dateString }
              this.PatientdashboardService.myBookings(bookingData).subscribe(
                response => {
                  this.spinner.hide();
                  this.upcommingBookings = response.body['data'].upcommingBookings;
                  this.pastBookings = response.body['data'].pastBookings;
                },
                err => {
                  this.spinner.hide();
                  this.router.navigateByUrl('/serverError');
                }
              );

            } else {
              Swal({
                title: 'cancel',
                text: 'booking cancel',
                type: 'warning',
                showConfirmButton: false,
                timer: 1000
              });
            }
          },
          err => {
            this.router.navigateByUrl('/serverError');
          }
        );
      }
    });


  }


}

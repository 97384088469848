import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http'
import { from, Observable } from 'rxjs';
import { environment } from '../../environments/environment';
// import { CookieService } from 'ngx-cookie-service';
import { Cookie } from 'ng2-cookies/ng2-cookies';


@Injectable({
	providedIn: 'root'
})
export class ChatService {
	accessToken = Cookie.get('token');

	httpheaders = new HttpHeaders({
		'Content-Type': [],
		'Accept': 'application/json',
		'lang': 'default',
		'authorization': this.accessToken,
		'role': 'doctor'
	});

	url = environment.baseUrl;
	chaturl = environment.ChatUrl;
	private handleError(error: Response) {
		return Observable.throw(error.statusText);
	}
	constructor(private http: HttpClient,
		) {
		// console.log(this.cookie.get('Token').split('|')[0]);

	}
	getChatHistory(data) {
		return this.http.post(`${this.chaturl}/chatDetailList`, data, {
			headers: this.httpheaders,
			observe: 'response'
		});
	}

	getChatListService(data) {
		return this.http.post(`${this.chaturl}/getChatList`, data, {
			headers: this.httpheaders,
			observe: 'response'
		});
	}

	getInsuranceDetailsService(data){
		return this.http.post(`${this.url}/getInsuranceDetails`, data, {
			headers: this.httpheaders,
			observe: 'response'
		});
	}
}

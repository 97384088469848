import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AuthGuard } from './auth/auth.guard';
import { LayoutsComponent } from './layouts/layouts.component';
import { HeaderComponent } from './layouts/header/header.component';
import { FooterComponent } from './layouts/footer/footer.component';
import { IndexComponent } from './index/index.component';
import { LoginComponent } from './login/login.component';
import { RegisterComponent } from './register/register.component';
import { ProfilesettingsComponent } from './profilesettings/profilesettings.component';
import { FavouritesComponent } from './favourites/favourites.component';
import { UserlayoutComponent } from './userlayout/userlayout.component';
import { BookingComponent } from './booking/booking.component';
import { CheckoutComponent } from './checkout/checkout.component';
import { SuccessComponent } from './success/success.component';
import { ChangepasswordComponent } from './changepassword/changepassword.component';
import { PatientdashboardComponent } from './patientdashboard/patientdashboard.component';
import { HomelayoutComponent } from './homelayout/homelayout.component';
import { DoctorprofileComponent } from './doctorprofile/doctorprofile.component';
import { SearchComponent } from './search/search.component';
import { ChatComponent } from './chat/chat.component';
import { PlusComponent } from './plus/plus.component';
import { VerifyotpComponent } from './verifyotp/verifyotp.component';
import { SocialsignupComponent } from './socialsignup/socialsignup.component';
import { UpdateprofileComponent } from './updateprofile/updateprofile.component';
import { AddaddressComponent } from './addaddress/addaddress.component';
import { ProfileupdateComponent } from './profileupdate/profileupdate.component';
import { BlogListComponent } from './blog-list/blog-list.component';
import { BlogDetailsComponent } from './blog-details/blog-details.component';
import { ListhospitalComponent } from './listhospital/listhospital.component';
import { HospitallocationComponent } from './hospitallocation/hospitallocation.component';
import { WalletComponent } from './wallet/wallet.component';
import { AudiocallComponent } from './audiocall/audiocall.component';
import { VideocallComponent } from './videocall/videocall.component';
import { UrgentcareComponent } from './urgentcare/urgentcare.component';
import { AccountdetailsComponent } from './accountdetails/accountdetails.component';
import { ChatboxComponent } from './chatbox/chatbox.component';
import { StripeeComponent } from './stripee/stripee.component';
import { PaymentComponent } from './payment/payment.component';
import { FamilymemberComponent } from './familymember/familymember.component';
import { AddInsuranceComponent } from './add-insurance/add-insurance.component';
import { EditInsuranceComponent } from './edit-insurance/edit-insurance.component';
import { EditfamilyComponent } from './editfamily/editfamily.component';
import { DirectpayComponent } from './directpay/directpay.component';
import { DirectdoctorComponent } from './directdoctor/directdoctor.component';
import { HomeComponent } from './home/home.component';
import { ChildrenHospitalLocationComponent } from './children-hospital-location/children-hospital-location.component';
import { LocationComponent } from './location/location.component';
import { NearMeComponent } from './near-me/near-me.component';
import { NearMeSpecialityComponent } from './near-me-speciality/near-me-speciality.component';
import { UrgentCareComponent } from './urgent-care/urgent-care.component';
import { GainPatientComponent } from './gain-patient/gain-patient.component';
import { VisionComponent } from './vision/vision.component';
import { UrgentpageComponent } from './urgentpage/urgentpage.component';
import { SuccessstoryComponent } from './successstory/successstory.component';
import { EmrintegrationComponent } from './emrintegration/emrintegration.component';
import { PracticegrowthComponent } from './practicegrowth/practicegrowth.component';
import { BlogComponent } from './blog/blog.component';
import { DocnowoncallComponent } from './docnowoncall/docnowoncall.component';
import { WaitlistComponent } from './waitlist/waitlist.component';
import { TelehealthComponent } from './telehealth/telehealth.component';
import { SeoComponent } from './seo/seo.component';
import { OurteamComponent } from './ourteam/ourteam.component';
import { CareerComponent } from './career/career.component';
import { PreeroomComponent } from './preeroom/preeroom.component';
import { AboutComponent } from './about/about.component';
import { NewBillingComponent } from './new-billing/new-billing.component'

const routes: Routes = [
  {
    path: '',
    component: HomeComponent,
  },
  {
    path: 'home',
    component: HomeComponent,
  },
  {
    path: 'vision',
    component: VisionComponent,
  },

  {
    path: 'urgent-page',
    component: UrgentpageComponent,
  },
  {
    path: 'success-stories',
    component: SuccessstoryComponent,
  },
  {
    path: 'emr-ehr-integration',
    component: EmrintegrationComponent,
  },
  {
    path: 'practice-growth',
    component: PracticegrowthComponent,
  },
  {
    path: 'blogs',
    component: BlogComponent,
  },
  {
    path: 'docnow-on-call',
    component: DocnowoncallComponent,
  },
  {
    path: 'waitlist',
    component: WaitlistComponent,
  },
  {
    path: 'telehealth',
    component: TelehealthComponent,
  },
  {
    path: 'seo-and-marketing',
    component: SeoComponent,
  },
  // {
  //   path: 'our-team',
  //   component: OurteamComponent,
  // },
  {
    path: 'careers',
    component: CareerComponent,
  },
  {
    path: 'press-room',
    component: PreeroomComponent,
  },

  {
    path: 'location',
    component: LocationComponent,
  },
  {
    path: 'near-me/:location',
    component: NearMeComponent,
  },
  {
    path: 'gain-new-patients',
    component: GainPatientComponent,
  },

  {
    path: 'urgent-care/:location',
    component: UrgentCareComponent,
  },
  {
    path: 'provider/:speciality/:location',
    component: NearMeSpecialityComponent,
  },
  {
    path: 'index',
    component: HomeComponent,
  },
  {
    path: '',
    component: LayoutsComponent,
    children: [
      // { path: 'index', component: IndexComponent },
      { path: 'login', component: LoginComponent },
      { path: 'register', component: RegisterComponent },
      { path: 'booking', component: BookingComponent },
      { path: 'checkout', component: CheckoutComponent },
      { path: 'success', component: SuccessComponent },
      { path: 'doctorprofile', component: DoctorprofileComponent },
      { path: 'doctorprofile/:id', component: DoctorprofileComponent },
      { path: 'search', component: SearchComponent },
      { path: 'chat', component: ChatboxComponent },
      { path: 'plus', component: PlusComponent },
      { path: 'verifyotp', component: VerifyotpComponent },
      { path: 'socialsignup', component: SocialsignupComponent },
      { path: 'updateprofile', component: UpdateprofileComponent, canActivate: [AuthGuard] },
      { path: 'addaddress', component: AddaddressComponent, canActivate: [AuthGuard] },
      { path: 'blogList', component: BlogListComponent },
      { path: 'blogDetails', component: BlogDetailsComponent },
      { path: 'blogDetails/:id/:title', component: BlogDetailsComponent },
      { path: 'listhospital', component: ListhospitalComponent },
      { path: 'hospitallocation', component: HospitallocationComponent },
      { path: 'ChildrenHospitalLocation', component: ChildrenHospitalLocationComponent },
      { path: 'audiocall', component: AudiocallComponent },
      { path: 'videocall', component: VideocallComponent },
      { path: 'urgentcare', component: UrgentcareComponent },
      { path: 'stripee', component: StripeeComponent },
      { path: 'payment', component: PaymentComponent },
      { path: 'directpay', component: DirectpayComponent },
      { path: 'directDoctor', component: DirectdoctorComponent },
      { path: 'get-new-patients', component: AboutComponent },
      { path: 'new-billing', component: NewBillingComponent },

    ]
  },
  {
    path: 'user',
    component: UserlayoutComponent,
    children: [
      { path: 'profilesettings', component: ProfilesettingsComponent, canActivate: [AuthGuard] },
      { path: 'favourites', component: FavouritesComponent, canActivate: [AuthGuard] },
      { path: 'changepassword', component: ChangepasswordComponent, canActivate: [AuthGuard] },
      { path: 'patientdashboard', component: PatientdashboardComponent, canActivate: [AuthGuard] },
      { path: 'profileupdate', component: ProfileupdateComponent, canActivate: [AuthGuard] },
      { path: 'wallet', component: WalletComponent, canActivate: [AuthGuard] },
      { path: 'accountdetails', component: AccountdetailsComponent, canActivate: [AuthGuard] },
      { path: 'chat', component: ChatboxComponent },
      { path: 'payment', component: PaymentComponent },
      { path: 'familymember', component: FamilymemberComponent },
      { path: 'addInsurance', component: AddInsuranceComponent },
      { path: 'editInsurance', component: EditInsuranceComponent },
      { path: 'editFamily', component: EditfamilyComponent },





    ], canActivate: [AuthGuard]
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }

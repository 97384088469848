import { Component, OnInit } from '@angular/core';
declare var $;
@Component({
  selector: 'app-practicegrowth',
  templateUrl: './practicegrowth.component.html',
  styleUrls: ['./practicegrowth.component.css']
})
export class PracticegrowthComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

  data: any = [{
    "parentName": "No Booking Fees",
    "childProperties":
      [
        { "propertyName": "Unlike other platforms, DocNow does not charge practitioners booking fees per appointment. Both new and existing patients can use the platform without incurring additional costs." }
      ]
  }, {
    "parentName": "Practice  Productivity",
    "childProperties":
      [
        { "propertyName": "Patients can select the Wait List™ feature which automatically books them into open slots as they occur, improving patient experience and enhancing practice productivity." }
      ]
  }, {
    "parentName": "Resources to Grow Your Practice",
    "childProperties":
      [
        { "propertyName": "DocNow promotes practices through search engine optimization, social media marketing, and reputation management. Our Telehealth feature provides high quality video connection to expand your patient footprint. Our unique Wait List™ feature optimizes your practice." }
      ]
  }

  ];
  ngAfterViewInit() {
    if (window.location.href.indexOf("practice-growth") > -1) {
      $('nav').addClass('addShadow');
    }
  }
  toggleAccordian(event, index) {
    const element = event.target;
    element.classList.toggle("active");
    if (this.data[index].isActive) {
      this.data[index].isActive = false;
    } else {
      this.data[index].isActive = true;
    }
    const panel = element.nextElementSibling;
    if (panel.style.maxHeight) {
      panel.style.maxHeight = null;
    } else {
      panel.style.maxHeight = panel.scrollHeight + "px";
    }
  }
}

import { Component, OnInit, ViewChild, ElementRef, Output, EventEmitter } from '@angular/core';
import { StripeService, StripeCardComponent } from 'ngx-stripe';
import {
  StripeCardElementOptions,
  StripeElementsOptions
} from '@stripe/stripe-js';
import { FormGroup, FormBuilder, Validators } from "@angular/forms";
import { environment } from 'src/environments/environment';
import { PaymentService } from './payment.service';
import { AppComponent } from 'src/app/app.component';
import { Router } from '@angular/router';
import { ProfileService } from './profile.service';
import { NgxSpinnerService } from "ngx-spinner";
@Component({
  selector: 'app-payment',
  templateUrl: './payment.component.html',
  styleUrls: ['./payment.component.css']
})

export class PaymentComponent implements OnInit {
  submitted = false;
  paymentForm: FormGroup;
  @ViewChild(StripeCardComponent) card: StripeCardComponent;
  cardOptions: StripeCardElementOptions = {
    style: {
      base: {
        iconColor: '#276fd3',
        color: '#31325F',
        lineHeight: '40px',
        fontWeight: '300',
        fontFamily: '"Helvetica Neue", Helvetica, sans-serif',
        fontSize: '18px',
        '::placeholder': {
          color: '#111'
        }
      }
    }
  };

  elementsOptions: StripeElementsOptions = {
    locale: 'en'
  };
  addcardview: boolean;
  showpayview: boolean;
  cardArray: any;
  payForm: FormGroup;
  customerId: any;
  remainingDays: any;
  remainingDate: any;
  showPayButton: any;
  cardData: { [k: string]: any; };

  constructor(
    private _stripe: StripeService,
    private formBuilder: FormBuilder,
    private service: PaymentService,
    private ProfileService: ProfileService,
    private spinner: NgxSpinnerService,
    private router: Router
  ) {
    this.cardData = this.router.getCurrentNavigation().extras.state;
    this.paymentForm = this.formBuilder.group({
      name: ['', [Validators.required, Validators.pattern(/^\S+(?: \S+)*$/)]],
      email: ['', [Validators.required, Validators.pattern(/^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-z]{2,4}$/)]]
    });
    this.payForm = this.formBuilder.group({
      days: ['', [Validators.required]],
      source: ['', [Validators.required]],
      amount: ['', [Validators.required]]
    });
  }


  ngOnInit() {
    if (this.cardData == undefined) {
      this.addcardview = false
    this.spinner.show();

      this.ProfileService.getprofileListView().subscribe((res) => {
        if (res.body['error'] === 'true') {
        } else {
          if (res.body['data'].length > 0) {
            this.customerId = res.body['data'][0].customerId;
            this.remainingDays = res.body['data'][0].remainingDays;
            this.showPayButton = res.body['data'][0].showPay
            if (this.customerId != 'invalid') {
              this.service.listCards().subscribe((res) => {
                if (res.body['error'] === 'true') {
                  environment.swalalert('nodata', res.body['message']);
                } else {
                  if (res.body['data'].length > 0) {
                    this.addcardview = false
                    this.showpayview = true
                    this.cardArray = res.body['data'][0]
                    this.payForm = this.formBuilder.group({
                      days: ['', [Validators.required]],
                      source: [this.cardArray.id, [Validators.required]],
                      amount: ['', [Validators.required]]
                    });
                  } else {
                    this.addcardview = true
                    this.showpayview = false
                  }
                }
              })
            } else {
              this.service.createcustomer().subscribe((res) => {
                if (res.body['error'] === 'true') {
                  environment.swalalert('nodata', res.body['message']);
            this.spinner.hide();
                } else {
                  this.addcardview = true
                  this.showpayview = false
                  // console.log('Response', res.body['data'])
                  // console.log('Response', res.body['data'].customerId)
                }
              })
            }
          }
        }
      },
        (err) => {
          console.log(err);
        })
            this.spinner.hide();
    } else {
      this.addcardview = true
    }
  }

  onSubmit() {
    this.spinner.show();
    this._stripe.createToken(this.card.getCard(), { name })
      .subscribe(result => {
        if (result.error) {
          environment.swalalert('nodata', 'Invalid card number');
        } else {
          const request = { source: result.token.id }
          this.service.addCard(request).subscribe((res) => {
            if (res.body['error'] === 'true') {
              environment.swalalert('nodata', res.body['message']);
            this.spinner.hide();
            } else {
              environment.swalalert('success', res.body['message']).then(value => {
                if (value) {
                  this.router.navigate(['/user/accountdetails']);
                  // window.location.reload();
                } else {
                  this.router.navigate(['/user/accountdetails']);
                  // window.location.reload();
                }
              })
            }
          })
        }
      });
  }

  proceedPayment() {
    this.spinner.show();
    if (this.payForm.value.days == '28') {
      this.payForm.value.amount = 30
    }
    if (this.payForm.value.days == '90') {
      this.payForm.value.amount = 90
    }
    if (this.payForm.value.days == '365') {
      this.payForm.value.amount = 250
    }
    if (this.payForm.value.amount && this.payForm.value.source && this.payForm.value.days) {
      this.service.stripePayment(this.payForm.value).subscribe((res) => {
        if (res.body['error'] === 'true') {
          environment.swalalert('nodata', res.body['message']);
            this.spinner.hide();
        } else {
          environment.swalalert('success', res.body['message']).then(value => {
            if (value) {
              this.router.navigate(['dashboard']);
            this.spinner.hide();
            } else {
              this.router.navigate(['dashboard']);
            this.spinner.hide();
            }
          })
        }
      })
    } else {
      environment.swalalert('error', "Invalid Payment Details");
            this.spinner.hide();

    }
  }

  deleteCard(card) {
    var request = {}
    request['cardId'] = card
    this.service.deleteCard(request).subscribe((res) => {
      if (res.body['error'] === 'true') {
        environment.swalalert('nodata', res.body['message']);
            this.spinner.hide();
      } else {
        environment.swalalert('success', res.body['message']).then(value => {
          if (value) {
            window.location.reload();
          } else {
            window.location.reload();
          }
        })
      }
    })
  }

}

import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http'
import { from, Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
// import { CookieService } from 'ngx-cookie-service';
import { Cookie } from 'ng2-cookies/ng2-cookies';


@Injectable({
  providedIn: 'root'
})
export class ProfileService {
	accessToken = Cookie.get('token');

  url = environment.baseUrl;
  httpheaders = new HttpHeaders({
    'Content-Type': [],
    'Accept': 'application/json',
    'lang': 'default',
    'authorization': this.accessToken,
    'role': 'doctor'
  });
  constructor(private http: HttpClient,
    ) { }

  // get profile List View
  getprofileListView() {
    return this.http.get(`${this.url}/viewDoctorProfile`, {
      headers: this.httpheaders,
      observe: 'response'
    });
  }

  requestPrice(data) {
    return this.http.post(`${this.url}/requestCallPrice`, data, {
      headers: this.httpheaders,
      observe: 'response'
    });
  }

  reRequestPrice(data) {
    return this.http.post(`${this.url}/reRequestCallPrice`, data, {
      headers: this.httpheaders,
      observe: 'response'
    });
  }

  deleteTelemateFeatureService() {
    return this.http.get(`${this.url}/requestDeleteCall`, {
      headers: this.httpheaders,
      observe: 'response'
    });
  }

  // get category
  getcategory() {
    return this.http.get(`${this.url}/listDoctorCategory`, {
      headers: this.httpheaders,
      observe: 'response'
    });
  }

  // get subcategory
  getsubcategory() {
    return this.http.get(`${this.url}/listDoctorSubCategory`, {
      headers: this.httpheaders,
      observe: 'response'
    });
  }

  // get procedures
  getprocedures() {
    return this.http.get(`${this.url}/listDoctorProcedures`, {
      headers: this.httpheaders,
      observe: 'response'
    });
  }
  // get areas of practice
  getareaofpractice() {
    return this.http.get(`${this.url}/listDoctorAreaOfPractices`, {
      headers: this.httpheaders,
      observe: 'response'
    });
  }

  //doctor profile update adn fileupload add
  profileupdate(data) {
    return this.http.post(`${this.url}/editDoctorProfile`, data, {
      headers: this.httpheaders,
      observe: 'response'
    });
  }
  //doctor profile insurance data search
  searchInsurance(data) {
    return this.http.post(`${this.url}/searchInsuranceV2`, data, {
      headers: this.httpheaders,
      observe: 'response'
    });
  }
  // fileupload add
  fileupload(data: File[], type) {
    var formData = new FormData();
    Array.from(data).forEach(f => formData.append('file', f),
      formData.append('doctor', type))
    // formData.append('replacefilename', '');
    return this.http.post(`${this.url}/imageUpload`, formData, {
      headers: this.httpheaders,
      observe: 'response'
    });
  }
}

